import * as React from 'react';

type CloseIconProps = {
  className?: string;
};

/**
 * @deprecated Use Assemblage [CrossIcon](http://assemblage.gokome.com/?path=/docs/stable-icons-images-and-badges-icons--docs) instead
 */
function CloseIcon(props: CloseIconProps) {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
      style={{ cursor: 'pointer' }}
      fill="currentColor"
      className={props.className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5425 1.04277C11.933 0.652247 12.5662 0.652247 12.9567 1.04277C13.3472 1.4333 13.3472 2.06646 12.9567 2.45699L8.414 6.99968L12.9574 11.543C13.3479 11.9335 13.3479 12.5667 12.9574 12.9572C12.5668 13.3478 11.9337 13.3478 11.5431 12.9572L6.99979 8.41389L2.45711 12.9566C2.06658 13.3471 1.43342 13.3471 1.04289 12.9566C0.652369 12.566 0.652369 11.9329 1.04289 11.5423L5.58557 6.99968L1.04355 2.45766C0.653026 2.06714 0.653025 1.43398 1.04355 1.04345C1.43407 0.652926 2.06724 0.652925 2.45776 1.04345L6.99979 5.58546L11.5425 1.04277Z"
      />
    </svg>
  );
}

export { CloseIcon };
