import type { ColorModeSetting, ColorTheme } from '../../models';

const defaultColorMode: ColorModeSetting = 'system';
const colorModeKey = 'color-mode';

export function getBrowserDefault(): ColorTheme {
  if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
    return 'dark';
  }
  return 'light';
}

function refreshBrowserTab() {
  window.location.reload();
}

export function getColorModeSetting(): ColorModeSetting {
  const savedColorTheme = localStorage.getItem(colorModeKey);
  if (savedColorTheme !== 'dark' && savedColorTheme !== 'light' && savedColorTheme !== 'system') {
    return defaultColorMode;
  }
  return savedColorTheme;
}

export function getColorMode(): ColorTheme {
  if (window.__forceLightMode) {
    return 'light';
  }

  const savedColorTheme = getColorModeSetting();
  if (savedColorTheme === 'system') {
    return getBrowserDefault();
  }

  return savedColorTheme;
}

export function getOppositeColorMode(): ColorTheme {
  const currentTheme = getColorMode();
  return currentTheme === 'light' ? 'dark' : 'light';
}

export const isLightMode = getColorMode() === 'light';

export function saveColorMode(mode: ColorModeSetting): void {
  localStorage.setItem(colorModeKey, mode);
  refreshBrowserTab();
}

export function initTheme(): void {
  const colorMode = getColorMode();

  if (colorMode === 'light') {
    import(
      /* webpackChunkName: "theme-light" */
      /* webpackPreload: true */
      'theme-dashboard/dist/toolkit-light.css?theme'
    );
  } else {
    import(
      /* webpackChunkName: "theme-dark" */
      /* webpackPreload: true */
      'theme-dashboard/dist/toolkit-inverse.css?theme'
    );
  }
}
