// The locations for which the app is active are configured in manifest.json
// See: https://developer.zendesk.com/apps/docs/support-api/introduction
import { Channel, FilterOptions } from '../../models';
import { ChannelOpt, channelOpts } from '../FilterConstants';
import { BasicFilterType, BasicFilterValue, FilterGroupType } from '../FilterParams';
import { handleFetchErrors, reportError } from '../utils/Errors';
import { ZendeskChannel } from './models';
import UserManager from '../utils/UserManager';

export type ZendeskAppLocation = 'background' | 'ticket_sidebar' | 'top_bar';

export const LocationBackground: ZendeskAppLocation = 'background';
export const LocationTicketSidebar: ZendeskAppLocation = 'ticket_sidebar';
export const LocationTopBar: ZendeskAppLocation = 'top_bar';

// From: https://developer.zendesk.com/apps/docs/core-api/client_api#client.context
export type ZendeskAppContext = {
  instanceGuid: string;
  product: string;
  account: {
    subdomain: string;
  };
  location: string;
  ticketId: number;
};

// From: https://developer.zendesk.com/apps/docs/core-api/client_api#client-object
export type ZendeskAppClient = {
  context: () => Promise<ZendeskAppContext>;
  set: (property: string, value: any) => Promise<any>;
  get: (property: string) => Promise<any>;
  on: (property: string, callback: (e: any) => void) => Promise<any>;
  off: (property: string, callback: (e: any) => void) => void;
  // there are basically no restrictions on this method...
  invoke: (
    arg1?: any,
    arg2?: any | null | undefined,
    arg3?: any | null | undefined,
    arg4?: any | null | undefined
  ) => Promise<any>;
  request: (url: string) => Promise<any>;
};

// convertZendeskChannel converts a Zendesk specific channel to an Assembled channel. The Zendesk
// channels can be found here:
// https://developer.zendesk.com/api-reference/apps/apps-support-api/ticket_sidebar/#specifications
function convertZendeskChannel(zendeskChannel: ZendeskChannel | null, tags: string[] | null): Channel | null {
  if (!zendeskChannel) {
    return null;
  }

  if (tags) {
    const hasVoiceTag = tags.some((tag) => tag === 'voice');
    if (hasVoiceTag) {
      return 'phone';
    }
  }

  switch (zendeskChannel) {
    case 'email':
    case 'web':
    case 'internal':
      return 'email';
    case 'voice':
      return 'phone';
    case 'chat':
    case 'native_messaging':
    case 'chat_transcript':
      return 'chat';
    case 'instagram_dm':
    case 'twitter':
    case 'facebook':
    case 'wechat':
    case 'sunshine_conversations_twitter_dm':
    case 'sunshine_conversations_facebook_messenger':
    case 'line':
    case 'whatsapp':
      return 'social';
    default:
      return null;
  }
}

function isInZendeskAppContext(): boolean {
  // @ts-expect-error - TS2339 - Property 'ZAFClient' does not exist on type 'Window & typeof globalThis'. | TS2339 - Property 'ZAFClient' does not exist on type 'Window & typeof globalThis'.
  return !!(window.ZAFClient && window.ZAFClient.init());
}

function getBasicFilterValue(
  type: BasicFilterType,
  value: string,
  filterOptions: FilterOptions
): BasicFilterValue | null | undefined {
  const valuesForType = getBasicFilterValuesForType(type, filterOptions);

  return valuesForType.find((basicValue: BasicFilterValue) => {
    return basicValue.value === value;
  });
}

function convertFilterTypeToGroupType(type: BasicFilterType): FilterGroupType | null | undefined {
  switch (type) {
    case 'channel':
      return 'channels';
    case 'queue':
      return 'queues';
    case 'site':
      return 'sites';
    case 'skill':
      return 'skills';
    case 'team':
      return 'teams';
  }
  // should never happen
  return null;
}

function getBasicFilterValuesForType(type: BasicFilterType, filterOptions: FilterOptions): Array<BasicFilterValue> {
  if (type === 'channel') {
    const opts = channelOpts(true);
    return opts.map((opt: ChannelOpt) => {
      return {
        type,
        name: opt.label,
        value: opt.value,
      };
    });
  }
  const groupType = convertFilterTypeToGroupType(type);
  if (!groupType || groupType === 'channels') {
    return [];
  }
  return filterOptions[groupType].map((value) => {
    return {
      type,
      name: value.name,
      value: value.value,
    };
  });
}

const handleZendeskFetchErrors = async function (response: Response, logs: any): Promise<any> {
  console.log(logs);
  return handleFetchErrors(response);
};

const handleZendeskReportError = (e: unknown, logs: any) => {
  console.log(logs);
  return reportError(e);
};

const getZendeskComment = (zendeskClient: ZendeskAppClient): Promise<any> => {
  return zendeskClient.get('comment.text');
};

const canAppendHTML = (zendeskClient: ZendeskAppClient): Promise<boolean> => {
  return zendeskClient.get('ticket.editor.capabilities.richText').then((res) => {
    return res['ticket.editor.capabilities.richText'];
  });
};

function replaceAgentSignature(zendeskClient: ZendeskAppClient, text: string): string {
  return text.replace('{{ currentUser.name }}', UserManager.getFirstName() || 'Cal');
}

export {
  canAppendHTML,
  convertFilterTypeToGroupType,
  convertZendeskChannel,
  getBasicFilterValue,
  getBasicFilterValuesForType,
  getZendeskComment,
  handleZendeskFetchErrors,
  handleZendeskReportError,
  isInZendeskAppContext,
  replaceAgentSignature,
};
