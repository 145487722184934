import moment from 'moment';
import queryString from 'query-string';
import type { History, Location as UseHistoryLocation } from 'history';

import type { FilterParams } from '../../models';

function parseUnix(params: any, paramName: string) {
  const pd = moment.unix(params[paramName]);
  if (pd.isValid()) {
    params[paramName] = pd;
  } else {
    delete params[paramName];
  }
  return params;
}

function parseQueryString(location: Location | UseHistoryLocation): any {
  if (!location) return {};
  let params = queryString.parse(location.search);
  if (params.date) {
    params = parseUnix(params, 'date');
  }
  if (params.start) {
    params = parseUnix(params, 'start');
  }
  if (params.end) {
    params = parseUnix(params, 'end');
  }
  if (params.week) {
    params = parseUnix(params, 'week');
  }
  return params;
}

const arrayParams = ['statuses'];

const didFiltersUpdate = (
  prevFilters: FilterParams | null | undefined,
  filters: FilterParams,
  prevExtraQuery = null,
  extraQuery = null
): boolean => {
  if (!prevFilters && !prevExtraQuery) {
    return false;
  }
  if (prevFilters === filters && prevExtraQuery === extraQuery) {
    return false;
  }

  if (prevFilters) {
    if (
      (prevFilters.date && filters.date && !prevFilters.date.isSame(filters.date)) ||
      (prevFilters.start && filters.start && !prevFilters.start.isSame(filters.start)) ||
      (prevFilters.end && filters.end && !prevFilters.end.isSame(filters.end)) ||
      prevFilters.range !== filters.range ||
      prevFilters.channel !== filters.channel ||
      prevFilters.site !== filters.site ||
      prevFilters.restricted_site !== filters.restricted_site ||
      prevFilters.skill !== filters.skill ||
      prevFilters.team !== filters.team ||
      prevFilters.queue !== filters.queue ||
      prevFilters.limit !== filters.limit ||
      (prevFilters.schedule_id && prevFilters.schedule_id !== filters.schedule_id)
    ) {
      return true;
    }
  }
  if (prevExtraQuery && extraQuery) {
    const diffElement = Object.keys(extraQuery).find((key) => {
      return extraQuery[key] !== prevExtraQuery[key];
    });
    if (diffElement) {
      return true;
    }
  }

  return false;
};

const updateFiltersHistory = (
  location: Location,
  history: History,
  prevFilters: FilterParams | null | undefined,
  filters: FilterParams,
  prevExtraQuery: any | null = null,
  extraQuery: any | null = null,
  includeKeys: Array<string> = []
) => {
  if (!didFiltersUpdate(prevFilters, filters, prevExtraQuery, extraQuery)) {
    return false;
  }

  let rawQuery = {
    date: filters.date.unix(),
    start: filters.start.unix(),
    end: filters.end.unix(),
    range: filters.range,
    channel: filters.channel,
    site: filters.site,
    restricted_site: filters.restricted_site,
    skill: filters.skill,
    team: filters.team,
    queue: filters.queue,
    schedule_id: filters.schedule_id,
    limit: filters.limit,
  };
  if (extraQuery) {
    rawQuery = Object.assign(rawQuery, extraQuery);
  }

  let query: Record<string, any> = {};
  if (includeKeys.length > 0) {
    includeKeys.forEach((k) => {
      // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ date: any; start: any; end: any; range: Range; channel: Channel | null | undefined; site: string | null | undefined; skill: string | null | undefined; team: string | null | undefined; queue: string | ... 1 more ... | undefined; schedule_id: string | ... 1 more ... | undefined; limit: number; }'.
      query[k] = rawQuery[k];
    });
  } else {
    query = rawQuery;
  }

  const qs = createQueryString(query);
  const search = `?${qs}`;
  // Only update when the query string changes. We need this check because
  // navigating to the page with query parameters will mean we update the
  // internal state without changing the query string
  if (location && history && location.search !== search) {
    const url = `${location.pathname}${search}`;
    history.push(url);
  }
  return true;
};

function createQueryString(info: any) {
  const params = new URLSearchParams();
  Object.keys(info).forEach((param: string) => {
    if (info[param] == null) {
      return;
    }
    if (arrayParams.includes(param)) {
      // For keys that hold arrays, append each element
      // @ts-expect-error - TS7006 - Parameter 'item' implicitly has an 'any' type.
      (info[param] || []).forEach((item) => {
        params.append(param, item);
      });
    } else {
      // For keys that aren't array params, we assume they're single item params
      params.set(param, info[param]);
    }
  });
  return params.toString();
}

export { createQueryString, updateFiltersHistory, parseQueryString, didFiltersUpdate };
