import type { GenerationSettings } from '../../models';

type GenerationSettingsAction = {
  type: 'UPDATE_GENERATION_SETTINGS';
  update: Partial<GenerationSettings>;
};

const initialState: GenerationSettings = {
  evaluators: [
    'rmse',
    'trend_rmse',
    'rsquared',
    'avg_sla',
    'avg_occupancy',
    'avg_first_response',
    'schedule_cost',
    'distinct_agents',
    'scheduled_hours',
    'scheduled_ftes',
  ],
};

const GenerationSettingsReducer = (state: GenerationSettings = initialState, action: GenerationSettingsAction) => {
  switch (action.type) {
    case 'UPDATE_GENERATION_SETTINGS': {
      let stateUpdate: Record<string, any> = {};
      if (action.update.evaluators) {
        stateUpdate = { ...stateUpdate, evaluators: action.update.evaluators };
      }
      return { ...state, ...stateUpdate };
    }
    default:
      return state;
  }
};

export { GenerationSettingsReducer };
