/**
 * Types from Assembled backend. See ../../gocode/models
 * TODO: Would be nice to autogenerate these
 */
import { Icon, SupportedLocale } from 'assemblage';
import { isNull } from 'lodash-es';
import moment from 'moment';
import * as React from 'react';

import type { DurationDisplayOption } from './components/automationforms/DurationInput';
import type { EventType } from './components/reports/scorecard/utils';
import type { NamedMetricGroup } from './components/reports/shared/ForecastedVsActualTable';
import type { KeywordMappingGroup } from './components/settings/queues/KeywordMappingGroups';
import type { TemplateInterval } from './components/templates/TemplateForm';
import type { PasteType } from './components/timeline/CopyPasteHelpers';
import type { ChangeGroup, EventChange } from './components/timeline/EventChanges';
import type { Range } from './constants/RangeConstants';
import { CustomActionType } from './components/zendesk/Cal/models';

export type AgentStatus = 'active' | 'deleted';

export type AgentStaffable = 'all' | 'true' | 'false';

export type LookbackPeriod =
  | 'lookback_period_today'
  | 'lookback_period_15_minutes'
  | 'lookback_period_30_minutes'
  | 'lookback_period_1_hour'
  | 'lookback_period_3_hours'
  | 'lookback_period_6_hours'
  | 'lookback_period_24_hours';

export type AgentWithConflicts = 'true' | 'false';

export type AllocationStatus = 'all' | 'over' | 'under';

export type Channel = 'all' | 'back_office' | 'chat' | 'email' | 'phone' | 'sms' | 'social';

export const AllChannel: Channel = 'all';
export const BackOfficeChannel: Channel = 'back_office';
export const ChatChannel: Channel = 'chat';
export const EmailChannel: Channel = 'email';
export const PhoneChannel: Channel = 'phone';
export const SMSChannel: Channel = 'sms';
export const SocialChannel: Channel = 'social';

export type ContactInput = 'case_volume' | 'units_of_work';

export const validateContactInput = (input: string): input is ContactInput => {
  return ['case_volume', 'units_of_work'].includes(input);
};

export type RequirementSource = 'stored' | 'dynamic';

export type Theme = 'light' | 'dark';

export type VendorRequirementInterval = 'hour' | 'day' | 'week' | 'month';

export type EventClassification = 'default';

export type TeamPerformanceMetricExportType = 'agent_performance' | 'aggregate_team_performance';

export type EventChangeSource = 'staffing_timeline' | 'edit_template';

export type ImpersonationData = {
  company_id?: string;
};

export type CalTicketStyleExample = {
  question: string;
  response: string;
};

export type CalCompanyConfig = {
  company_name: string;
  company_description: string;
  slack_redirect_url: string;
  slack_auto_response_channel_ids: string[];
  style_description: string | undefined;
  style_examples: CalTicketStyleExample[];
  enable_ticket_categorization: boolean;
  respond_as_agent_signature: string | undefined;
  gdrive_folder_id: string | null;
  gdrive_domains: string[];
  installed_zd_app: boolean;
  send_zendesk_notifications: boolean;
  auto_fill_mode: 'always' | 'resolution_paths';
  auto_fill_enabled: 'never' | 'company' | 'user';
  async_reply_auto_send_author_id: string | null;
  autosend_delay_seconds_minimum: number | null;
  autosend_delay_seconds_maximum: number | null;
  available_custom_actions: CustomActionType[] | null;
  autosend_tag: string | null;
  auto_send_enabled: boolean;
  enable_translations: boolean;
  enable_chat_completions: boolean;
};

export type RestrictedSites = Array<string> | null | undefined;

export type User = {
  id: string;
  company: Company | null | undefined;
  first_name: string;
  last_name: string;
  timezone: string;
  locale: SupportedLocale | null | undefined;
  email: string;
  password?: string;
  admin: boolean;
  agent: Agent | null | undefined;
  agent_id: string | null | undefined;
  role: UserRole;
  roles: Array<RoleInfo>;
  default_channel: Channel | null | undefined;
  default_range: Range | null | undefined;
  default_site: string | null | undefined;
  default_queue: string | null | undefined;
  default_team: string | null | undefined;
  default_limit: number | null | undefined;
  last_login: string | null | undefined;
  gcal_read_enabled: boolean;
  gcal_write_enabled: boolean;
  email_report: boolean;
  impersonated?: ImpersonationData | null | undefined;
  team_account?: boolean | null | undefined;
  updated_at: string;
  created_at: string;
  restricted_sites: Readonly<RestrictedSites>;
  slack_id: string | null | undefined;
  timeoff_notifications: boolean;
  intercom_hmac: string | null | undefined;
  teams: Array<FilterValue> | null | undefined;
  queues: Array<FilterValue> | null | undefined;
};

export type Person = {
  user_id: string | null | undefined;
  agent_id: string | null | undefined;
  first_name: string;
  last_name: string;
  email: string;
  user_role: UserRole | null | undefined;
  roles: Array<RoleInfo>;
  agent_role?: string;
  last_login: string | null | undefined;
  gcal_read_enabled: boolean;
  gcal_write_enabled: boolean;
  created_at: string;
  restricted_sites: Array<string> | null | undefined;
  user_active: boolean;
  imported_id: string | null | undefined;
  site: FilterValue | null | undefined;
  teams: Array<FilterValue> | null | undefined;
  queues: Array<FilterValue> | null | undefined;
  secondary_queues: Array<FilterValue> | null | undefined;
  skills: Array<FilterValue> | null | undefined;
  channels: Array<Channel> | null | undefined;
  platform_associations: Partial<Record<Platform, AgentPlatformAssociation>>;
  start_date: string | null | undefined;
  end_date: string | null | undefined;
  productivity: number | null | undefined;
  shift_pattern_id?: string;
  assist_enabled?: boolean;
  timezone?: string;
};

export type GetPeoplePaginatedResponse = {
  persons: Array<Person>;
  limit: number;
  offset: number;
  count: number;
};

export type GetAgentsPaginatedResponse = {
  agents: Array<Agent>;
  limit: number;
  offset: number;
  count: number;
};

export type CreateUsersReq = {
  requests: Array<CreateUserReq>;
};

export type CreateUserReq = UserReq & {
  agent_details: CreateUserAgentDetails;
};

export type UserReq = {
  first_name: string;
  last_name: string;
  email: string;
  role?: string;
  restricted_sites?: Array<string> | null;
  send_invite_email: boolean;
  agent_id?: string;
  agent_details: UserAgentDetails;
  teams?: Array<string> | null;
};

export type EditUserReq = UserReq & {
  create_agent: boolean;
  agent_details: UserAgentDetails;
};

export type CreateUserAgentDetails = UserAgentDetails & {
  create_agent?: boolean;
};

type UserAgentDetails = {
  agent_id?: string;
  imported_id?: string;
  channels?: Array<Channel>;
  site?:
    | {
        value: string;
      }
    | null
    | undefined;
  queues?: Array<{
    value: string;
  }>;
  secondary_queues?: Array<{
    value: string;
  }>;
  teams?: Array<{
    value: string;
  }>;
  skills?: Array<{
    value: string;
  }>;
  platform_associations?: Partial<Record<Platform, AgentPlatformAssociation>>;
  email?: string;
  start_date?: Moment;
  end_date?: Moment;
  agent_role?: string;
  shift_pattern_id?: string;
};

export type SimpleUser = {
  id: string;
  first_name: string;
  last_name: string;
  timezone: string;
  email: string;
  role: UserRole;
  restricted_sites: Array<string>;
};

// ShiftPattern and Shift are the inbound params from the backend for Agent Shifts / Shift Patterns
export type ShiftPattern = {
  external_id: string;
  name: string;
  description?: string;
  timezone: string;
  active: boolean;
  shifts: Shift[];
  created_at: string;
  updated_at: string;
  user_external_id: string;
  user_first_name: string;
  user_last_name: string;
  user_email: string;
  agent_shift_count: number;
  optimization_id?: string;
  event_type_ids?: string[];
  excluded_duration_seconds: number;
  restricted_sites: string[];
};

export type Shift = {
  start_time: string;
  end_time: string;
};

export type AgentShift = {
  external_id: string;
  agent_id: string;
  start_time: string;
  end_time: string;
  created_at: string;
  updated_at: string;
  shift_pattern_id: string;
};

export type Agent = {
  id: string;
  company: Company | null | undefined;
  imported_id: string | null | undefined;
  name: string;
  email: string | null;
  phone: string | null | undefined;
  channels: Array<Channel>;
  role: string;
  site: FilterValue | null | undefined;
  teams: Array<FilterValue> | null | undefined;
  queues: Array<FilterValue> | null | undefined;
  secondary_queues: Array<FilterValue> | null | undefined;
  skills: Array<FilterValue> | null | undefined;
  active: boolean | null | undefined;
  updated_at: string;
  created_at: string;
  productivity: number | null | undefined;
  shift_pattern?: ShiftPattern;
  start_date: string | null | undefined;
  end_date: string | null | undefined;
  intercom_id?: string | null | undefined;
  liveperson_id?: string | null | undefined;
  serenova_id?: string | null | undefined;
  wheniwork_id?: number | null | undefined;
  zendesk_id?: number | null | undefined;
  zendeskchat_id?: number | null | undefined;
  platform_associations?: Partial<Record<Platform, AgentPlatformAssociation>>;
  avatar_url?: string | undefined;
  // Used when creating agents to pass into staffing timeline components
  metadata?: {
    group_id?: string;
  };
  timezone?: string | null | undefined;
};

export type UnfulfilledAgent = {
  agent: Agent;
  time: TimeRange;
};

export type AssignableAgent = {
  agent: Agent;
  matchesTemplateFilters: boolean;
};

export type AgentPlatformAssociation = {
  agent_id: string;
  platform: Platform;
  agent_platform_id: string;
  updated_at: string;
  created_at: string;
};

export const PlatformList = [
  'five9',
  'google',
  'googlecalendar_write',
  'googlecalendar_read',
  'googlecalendar_teamwide',
  'googlecalendar_read_teamwide',
  'googlecalendar_write_teamwide',
  'googledrive',
  'googlesheets',
  'guru',
  'intercom',
  'kustomer',
  'livechat',
  'liveperson',
  'newvoicemedia',
  'salesforce',
  'serenova',
  'slack',
  'slack_cal',
  'upload',
  'internal',
  'wheniwork',
  'workramp',
  'ujet',
  'zendesk',
  'zendesk_clock_in',
  'zendesk_talk',
  'zendesk_chat',
  'zendesk_companion_app',
  'twilio',
  'api',
  'merge',
  'hibob',
  'maestro',
  'talkdesk',
  'zoom_contact_center',
  'workday',
  'amazon_connect',
  'sftp',
  'vendor_requirement',
  'ukg',
  'nice_iex_sftp',
  'verint_sftp',
  'injixo_sftp',
  'alvaria_sftp',
  'custom_platform_sftp',
  'klaus',
  'service_now',
  'zendesk_cal',
  'zendesk_unified_agent_status',
  'loop_returns',
  'shopify',
  'voxpro_sftp',
  'hrwize_sftp',
  'backlog_appearances',
  'ticket_dependencies',
  'ukg_dimensions',
  'observe_ai',
  'notion',
  'doordash_cmp',
  'gladly',
] as const;

export type Platform = (typeof PlatformList)[number];

export type PlatformWithAll = Platform | 'all';

export const isPlatform = (possiblePlatform: string): possiblePlatform is Platform => {
  return PlatformList.some((platform) => platform === possiblePlatform);
};

export const shouldOverrideWithClockInState = (clockInState: MappedAgentStateV2, zendeskState: MappedAgentStateV2) => {
  return (
    zendeskState.agent_state.agent_platform_id === clockInState.agent_state.agent_platform_id &&
    !isNull(clockInState.agent_state.state)
  );
};

export type AgentStateName = string;

export type OccupancyType = 'available' | 'busy' | null;

export type AgentMetric = {
  agent: Agent | null | undefined;
  window: EventWindow;
  messages_sent: number;
  tickets_handled: number;
  scheduled_time: number | null | undefined;
};

export type AgentStateUpdate = {
  agent_platform_id: string;
  state: AgentStateName | null | undefined;
  attributes: any;
};

export type AgentStateUpdateRequest = {
  platform: Platform;
  state: AgentStateUpdate;
  time: number;
};

export type AgentStateGroup = Channel | 'default' | 'timeoff';

export type BillingCoupon = {
  stripe_id: string;
  percent_off: number;
  amount_off: number;
};

export type BillingDetails = {
  id: string;
  created_at: string;
  updated_at: string;
  active_agents: number;
  price_per_agent: number | null | undefined;
  coupon?: BillingCoupon;
  managed?: boolean;
  stripe_customer_id?: string;
  stripe_plan_id?: string;
  stripe_subscription_id?: string;
};

export type GCalEventTransparency = 'opaque' | 'transparent' | 'default';

export const GCAL_EVENT_TRANSPARENCY_DEFAULT: GCalEventTransparency = 'default';
export const GCAL_EVENT_TRANSPARENCY_TRANSPARENT: GCalEventTransparency = 'transparent';
export const GCAL_EVENT_TRANSPARENCY_OPAQUE: GCalEventTransparency = 'opaque';

export type CompanyTier = 'core' | 'pro' | 'enterprise';

export type Company = {
  id: string;
  name: string;
  channels: Array<Channel>;
  sites: Array<string>;
  tags: Array<string>;
  feature_flags: Array<string>;
  updated_at: string;
  created_at: string;
  base_interval: number;
  drag_precision: number;
  start_of_week: number | null | undefined;
  default_channel?: Channel;
  gcal_domains?: Array<string>;
  gcal_event_transparency: GCalEventTransparency;
  zendesk_subdomain?: string;
  gcal_pause_start?: string | null | undefined;
  trial_end: string | null | undefined;
  timeoff_day_length_seconds: number;
  default_timezone: string | null;
  enabled_products: Array<AssembledProduct>;
  tier: CompanyTier;
};

export enum AssembledProduct {
  Assembled = 'assembled',
  Cal = 'cal',
}

export type CustomEventTypeWithDuration = {
  duration: string;
  event: CustomEventType;
  display: DurationDisplayOption;
};
export type CustomEventType = {
  id: string;
  company_id: string;
  display_name: string;
  display_name_short: string | null | undefined;
  useNameAsDisplayName: boolean | null | undefined;
  value: string;
  created_at: string;
  updated_at: string;
  channels: Array<Channel>;
  queues: Array<FilterValue>;
  is_productive: boolean;
  is_productive_aux: boolean;
  is_eligible_for_overtime: boolean;
  is_timeoff: boolean;
  active: boolean;
  gcal_event_transparency: GCalEventTransparency | null | undefined;
  background_color: string | null;
  font_color: string | null;
  timeoff_unit: TimeoffUnit | null | undefined;
  can_clock_in: boolean;
};

export type CustomUserMetrics = {
  id: string;
  report_type: string;
  channel_type: string;
  metrics: Array<string>;
};

type EventBase = {
  id: string;
  channels: Array<Channel>;
  start_unix: number;
  end_unix: number;
  type: string;
  recurrences: Array<string> | null | undefined;
  source_template_id: string | null | undefined;
  source_google_calendar_id?: string | null | undefined;
  timezone: string | null | undefined;
  productive: boolean;
  created_at: string;
  updated_at: string;
  description: string | null | undefined;
};

type EventInstanceBase = {
  id: string;
  event_id: string;
  type: string;
  channels: Array<Channel>;
  start_unix: number;
  end_unix: number;
  productive: boolean;
  source: EventSource | null | undefined;
  summary: string | null | undefined;
};

type EventDeprecatedFields = {
  // prefer the unix timestamp
  start: string;
  end: string;
};

export type Event = EventBase & EventDeprecatedFields;
export type EventInstance = EventInstanceBase & EventDeprecatedFields;

export type ExpandedEventInstance = {
  event: Event;
} & EventInstance;

type AgentStateAttributes = {
  [key: string]: any;
};

export type EmailContactStat = {
  messages_received: number | null | undefined;
  opened: number | null | undefined;
  reopened: number | null | undefined;
  solved: number | null | undefined;
  service_level_met: number | null | undefined;
  service_level_missed: number | null | undefined;
  service_level_percent: number | null | undefined;
  productivity: number | null | undefined;
  reassigned_away: number | null | undefined;
  transferred_away: number | null | undefined;
  case_volume: number | null | undefined;
  segment_service_level_met: number | null | undefined;
  segment_service_level_missed: number | null | undefined;
  segment_service_level_percent: number | null | undefined;
  initial_cases: number | null | undefined;
  transfers_in: number | null | undefined;
  reassigns_in: number | null | undefined;
  reengagements: number | null | undefined;
};

export type ExtraColumnType =
  | 'agent_channels'
  | 'agent_queues'
  | 'agent_role'
  | 'agent_group_id'
  | 'agent_site'
  | 'agent_teams'
  | 'agent_skills'
  | 'agent_timezone'
  | 'event_time'
  | 'all_event_time'
  | 'productive_time'
  | 'nonproductive_time';

export type ExtraStaffingColumn = {
  id: string;
  side: 'left' | 'right';
  index: number;
  name: string;
  type: ExtraColumnType;
  event_types: Array<string> | null | undefined;
  updated_at?: string;
  created_at?: string;
};

export type RealtimeContactStat = {
  received: number | null | undefined;
  avg_csat_score: number | null | undefined;
  avg_handle_time_secs: number | null | undefined;
  csat_count: number | null | undefined;
  occupancy: number | null | undefined;
  service_level_met: number | null | undefined;
  service_level_missed: number | null | undefined;
  service_level_percent: number | null | undefined;
  reassigned_away: number | null | undefined;
  transferred_away: number | null | undefined;
  case_volume: number | null | undefined;
  segment_service_level_met: number | null | undefined;
  segment_service_level_missed: number | null | undefined;
  segment_service_level_percent: number | null | undefined;
  initial_cases: number | null | undefined;
  transfers_in: number | null | undefined;
  reassigns_in: number | null | undefined;
  reopened: number | null | undefined;
  reengagements: number | null | undefined;
};

export type ContactStat = {
  start: string;
  end: string;
  email_stats: EmailContactStat | null | undefined;
  realtime_stats: RealtimeContactStat | null | undefined;
};

export type ContactStatWrapper = {
  channel: Channel;
  queue: string | null | undefined;
  stats: Array<ContactStat>;
};

export type FilterOptionsToFilterValues = {
  readonly queues: Array<FilterValue>;
  readonly teams: Array<FilterValue>;
  readonly skills: Array<FilterValue>;
  readonly sites: Array<FilterValue>;
};

export const INVALID_QUEUE = 'Invalid queue';

export type FilterOptions = {
  readonly channels: Array<Channel>;
  readonly sites_restricted: boolean;
  readonly teams_restricted: boolean;
  readonly queues_restricted: boolean;
  readonly schedules: Array<Schedule>;
  readonly templates: Array<any>; // TODO: Encode
  readonly allocation_statuses?: Array<AllocationStatus>;
  readonly roles: Array<RoleInfo>;
  readonly restricted_sites?: Array<FilterValue>;
  readonly agent_statuses?: Array<{ label: string; value: AgentStatus }>;
  readonly agent_staffable_options?: Array<{ label: string; value: AgentStaffable }>;
  // This cannot be readonly because it is populated conditionally based on a feature flag
  // When/if agent shifts are more deeply integrated into the product, this should be set to readonly
  shift_patterns?: Array<FilterValue>;
  readonly event_type_classification?: Array<{ label: string; value: EventType }>;
} & FilterOptionsToFilterValues;

export type FilterValue = {
  id: string;
  name: string;
  value: string;
  parent_id: string | null | undefined;
  parent_value: string | null | undefined;
  created_at: string;
  updated_at: string;
};

export type FilterValueName = {
  name: FilterValue['name'];
};

export type StaffingTimeWindow = {
  start_hour: number;
  start_minute: number;
  end_hour: number;
  end_minute: number;
  timezone: string;
};

export type EventWindow = {
  start: string;
  end: string;
  interval: number;
};

export type AgentEvent = {
  id: string;
  agent: Agent;
  event_group: EventGroup | null | undefined;
  event_window: EventWindow;
  events: Array<Event>;
  instances: Array<EventInstance>;
};

export type AgentOptionalEvent = {
  id: string;
  agent: Agent | null | undefined;
  event_group: EventGroup | null | undefined;
  event_window: EventWindow;
  events: Array<Event>;
  instances: Array<EventInstance>;
};

export type AgentEventIdOptions = {
  agentId: string | null | undefined;
  eventGroupId: string | null | undefined;
};

export type CommonRange = {
  start_time: number;
  end_time: number;
};

export type RequiredValue = {
  id: string;
  requirement_id: string;
  recurrences: Array<string> | null | undefined;
  required: number;
  required_duration: number;
  start: string;
  end: string;
};

export type RequiredValueUpdate = {
  requirement_id: string;
  recurrences: Array<string> | null | undefined;
  required: number;
  start: number;
  end: number;
};

export type Requirement = {
  id: string;
  name: string;
  description: string | null | undefined;
  standard: boolean;
  full_display: boolean;
  channel: Channel | null | undefined;
  queue: string | null | undefined;
  site: string | null | undefined;
  skill: string | null | undefined;
  team: string | null | undefined;
  event_types: Array<string> | null | undefined;
  productive: boolean | null | undefined;
  required_values?: Array<RequiredValue>;
  updated_at: string;
  created_at: string;
};

type PeopleAllocation = {
  site: FilterValue;
  count: number;
};

export type DisplayedRequirement = {
  id: string;
  channel: Channel;
  queue: FilterValue;
  allocated_count: number;
  allocated_sites_count: number;
  forecasted_count: number;
  site_allocated_counts: Array<PeopleAllocation>;
  all_sites_count: number;
};

export type ExportRequirementModalOptions = {
  channel: string;
  queue: FilterValue | null | undefined;
  all_sites_count: number;
  start_date: Moment;
  end_date: Moment;
};

export type DeleteRequirementModalOptions = {
  channel: string;
  queue: string;
  all_sites_count: number;
};

export type DeleteAllocationTemplateModalOptions = {
  name: string;
  description?: string;
  templateId: string;
  channel: string;
  queue: string;
  all_sites_count?: number;
};

export type AllocationTemplate = {
  id: string;
  name: string;
  description: string;
  channel: Channel;
  queue_id: string;
  updated_at: string;
  site_ids: Array<string>;
  values: Array<AllocationTemplateValue>;
  created_by: SimpleUser | null | undefined;
  updated_by: SimpleUser | null | undefined;
};

export type AllocationTemplateValue = {
  id: string;
  allocation_template_id: string;
  filter_id: string;
  day: number;
  hour: number;
  percentage: number;
  created_at: string;
  created_by: string | null | undefined;
  updated_at: string;
  updated_by: string | null | undefined;
};

export type CreateAllocationTemplateForm = {
  name: string;
  description: string;
  channel: Channel | null;
  queue: FilterValue | null;
};

export type RequirementAssociatedTemplate = {
  id: string;
  association_id: string;
  template_name: string;
  primary: boolean;
  deleted: boolean;
};

export type StaffingRequiredValue = {
  start: string;
  end: string;
  required: number;
  requirement_id: string;
  site_id: string;
};
export type StaffingForecastValue = {
  start: string;
  end: string;
  value: number;
};

export type StaffingRequirement = {
  id: string;
  channel: string;
  queue_id: string;
  required_values: Array<StaffingRequiredValue>;
  forecasts: Array<StaffingForecastValue>;
  site_ids: Array<string>;
  template?: AllocationTemplate;
};

export type StaffingRequiredValueUpdate = {
  id: string;
  value: number;
};

export type Schedule = {
  id: string;
  live: boolean;
  pagination: Pagination;
  title: string;
  description: string | null | undefined;
  common_ranges: Array<CommonRange>;
  agent_events: Array<AgentEvent>;
  updated_at: string;
  updated_by: any | null | undefined;
  created_at: string;
  created_by: any | null | undefined;
};

export type IntervalStaffing = {
  start: string;
  end: string;
  agents: number;
  capacity: number;
  capacity_duration: number;
  unfiltered_capacity: number;
  unfiltered_capacity_duration: number;
  required: number;
  required_duration: number;
  net: number;
  net_duration: number;
  unfiltered_net: number;
  unfiltered_net_duration: number;
  base: number;
  surplus: number;
  deficit: number;
};

export type ChannelStaffing = {
  channel: Channel;
  intervals: Array<IntervalStaffing>;
};

export type SupplementalStaffing = {
  requirement: Requirement;
  intervals: Array<IntervalStaffing>;
};

export type Forecast = {
  start: string;
  end: string;
  contacts: number | null | undefined;
  case_volume_with_reopens: number | null | undefined;
  backlog: Array<BacklogBucket> | null | undefined;
  required_duration: number;
  required_staffing: number;
  realtime_metrics?: RealtimeMetrics;
  email_metrics?: EmailMetrics;
};

export type RealtimeMetrics = {
  occupancy?: number;
  service_level?: number;
  avg_handle_time_secs?: number;
  avg_segment_handle_time_secs?: number;
};

export type EmailMetrics = {
  messages_received: number;
  tickets_closed: number;
  tickets_open: number; // aka backlog
  tickets_opened: number;
  case_volume: number;
  tickets_reopened: number;
  tickets_sla_met: number;
  tickets_sla_breached: number;
  service_level?: number;
  first_response_time?: number;
  productivity: number;
  unit_work_throughput: number;
};

export type RawForecast = {
  channel: Channel;
  queue: string | null | undefined;
  forecasts: Array<Forecast>;
};

export type ForecastUpdate = {
  cell: CellType;
  channel: Channel;
  queue: string | null | undefined;
  metric: NamedMetric;
  value: number;
  oldValue: number;
};

export type ForecastAdjustmentRaw = {
  adjust_end: string;
  adjust_name: string;
  adjust_start: string;
  company_id: string;
  created_at: string;
  created_by: SimpleUser | null | undefined;
  forecast_model_id: string;
  type: string;
  value: number;
};

export type ForecastOutlierRaw = {
  channel: string;
  company_id: string;
  created_at: string;
  created_by: SimpleUser | null | undefined;
  end: string;
  name: string;
  queue: string | null | undefined;
  start: string;
};

export type ForecastTotalRaw = {
  id: string;
  start: number;
  end: number;
  value: number;
};

export type ForecastPreviewRaw = {
  channel: string;
  created_at: string;
  end: string;
  id: string;
  queue: string | null | undefined;
  source: {
    forecast_model_id: string;
  };
  start: string;
  value: number;
  value_type: string;
};

export type ActualsRaw = {
  start: string;
  end: string;
  value: number;
};

export type StaffingMetrics = {
  interval: number;
  raw_forecasts: Array<RawForecast>;
  standard: Array<IntervalStaffing>;
  channel_staffing: Array<ChannelStaffing>;
  supplemental: Array<SupplementalStaffing> | null | undefined;
  dynamic: boolean;
};

export type ForecastedActualPair = {
  forecasted: number | null;
  actual: number | null;
  attributes?: MetricAttributes;
};

export type ForecastedVsActuals = {
  start_time: number;
  end_time: number;
  channel: Channel;
  queue: string | null | undefined;
  service_level: ForecastedActualPair;
  service_level_missed: ForecastedActualPair;
  service_level_met: ForecastedActualPair;
  first_response_time: ForecastedActualPair;
  first_response_count: ForecastedActualPair;
  transferred_away: ForecastedActualPair;
  reassigned_away: ForecastedActualPair;
  messages_received: ForecastedActualPair;
  cases_answered: ForecastedActualPair;
  cases_opened: ForecastedActualPair;
  cases_reopened: ForecastedActualPair;
  cases_solved: ForecastedActualPair;
  productivity: ForecastedActualPair;
  contacts_received: ForecastedActualPair;
  contacts_abandoned: ForecastedActualPair;
  handle_time: ForecastedActualPair;
  handle_time_count: ForecastedActualPair;
  occupancy: ForecastedActualPair;
  staffing_scheduled: number;
  staffing_required: number;
  staffing_net: number;
};

export type Template = {
  id: string;
  title: string;
  description: string | null | undefined;
  timezone: string;
  schedule_id: string;
  channel: Channel | null | undefined;
  site: string | null | undefined;
  skill: string | null | undefined;
  queue: string | null | undefined;
  team: string | null | undefined;
  pagination: Pagination;
  agent_events: Array<AgentEvent>;
  source_start: string;
  source_end: string;
  interval: TemplateInterval;
  updated_at: string;
  updated_by: any | null | undefined;
  created_at: string;
  created_by: any | null | undefined;
};

export type TemplateViewType = 'list' | 'card';

export type FeatureFlag = {
  name: string;
  active: boolean;
};

export type CompanyConfig = {
  vendor_requirement_interval: Array<VendorRequirementInterval>;
  prevent_save_if_stale: boolean;
  end_of_shift_exclusion: boolean;
  exclusion_trigger_minutes: number;
  zendesk_unified_agent_status: boolean;
  agent_state_editing_enabled: boolean;
  event_classification: EventClassification;
  schedule_adherence_enabled: boolean;
  show_gcal_event_details: boolean;
  bpo_planner_comparison_threshold: Array<number>;
};

export type EnrichedCompanyConfigs = {
  vendor_requirement_interval: CompanyConfigDetails;
  prevent_save_if_stale: CompanyConfigDetails;
  end_of_shift_exclusion: CompanyConfigDetails;
  exclusion_trigger_minutes: CompanyConfigDetails;
  zendesk_unified_agent_status: CompanyConfigDetails;
  agent_state_editing_enabled: CompanyConfigDetails;
  event_classification: CompanyConfigDetails;
  schedule_adherence_enabled: CompanyConfigDetails;
  show_gcal_event_details: CompanyConfigDetails;
  bpo_planner_comparison_threshold: CompanyConfigDetails;
};

export type CompanyConfigDetails = {
  company_id: string;
  value: string | number | boolean | Array<VendorRequirementInterval> | Array<number>;
  updated_at: string | null | undefined;
  updated_by: SimpleUser | null | undefined;
};

export type GCalSyncType = 'write_primary' | 'write_secondary' | 'write_secondary_oauth' | 'read';
export const isSecondaryGCalSyncType = (syncType: GCalSyncType): boolean => {
  return syncType === 'write_secondary' || syncType === 'write_secondary_oauth';
};

export type GoogleCalendarSync = {
  id: string;
  sync_type: GCalSyncType;
  email: string;
  additional_emails?: Array<string>;
  google_id: string;
  created_at: string;
  updated_at: string;
};

export type GoogleCalendarSyncOverview = {
  write?: GoogleCalendarSync;
  read?: GoogleCalendarSync;
};

export type StaffingParameters = {
  variables: Array<Variables>;
  channel_configurations: Array<ChannelConfiguration>;
};

export type Variables = {
  id: string;
  channel: Channel;
  queue: string | null | undefined;
  shrinkage: number;
  forecast_multiplier: number;
  agent_productivity: number;
  average_handle_time: number;
  concurrency: number;
  minimum_staffing: number;
  required_agents_override: number;
  service_level_percent: number;
  service_level_seconds: number;
  reopens_multiplier: number;
  should_infer: boolean;
  updated_at?: string;
  updated_by?: SimpleUser;
};

export type ChannelConfiguration = {
  channel: Channel;
  contact_input: ContactInput;
  active: boolean;
  updated_at: string;
};

export type ChannelConfigurationsAudit = {
  channel: Channel;
  original_channel_configuration: ChannelConfiguration;
  new_channel_configuration: ChannelConfiguration;
  user: SimpleUser;
  created_at: string;
};

export type TimeoffRequestStatus = 'pending' | 'approved' | 'denied' | 'canceled';
export type EventChangeRequestStatus = 'pending' | 'approved' | 'denied' | 'canceled' | 'invalid' | 'expired';

export type TimeoffRequest = {
  id: string;
  user: SimpleUser;
  agent: Agent;
  event_type: CustomEventType;
  description: string | null | undefined;
  status: TimeoffRequestStatus;
  start_time: string | null | undefined;
  end_time: string | null | undefined;
  created_at: string;
  eval_results: Array<TimeoffEvaluation>;
  editable: boolean;
  actionable: boolean;
  actioner: SimpleUser | null | undefined;
  actioned_at: string | null | undefined;
  cancelable: boolean;
  timeoff_unit: TimeoffUnit | null | undefined;
  timeoff_length: number | null | undefined;
  total_duration_seconds: number | null | undefined;
  merge_id: string | null | undefined;
  remote_id: string | null | undefined;
  hr_platform_start_date: string | null | undefined;
  update_comment: string | null | undefined;
  source_platform: string | null | undefined;
  sync_error: string | null;
};

export type UpdatedTimeoffResponse = {
  id: string;
  description: string | null | undefined;
  start_time: string;
  end_time: string;
  event_type: CustomEventType;
  timeoff_unit: TimeoffUnit | null | undefined;
  timeoff_length: number | null | undefined;
};

export type TimeoffRequestsPaginated = {
  requests: Array<TimeoffRequest>;
  count: number;
  limit: number;
  offset: number;
};

export type TimeoffMetric = {
  name: string;
  value: string;
  details: string;
  maximum?: number;
};

export type Decision = 'approve' | 'unsure' | 'deny';

export type TimeoffEvaluation = {
  external_metric: TimeoffMetric;
  decision: Decision;
  evaluated_details: string;
  evaluation_date: { start: string; end: string };
  max_overlap?: number;
  hourly_availability?: { [key: number]: number };
};

export enum EvaluatorType {
  Overlap = 'overlap',
  Advance = 'advance',
  Noop = 'noop',
}

export type AdvanceEvaluator = 'min_advance' | 'max_advance' | 'min_advance_date' | 'max_advance_date';

export type AgentTimeoffAvailability = {
  evaluation_date_map?: { [key: number]: TimeoffEvaluation };
  advance_evaluators?: Record<AdvanceEvaluator, number>;
  company_id: string;
};

export type TimeoffUnit = 'hours' | 'days';

export const TimeoffUnitHours: TimeoffUnit = 'hours';
export const TimeoffUnitDays: TimeoffUnit = 'days';

export type TimeoffDuration = {
  timeoffLength: number;
  totalDurationSeconds: number;
};

/**
 * Types that are reused throughout the frontend code
 */

export type CSSStyle = CSSStyleDeclaration & {
  [':before']: Partial<CSSStyleDeclaration>;
  [':after']: Partial<CSSStyleDeclaration>;
  [':hover']: Partial<CSSStyleDeclaration>;
  [':active']: Partial<CSSStyleDeclaration>;
  [':first-child']: Partial<CSSStyleDeclaration>;
  ['::-webkit-scrollbar']: Partial<CSSStyleDeclaration>;
  ['::-webkit-scrollbar-thumb']: Partial<CSSStyleDeclaration>;
  ['::-webkit-scrollbar-thumb:hover']: Partial<CSSStyleDeclaration>;
};

export type RangeChange = 'increase_range' | 'decrease_range';

export type SortType =
  | 'name'
  | 'event_start'
  | 'event_type'
  | 'agent_role'
  | 'site'
  | 'team'
  | 'channels'
  | 'emails'
  | 'queues'
  | 'shift_pattern';

export enum SortDirection {
  Ascending = 'asc',
  Descending = 'desc',
}

export type SortKey = {
  type: SortType;
  order: 'ascending' | 'descending';
};

export type EventFilter = {
  label: string;
  value: string;
  channels: Array<Channel>;
  style?: any;
};

export type EventFilters = {
  [key: string]: EventFilter;
};
export type EditMode = 'editing_all' | 'editing_disabled' | 'editing_agent';

export type RangeDetails = {
  range: Range;
  interval: number; // seconds in a "block", generally 60*60 or 60*60*24,
  intervalWidth: number; // width for a "block" (15 min, 30 min, 1 hr, 1 day),
  timelineWidth: number;
  totalRange: number; // seconds in the time window that is being viewed,
  titleWidth: number; // set from a constant, its the width of the left most column,
  cellsStartWidth: number; // titleWidth + extra left column offset,
  cellsEndWidth: number; // timelineWidth - extra right column offset
};

export type ExportStaffingOpts = {
  allChannels: boolean;
  allQueues: boolean;
  allSites: boolean;
  excludeEmptyRows: boolean;
};

export type FilterParams = {
  channel: Channel | null | undefined;
  date: any;
  start: any;
  end: any;
  limit: number;
  range: Range;
  schedule_id: string | null | undefined;
  site: string | null | undefined;
  restricted_site: string | null | undefined;
  skill: string | null | undefined;
  team: string | null | undefined;
  queue: string | null | undefined;
  agentSelect: Array<any> | null | undefined;
  // TODO Doesn't actually come from backend model
  limitModified: boolean;
  setByLocationChange: boolean;
  allocationStatus?: AllocationStatus | null;
  role?: string | null;
  agent_status?: AgentStatus | null;
  agent_staffable?: AgentStaffable | null;
  dateRangeInterval?: string;
  event_type_classification?: EventType | null;
};

export type FilterType = 'skills' | 'queues' | 'sites' | 'teams';

export type EventTypes = {
  activeCustomEventTypes: Array<CustomEventType>;
  activeCustomEventTypesMap: {
    [key: string]: CustomEventType;
  };
  allCustomEventTypes: Array<CustomEventType>;
  allCustomEventTypesMap: {
    [key: string]: CustomEventType;
  };
};

export type AgentInstanceMap = {
  [key: string]: Array<TimelineEventInstance>;
};

export type FeatureFlags = {
  [key: string]: boolean;
};

export type Pagination = {
  count: number;
  limit: number;
  offset: number;
};

export type PaginationUpdate = {
  limit: number;
  offset: number;
};

export type ForecastedVsActualColumnType = 'forecasted' | 'actual';

export const COLUMN_FORECASTED: ForecastedVsActualColumnType = 'forecasted';
export const COLUMN_ACTUAL: ForecastedVsActualColumnType = 'actual';

export type NamedMetric = {
  value: string;
  label: string;
  metricGroup?: NamedMetricGroup;
  partialLabel?: string;
  type?: 'basic' | 'forecasted' | 'header' | 'net' | 'required' | 'scheduled';
  supplementalId?: string;
  channels?: Channel[];
  unit?: MetricUnit;
  // todo(wei-wei): this field specifies which (sub)column in the table is hidden from the user
  // we want to rename / rework this in the future so it is not as confusing
  missing?: ForecastedVsActualColumnType;
  show_sum?: boolean;
  cached_metric_value_type?: CachedMetricsValueType;
  generator_metric_type?: string;
  hidden?: boolean;
  tooltip?: string;
  tooltip_hyperlink?: string;
  tooltip_equation?: string;
  renderTooltip?: () => React.ReactNode;
};

export type StaffingSettingsUpdate = {
  hideOfflineAgents: boolean;
  showGridLine: boolean;
  showTimeInBlock: boolean;
  sortBy: Array<SortKey>;
  eventFilters: any;
  extraColumns: Array<ExtraStaffingColumn>;
  hideExtraColumns: boolean;
  hideGCalProvenance: boolean;
  timeWindow: StaffingTimeWindow | null | undefined;
  secondaryTimezones: Array<string>;
  staffingTimelineV2: boolean;
};

export type StaffingSettings = StaffingSettingsUpdate & {
  isLoaded: boolean;
};

export type ScorecardSettings = {
  visibleToAgents: boolean;
  visibleToOthers: boolean;
};

export type GenerationSettings = {
  evaluators: Array<GenerateScheduleEvaluator>;
};

export type DisplayCell = {
  start: any;
  end: any;
  instances: Array<TimelineEventInstance>;
};

export const GoogleCalendarEventSource = 'Google Calendar';
type EventSource = 'Google Calendar';

export const TimeOffRequestSourceType = 'timeoff_request';

// Note: This is different than the Event that comes from the backend because
// we have additional fields and we also don't have created_at, updated_at, etc.
export type TimelineEvent = {
  id: string;
  agent: Agent | null | undefined;
  preliminary?: boolean;
  channels: Array<Channel>;
  start_unix: number;
  end_unix: number;
  source_template_id?: string | null | undefined;
  source?: EventSource | null | undefined;
  source_type?: string | null | undefined;
  source_external_id?: string | null | undefined;
  type: string;
  description: string | null | undefined;
};

export type TimelineEventInstance = EventInstanceBase & {
  agentEventsId: string;
  event: TimelineEvent;
};

export type CellType = {
  start: any;
  end: any;
  width: number;
};

export type DisplayPoint = {
  time: any;
  type: 'start' | 'end';
  instance: TimelineEventInstance;
};

export type ExtraColumnInfo = {
  extraColumns: Array<ExtraStaffingColumn> | null | undefined;
  firstRow: boolean;
  totalRows: number;
};

export type ExactRequirement = {
  required: number;
};

export type RangeRequirement = {
  min: number | null | undefined;
  max: number | null | undefined;
};

export type CountRequirement = {
  exact?: ExactRequirement;
  range?: RangeRequirement;
};

export type CountRequirementType = 'exact' | 'range';

export type TimeRequirement = {
  time: CountRequirement;
  modulus: number;
};

export type TimeDiffRequirement = {
  difference: CountRequirement;
  modulus: number;
};

export type ModulusRequirement = {
  modulus: number;
};

export type Segment = {
  start: number;
  end: number;
  type?: string;
};

export type SegmentContainer = {
  segments: Array<Segment>;
};

export type CurrencyType = 'usd';

export type CurrencyAmount = {
  amount: number;
  currency: CurrencyType;
};

export type AgentGroupRequirement = {
  group_id: string;
  name: string;
  num_schedules: CountRequirement;
  num_shifts: CountRequirement;
  shift_length: CountRequirement | null | undefined;
  shift_separation: CountRequirement | null | undefined;
  shift_start: TimeRequirement | null | undefined;
  shift_end: TimeRequirement | null | undefined;
  start_difference: TimeDiffRequirement | null | undefined;
  end_difference: TimeDiffRequirement | null | undefined;
  consecutive_days_off: ModulusRequirement | null | undefined;
  valid_segment_containers: Array<SegmentContainer> | null | undefined;
  hourly_rate: CurrencyAmount | null | undefined;
  agent_ids: string[] | null | undefined;
  optimization_id: string | null | undefined;
  timezone: string | null;
  assigned_queue_channels?: AssignedQueueChannel[];
};

export type AssignedQueueChannel = {
  forecast_filters: ForecastFilters | null | undefined;
  min_total_duration?: number | null;
  max_total_duration?: number | null;
};

export type AggregateType = 'required_staffing' | 'realtime_volume' | 'email_volume';
export const REQUIRED_STAFFING: AggregateType = 'required_staffing';
export const REALTIME_VOLUME: AggregateType = 'realtime_volume';
export const EMAIL_VOLUME: AggregateType = 'email_volume';

export type AggregateMetadata = {
  interval_duration: number | null | undefined;
  target_service_level: number | null | undefined;
  target_answer_time: number | null | undefined;
  handle_time: number | null | undefined;
  agent_productivity: number | null | undefined;
  concurrency: number | null | undefined;
  shrinkage: number | null | undefined;
  max_first_response_time: number | null | undefined;
};

export type ForecastFilters = {
  channel: Channel;
  queue: string | null | undefined;
};

export type ForecastRange = {
  start: number;
  end: number;
};

export type AggregateDetails = {
  id?: string;
  type: AggregateType;
  forecast_filters: ForecastFilters | null | undefined;
  min_staffing: number;
  metadata: AggregateMetadata;
  event_type_external_id: string | null | undefined;
  priority?: number;
};

export type RequiredIntervalValue = {
  required: number;
  interval: number;
};

export type AggregateRequirement = {
  details: AggregateDetails;
  values: Array<RequiredIntervalValue>;
};

export type WindowRequirement = {
  start: number;
  end: number;
};

// non optional
export type GenerateScheduleRequirements = {
  agent_groups: Array<AgentGroupRequirement>;
  window: WindowRequirement;
  forecast_range: ForecastRange;
  aggregates: AggregateRequirement[];
};

export type GenerateScheduleAlgorithm = 'basic_greedy';
export type GenerateScheduleRange = 'week';
export type GenerateScheduleEvaluator =
  | 'rmse'
  | 'trend_rmse'
  | 'rsquared'
  | 'avg_sla'
  | 'avg_occupancy'
  | 'avg_first_response'
  | 'schedule_cost'
  | 'distinct_agents'
  | 'scheduled_hours'
  | 'scheduled_ftes';

export type GenerateScheduleRequest = {
  name: string;
  description: string | null | undefined;
  timezone: string | null;
  currency_type: CurrencyType | null;
  algorithm: GenerateScheduleAlgorithm;
  range: GenerateScheduleRange;
  interval: number;
  requirements: GenerateScheduleRequirements;
};

export type GeneratedScheduleActivity = {
  start: number;
  end: number;
  event_type_id: string;
  productive: boolean;
};

export type GeneratedScheduleShift = {
  start: number;
  end: number;
  activities: Array<GeneratedScheduleActivity> | null | undefined;
  event_type_external_id: string;
};

export type GeneratedSchedule = {
  id: string;
  group_id: string;
  shifts: Array<GeneratedScheduleShift>;
  agent_id: string | null | undefined;
};

export type GenerateScheduleResidual = {
  interval: number;
  required: number;
  observed: number;
  net: number;
};

export type GenerateScheduleIntervalResult = {
  result: number | null | undefined;
  interval: number;
};

export type GenerateScheduleEvaluation = {
  type: GenerateScheduleEvaluator;
  result: number | null | undefined;
  interval_results: Array<GenerateScheduleIntervalResult>;
};

export type GenerateScheduleEvaluatorDetails = {
  display_name: string;
  display_name_short: string;
  description: string;
  has_interval_results: boolean;
  normalized: boolean;
};

export type GenerateScheduleEvaluationsMap = Partial<Record<GenerateScheduleEvaluator, GenerateScheduleEvaluation>>;

export type GenerateScheduleEvaluatorDetailsMap = Partial<
  Record<GenerateScheduleEvaluator, GenerateScheduleEvaluatorDetails>
>;

type ChannelEvaluationsMap = {
  [key in Channel]: {
    [key in GenerateScheduleEvaluator]: number | undefined;
  };
};

export type GenerateScheduleResult = {
  id: string;
  name: string;
  description: string | null | undefined;
  in_progress: boolean;
  errors: Array<string>;
  algorithm: GenerateScheduleAlgorithm;
  range: GenerateScheduleRange;
  interval: number;
  requirements: GenerateScheduleRequirements;
  schedules: Array<GeneratedSchedule>;
  residuals: Array<GenerateScheduleResidual>;
  channel_residuals: {
    [key in Channel]: Array<GenerateScheduleResidual>;
  };
  aggregate_residuals: { [key: string]: { [key: number]: GenerateScheduleResidual } };
  evaluator_details: GenerateScheduleEvaluatorDetailsMap | null | undefined;
  aggregate_evaluations: { [key: string]: GenerateScheduleEvaluationsMap };
  channel_evaluations: ChannelEvaluationsMap;
  schedule_evaluations: {
    [key in GenerateScheduleEvaluator]?: number;
  };
  template_details: TemplateDetails;
  updated_at: string;
  created_at: string;
  agent_map: {
    [key: string]: Agent;
  };
  timezone: string | null;
  currency_type: CurrencyType | null;
};

export type TemplateDetails = {
  id: string;
  published: boolean;
  title: string;
  description: string | null | undefined;
};

export type ForecastInterval = 'hourly' | 'daily' | 'weekly';

export type EvaluationMetrics = {
  mean_error: number | null;
  mean_percentage_error: number | null;
  mean_scaled_error: number | null;
  mean_absolute_error: number | null;
  mean_absolute_percentage_error: number | null;
  mean_absolute_scaled_error: number | null;
  over_mean_absolute_error: number | null;
  over_mean_absolute_percentage_error: number | null;
  over_mean_absolute_scaled_error: number | null;
  under_mean_absolute_error: number | null;
  under_mean_absolute_percentage_error: number | null;
  under_mean_absolute_scaled_error: number | null;
  median_absolute_error: number | null;
  median_absolute_percentage_error: number | null;
  mean_squared_error: number | null;
  root_mean_squared_error: number | null;
  symmetric_mean_absolute_percentage_error: number | null;
};

export type ForecastEvaluationMetrics = {
  interval: EvaluationMetrics;
  day: EvaluationMetrics;
  week: EvaluationMetrics;
  num_outliers: number;
};

export type ForecastEvaluation = {
  metrics: ForecastEvaluationMetrics;
  raw_metrics: ForecastEvaluationMetrics | null | undefined;
  forecasts: Array<TimeSeriesPoint>;
  actuals: Array<TimeSeriesPoint>;
  isLoading?: boolean;
};

export type TimeSeriesPoint = {
  x: number;
  y: number;
};

export enum ModelType {
  Combined = 'combined',
  Contacts = 'contacts',
  Reopened = 'reopened',
  Messages = 'messages',
  Productivity = 'productivity',
  UnitOfWorkThroughput = 'unit_of_work_throughput',
  Aht = 'aht',
  UnitOfWorkAht = 'unit_of_work_aht',
  CaseVolume = 'case_volume',
}

export enum MethodType {
  Python = 'python',
  Averaging = 'averaging',
  AveragingWithMomentum = 'averaging_with_momentum',
  Seasonal = 'seasonal',
  Zeros = 'zeros',
  Prophet = 'prophet',
}

export type ForecastModel = {
  id: string;
  company_id: string;
  channel: Channel;
  queue: string | null | undefined;
  active: boolean;
  created_at: string;
  disabled_at: string;
  type: ModelType;
  method_type: MethodType;
  num_weeks: number | null | undefined;
  name: string;
  master: boolean;
};

export type ForecastSettings = {
  id: string;
  range_start: number;
  range_end: number;
};

export type IntegrationMappingKeywordType =
  | 'group'
  | 'tag'
  | 'ticket_form'
  | 'phone_number'
  | 'talkdesk_phone_number'
  | 'salesforce_agentwork_skill_id'
  | 'summary'
  | 'default'
  | 'timeoff'
  | 'aux_productive'
  | 'aux_non_productive'
  | 'custom_event_type'
  | 'channel';
export type IntegrationMappingObjectType = 'queue' | 'event' | 'exclusion' | 'cal_resolution_path';
export type IntegrationMappingMatchMethod =
  | 'exact_match'
  | 'negate_exact_match'
  | 'substring_match'
  | 'negate_substring_match'
  | 'prefix_match'
  | 'wildcard_match'
  | 'is_empty'
  | 'greater_than'
  | 'less_than'
  | 'greater_than_equal'
  | 'less_than_equal'
  | 'negate_is_empty'
  | 'default';

export type SelectOption<T extends any> = {
  label: string;
  value: T;
  isDisabled?: boolean;
};

export const IntegrationMappingPlatformList = [
  'intercom',
  'kustomer',
  'zendesk',
  'zendesk_chat',
  'zendesk_talk',
  'salesforce',
  'googlecalendar_read',
  'api',
  'ujet',
  'five9',
  'talkdesk',
  'zoom_contact_center',
  'sftp',
  'vendor_requirement',
  'amazon_connect',
  'gladly',
  'doordash_cmp',
] as const;

export type IntegrationMappingPlatform = (typeof IntegrationMappingPlatformList)[number];

export function isIntegrationMappingPlatform(platform: Platform): platform is IntegrationMappingPlatform {
  return IntegrationMappingPlatformList.some((i) => i === platform);
}

export type IntegrationMappings = Partial<Record<IntegrationMappingPlatform, Array<ObjectMapping>>>;

export type ObjectMapping = {
  id: string;
  object_id: string | null | undefined;
  object_type: IntegrationMappingObjectType;
  keyword: string;
  keyword_type: IntegrationMappingKeywordType;
  match_method: IntegrationMappingMatchMethod;
  name: string | null | undefined;
  priority: number;
  integration: IntegrationMappingPlatform;
  active: boolean;
  created_at: string;
  updated_at: string;
  site_external_id: string | null | undefined;
};

export type IntegrationDisplayData = {
  scope: string | null | undefined;
  subdomain: string | null | undefined;
  instance_url: string | null | undefined;
  email: string | null | undefined;
  user_id: string | null | undefined;
  team_name: string | null | undefined;
  created_at: string;
  regions: Array<string> | null | undefined;
  gdrive_folder_id: string | null | undefined;
};

export type GoogleCalendarTeamwideAuthResponse = {
  created_at: string;
  email: string;
};

export type IntercomAuthResponse = {
  created_at: string;
};

export type KustomerAuthResponse = {
  created_at: string;
};

export type UjetAuthResponse = {
  subdomain: string;
  created_at: string;
};

export type SalesforceAuthResponse = {
  instance_url: string;
  scope: string;
  created_at: string;
};

export type SlackAuthResponse = {
  created_at: string;
  scope: string;
  team_name: string;
  user_id: string;
};

export type ZendeskAuthResponse = {
  created_at: string;
  scope: string;
};

export type ZendeskChatAuthResponse = {
  scope: string;
  created_at: string;
};

export type ZendeskTalkAuthResponse = {
  created_at: string;
};

export type MergeAuthResponse = {
  created_at: string;
};

export type MergeAuthData = {
  account_token: string;
  created_at: string;
};

export type PlatformAuthResponse = {
  five9?: IntegrationDisplayData;
  googlecalendar_teamwide?: GoogleCalendarTeamwideAuthResponse;
  googlecalendar_mappings?: {
    [key: string]: Array<string>;
  };
  googledrive?: IntegrationDisplayData;
  intercom?: IntercomAuthResponse;
  kustomer?: KustomerAuthResponse;
  salesforce?: SalesforceAuthResponse;
  slack?: SlackAuthResponse;
  slack_cal?: IntegrationDisplayData;
  ujet?: UjetAuthResponse;
  zendesk?: ZendeskAuthResponse;
  zendesk_chat?: ZendeskChatAuthResponse;
  zendesk_talk?: ZendeskTalkAuthResponse;
  merge?: MergeAuthResponse;
  maestro?: IntegrationDisplayData;
  talkdesk?: IntegrationDisplayData;
  zoom_contact_center?: IntegrationDisplayData;
  amazon_connect?: IntegrationDisplayData;
  ukg?: IntegrationDisplayData;
  hibob?: IntegrationDisplayData;
  klaus?: IntegrationDisplayData;
  service_now?: IntegrationDisplayData;
  workday?: IntegrationDisplayData;
  guru?: IntegrationDisplayData;
  googlesheets?: IntegrationDisplayData;
  loop_returns?: IntegrationDisplayData;
  shopify?: IntegrationDisplayData;
  observe_ai?: IntegrationDisplayData;
  notion?: IntegrationDisplayData;
  gladly?: IntegrationDisplayData;
  doordash_cmp?: IntegrationDisplayData;
};

/**
 * @deprecated Prefer `moment.Moment`
 */
export type Moment = moment.Moment;
/**
 * @deprecated Prefer `moment.Duration`
 */
export type MomentDuration = moment.Duration;

export type GraphDatasetValues = Array<number>;

export type GraphDataset = {
  data: GraphDatasetValues;
  label: string;
  backgroundColor?: string;
  color?: string;
  borderWidth?: number;
  borderColor?: string;
  fill?: boolean;
  tension?: number;
};

export type GraphData = {
  labels: Array<string>;
  datasets: Array<GraphDataset>;
};

export type AgentStateV2Attributes = any;

export type AgentStateV2 = {
  agent_platform_id: string;
  start_unix: number;
  end_unix: number;
  platform: Platform;
  state: string | null;
  attributes: AgentStateV2Attributes | null | undefined;
  is_current: boolean;
  created_at: number;
  start_time: number | null | undefined;
  end_time: number | null | undefined;
  external_id: string | null | undefined;
};

export type AgentStateType = {
  id: string;
  external_id: string;
  company_id?: string;
  agent_state_name: AgentStateName;
  platform?: Platform;
  create_at?: Moment;
  update_at?: Moment;
  channels?: Array<Channel>;
  queues?: Array<FilterValue>;
  occupancy_type?: OccupancyType;
};

export type TicketStatusState = {
  ticket_id: string;
  ticket_status: string;
  start_time: number;
  end_time: number;
  ticket_channel?: string | null | undefined;
};

export type MappedAgentStateV2 = {
  agent_id: string | null | undefined;
  agent_state: AgentStateV2;
  is_excluded: boolean;
  threshold_data:
    | {
        status: string;
        threshold: number;
        id: string;
      }
    | null
    | undefined;
};

export type PlatformToAgentStatesV2 = Partial<Record<Platform, Array<MappedAgentStateV2>>>;

export type AgentIdToOutOfAdherenceDetails = {
  [key: string]: OutOfAdherenceDetails;
};

export type OutOfAdherenceDetails = {
  threshold_external_id?: string;
  duration: number;
  status?: string;
};

export type AgentStateRecords = {
  [key: string]: Array<AgentStateV2>;
};

export type AgentStateRecordsMap = Partial<Record<Platform, AgentStateRecords>>;

export type AgentStateExclusion = {
  start: Moment;
  end?: Moment;
  agent_external_id?: string;
  platform?: Platform | null;
  created_at: number;
  company_id?: string;
};

export type ColorTheme = 'dark' | 'light';
export type ColorModeSetting = ColorTheme | 'system';
export type ColorSet = {
  backgroundColor: string;
  mainTextColor: string;
  secondaryTextColor: string;
};

export type IntervalUnit = 'day' | 'week' | 'month' | 'year';

export type AbsoluteIntervalDetails = {
  start: string;
  end: string;
};

export type RelativeIntervalDetails = {
  interval: number;
  unit: IntervalUnit;
};

export type CurrentPeriodIntervalDetails = {
  unit: IntervalUnit;
};

export type TimeIntervalDetails =
  | {
      type: 'relative';
      relative_interval_details: RelativeIntervalDetails;
    }
  | {
      type: 'absolute';
      absolute_interval_details: AbsoluteIntervalDetails;
    }
  | {
      type: 'current_period';
      current_period_interval_details: CurrentPeriodIntervalDetails;
    };

export type MetricsView = {
  id: string;
  view_definition_id: string;
  time_interval_details: TimeIntervalDetails;
  channels: Array<Channel>;
  queue_ids: Array<string>;
  agent_id: string | null | undefined;
  is_recomputing: boolean;
  has_recomputed: boolean;
  most_recent_record_id: string | null | undefined;
  auto_recompute_frequency_seconds: number;
};

export type MetricsFilters = {
  agent_id?: string | null | undefined;
  queue_id?: string | null | undefined;
  site_id?: string | null | undefined;
  skill_id?: string | null | undefined;
  team_id?: string | null | undefined;
  metric_types?: Array<MetricUseType>;
  agent_ids_on_current_page?: Array<string>;
};

export type MetricsWithViewRecord = {
  view_record: MetricsViewRecord;
  metrics: Array<RecomputedMetric>;
  aggregate_metrics: Array<AggregateMetric>;
  totals: Array<MetricValue>;
};

export type MetricsViewRecord = {
  record_id: string;
  interval_start: string;
  interval_end: string;
  recomputed_at: string;
};

export type RecomputedMetricAttributes = {
  [key: string]: any;
};

export type MetricUnit = 'integer' | 'seconds' | 'minutes' | 'hours' | 'percentage' | 'float';
export const METRIC_UNIT_INTEGER: MetricUnit = 'integer';
export const METRIC_UNIT_SECONDS: MetricUnit = 'seconds';
export const METRIC_UNIT_MINUTES: MetricUnit = 'minutes';
export const METRIC_UNIT_HOURS: MetricUnit = 'hours';
export const METRIC_UNIT_PERCENTAGE: MetricUnit = 'percentage';
export const METRIC_UNIT_FLOAT: MetricUnit = 'float';

export type RecomputedMetric = {
  id: string;
  view_record_id: string;
  name: string;
  value: number;
  unit: MetricUnit;
  attributes: RecomputedMetricAttributes;
  error: string | null | undefined;
  created_at: string;
};

export type ActiveTicketVisibility = {
  imported_id: string;
  platform: string;
  external_created_at: string;
  created_at: string;
  updated_at: string;
};

export type ActiveTicketVisibilityV2 = {
  platform: string;
  platform_id: string;
  platform_ticket_id: string;
  platform_status: string;
  status: string;
  queue: string | null | undefined;
  channel: string | null | undefined;
  excluded: boolean;
  external_created_at: string;
  external_updated_at: string;
};

export type VisilibityType =
  | 'active_tickets'
  | 'queue_incoming_volume'
  | 'queue_longest_wait'
  | 'queue_average_wait'
  | 'queue_longest_wait_within_business_hours'
  | 'queue_longest_wait_outside_business_hours'
  | 'queue_average_wait_within_business_hours'
  | 'queue_average_wait_outside_business_hours';

export type Visibility =
  | {
      type: VisilibityType;
      active_ticket_visibility: Array<ActiveTicketVisibility>;
      computed_on_tickets_v2: boolean;
    }
  | {
      type: 'active_tickets_v2';
      active_ticket_visibility_v2: Array<ActiveTicketVisibilityV2>;
      computed_on_tickets_v2: boolean;
    }
  | {
      type: 'qa_stats';
      qa_ticket_visibility: Array<QATicketVisibility>;
    };

export type TicketStatVisibility = {
  ticket_id: string;
  platform: string;
  start_time: string;
  end_time: string | null | undefined;
  csat_score: string | null | undefined;
  first_responded_at: string | null | undefined;
  first_response_duration: string | null | undefined;
  external_sla_met: boolean | null | undefined;
  abandoned: boolean | null | undefined;
  created_at: string;
  updated_at: string;
};

export type AgentTicketStatVisibility = {
  ticket_id: string;
  platform: string;
  queue: string | null | undefined;
  current_status: string;
  start_time: string;
  end_time: string | null | undefined;
  abandoned: boolean | null | undefined;
  first_responded_at: string | null | undefined;
  handle_times: Array<HandleTimeInfo>;
  ticket_status_changes: Array<AgentTicketStatusChangeInfo>;
  agent_external_id_and_ticket_segments: Array<AgentExternalIDAndTicketSegments>;
};

export type AgentExternalIDAndTicketSegments = {
  agent_external_id: string;
  ticket_segment: TicketSegmentWithTasks;
};

export type MessagesSentVisibility = {
  ticket_id: string;
  message_id: string;
  platform: string;
  channel: string;
  queue: string | null | undefined;
  from_id: string;
  external_timestamp: string;
};

export type HandleTimeInfo = {
  agent_platform_id: string;
  agent_id: string;
  start_time: string;
  end_time: string;
  ticket_status: string;
};

export type TicketSegmentWithTasks = {
  company_id: string;
  tickets_v2_id: number;
  platform: string;
  platform_id: string;
  platform_ticket_id: string;
  platform_status: string;
  platform_assignee_id: string | null | undefined;
  status: string;
  queue: string | null | undefined;
  channel: string | null | undefined;
  excluded: boolean;
  external_created_at: string;
  external_updated_at: string;
  started_at: string;
  assigned_at: string;
  first_responded_at: string;
  hangup_at: string;
  ended_at: string;
  handle_time: number;
  first_response_duration: number;
  is_reopened: boolean;
  is_first_segment: boolean;
  is_last_segment: boolean | null | undefined;
  csat_score: boolean;
  tasks: Array<Task>;
};

export type Task = {
  company_id: string;
  ticket_segment_platform_id: string;
  platform: string;
  platform_id: string;
  platform_ticket_id: string;
  platform_assignee_id: string;
  assignee_type: string;
  queue: string | null | undefined;
  channel: string | null | undefined;
  excluded: boolean;
  started_at: string;
  ended_at: string;
  task_type: string;
  data: string | null | undefined;
  old_status: string | null | undefined;
  new_status: string | null | undefined;
};

export type AgentTicketStatusChangeInfo = {
  ticket_status_change_platform_id: string | null | undefined;
  agent_id: string;
  agent_platform_id: string;
  external_timestamp: string;
  new_status: string;
  old_status: string;
};

export type TicketStateActionVisibility = {
  ticket_id: string;
  platform: string;
  channel: string;
  current_status: string;
  ticket_status_changes: Array<TicketStatusChangeInfo>;
};

export type TicketStateActionVisibilityV2 = {
  tickets_v2_id: number;
  ticket_id: string;
  current_status: string;
  platform: string;
  ended_at: string | null | undefined;
  channel: string;
  ticket_segments_with_tasks: Array<TicketSegmentWithTasks>;
};

export type QATicketVisibility = {
  agent_email: string;
  agent_name: string;
  agent_platform_id: string;
  graded_at: string;
  grader: string;
  imported_id: string;
  max_score: number;
  platform: string;
  score: number;
  score_type: number;
  ticket_created_at: string;
};

export type TicketStatusChangeInfo = {
  old_status: string;
  new_status: string;
  external_timestamp: string;
};

export type MetricVisibility = {
  metric_name: string;
  visibility: Visibility;
  attributes: RecomputedMetricAttributes;
  error: string | null | undefined;
};

export type AggregateMetric = {
  name: string;
  value: number;
  unit: MetricUnit;
  attributes: RecomputedMetricAttributes;
  error: string | null | undefined;
};

export type MetricGroupType = {
  id: string;
  label: string;
  order: number;
  isLive: boolean;
};

export type AggregationMethod = 'average' | 'sum';

export type MetricType = {
  id: string;
  label: string;
  tooltip?: string;
  tooltip_hyperlink?: string;
  tooltip_equation?: string;
  disable_sort?: boolean;
  has_visibility?: boolean;
  useMetricUnits?: boolean;
  visibilityUnit?: string;
  positive_direction?: MetricDirection;
  uses_lookback?: boolean;
  group?: MetricGroupType;
  breakdown?: MetricType[];
  aggregateLabel?: string;
  aggregationMethod?: AggregationMethod;
  is_exportable?: boolean;
  unit?: MetricUnit;
  graphType?: 'bar' | 'line';
  graphColor?: string;
  skip_threshold?: boolean;
};

export type BusinessHour = {
  start_hour: number;
  start_minute: number;
  end_hour: number;
  end_minute: number;
};

export type BusinessHourRecord = {
  channel: Channel;
  queue?: string | null;
  timezone: string;
  days: {
    [dayOfWeek: string]: BusinessHour[];
  };
};

export type MetricValue = {
  metricId: string;
  value: number;
  unit: MetricUnit;
  attributes?: MetricAttributes;
};

export type TeamPerformanceAttributes = {
  agent_id?: string;
  channel?: Channel;
  queue_id?: string | null | undefined;
  start_time?: number;
  end_time?: number;
  type?: MetricUseType;
  platform?: Platform;
  agent_state?: string;
  weight?: number;
  clock_in_metric?: boolean;
  lookback_period_type?: string;
  resolution_path_id?: string;
};

export type MetricUseType = 'graph' | 'full_interval' | 'team_graph' | 'total';

export type MetricAttributes = TeamPerformanceAttributes;

export type MetricDirection = 'ascending' | 'descending';

export type MetricSubgroupSpecification = {
  // The attribute to pull subgroup information out of. This will check the MetricValue's
  // attributes hash and look for attributes[<attribute>];
  attribute: keyof TeamPerformanceAttributes;
  // Formats the subgroup value into a displayable value.
  valueFormatter: (arg1: string) => string;
  // Groups from the data which should be ignored when computing subgroups
  groupsToIgnore?: Array<string>;
  // Provides overrides so you can manually define subgroups for a particular metric
  subgroupOverrides?: {
    [key: string]: Array<MetricSubgroup>;
  };
  // Optionally specify all of the available subgroups if you know them in advance.
  allSubgroups?: Array<string>;
};

export type MetricSubgroup = {
  attribute: string;
  id: string;
  label: string;
};

export type APIKey = {
  id: string;
  name: string;
  description: string;
  options: Array<string>;
  key: string;
  source: string;
  active: boolean;
  api_version: string;
  updated_at: string;
  created_at: string;
};

export type APIWhitelist = {
  id: string;
  company_id: string;
  ip: string;
  is_range: boolean;
  description: string;
};

export type DemoState = {
  demo: boolean;
};

export type TimeRange = {
  start: Moment;
  end: Moment;
};

export type UnixTimeRange = {
  start: number;
  end: number;
};

export type InfiniteRange = {
  start: moment.Moment | null;
  end: moment.Moment | null;
};

export type OptimizationProperties = {
  id: string;
  title: string;
  interval_size: number;
  updated_by: SimpleUser | null | undefined;
  updated_at: string;
  created_by: SimpleUser | null | undefined;
  created_at: string;
};

export type InferredUserAttributeType = 'Zendesk Group' | 'Intercom Team' | 'Intercom Job Title';

export type InferredUserAttribute = {
  label: string;
  value: string;
  type: InferredUserAttributeType;
};

export type InferredUser = {
  first_name: string;
  last_name: string;
  email: string;
  role: UserRole;
  imported_id: string;
  attributes: InferredUserAttribute[];
};

export type InferredUserResponse = {
  users: InferredUser[];
  attribute_options: Partial<Record<InferredUserAttributeType, InferredUserAttribute[]>>;
};

export type OverflowAction = {
  title: React.ReactNode;
  onClick?: (arg1: React.MouseEvent) => any;
  link?: string;
  style?: any;
  type?: 'link' | 'external-link' | 'action' | 'dangerous-action';
};

export type OverflowMenuPlacement = 'bottom' | 'bottom-end' | 'bottom-left';

// Copied from the Bootstrap prop definition for the Overlay component
export type OverlayPlacement = 'top' | 'right' | 'bottom' | 'left' | undefined;

export type FilterState = {
  site?: string | null | undefined;
  skills?: Array<string> | null | undefined;
  teams?: Array<string> | null | undefined;
  queues?: Array<string> | null | undefined;
  channels?: Array<Channel> | null | undefined;
};

// Taylor (2021-03-08): If you add a new role, make sure to add
// it to UserManager/USER_ROLE_ORDER
export type UserRole = 'admin' | 'manager' | 'lead' | 'standard' | 'basic';
export type UserRoleFilter = UserRole | '' | null;

export type KeywordSuggestion = {
  keyword_type: string;
  label: string;
  occurrences: number;
  value: string;
};

export type KeywordOption = {
  value: string;
  label: string;
};

export type PlatformKeywordSuggestionsResponse = {
  keywords: KeywordSuggestion[];
};

export type JobProgress<T> = {
  id: string;
  progress: number;
  message_queue: string | null | undefined;
  result: T | null | undefined;
  error: string | null | undefined;
  created_at: string;
  updated_at: string;
  canceled: boolean;
};

export type AgentRowTitleContext = {
  editable: boolean;
  showPaste: () => boolean;
  agentEventsId: string;
  agent: Agent;
  eventGroup: EventGroup | null | undefined;
  ticketHours: number;
  onPasteRow: (agentEventsID: string, cellStart: Moment, rangeDetails: RangeDetails, forceType?: PasteType) => void;
  onClearClipboard: (arg1: string) => void;
  onDeleteFull: (arg1: string) => void;
  onCopyRow: (arg1: string) => void;
  start: Moment;
  end: Moment;
  rangeDetails: RangeDetails;
  agentSelected: boolean;
  index?: number;
};

// Sync Tasks
export const SYNC_DEFAULT: SyncTask = 'default';
export const SYNC_AGENTS: SyncTask = 'agents';
export const SYNC_AGENT_STATES: SyncTask = 'agent_states';

// Salesforce specific sync tasks
export const SYNC_CASE_HISTORIES: SyncTask = 'case_histories';
export const SYNC_LIVE_CHAT_TRANSCRIPTS: SyncTask = 'live_chat_transcripts';
export const SYNC_NVM_CALL_SUMMARIES: SyncTask = 'nvm_call_summaries';
export const SYNC_TALKDESK_ACTIVITIES: SyncTask = 'talkdesk_activities';
export const SYNC_TASKS: SyncTask = 'tasks';
export const SYNC_EMAIL_MESSAGES: SyncTask = 'email_messages';

// Zendesk specific sync tasks
export const SYNC_CALCULATED_METRICS: SyncTask = 'calculated_metrics';
export const SYNC_BACKFILL: SyncTask = 'backfill';

export type SyncTask =
  | 'default'
  | 'default_v2'
  | 'agents'
  | 'agent_states'
  | 'case_histories'
  | 'live_chat_transcripts'
  | 'nvm_call_summaries'
  | 'talkdesk_activities'
  | 'tasks'
  | 'email_messages'
  | 'calculated_metrics'
  | 'backfill'
  | 'helpcenter_encodings'
  | 'time_off_read'
  | 'voice_calls'
  | 'custom_work_items'
  | 'pending_service_routing'
  | 'timesheet_read'
  | 'leave_of_absence_read'
  | 'stripe_calculated_metrics'
  | 'ticket_events'
  | 'ticket_escalations'
  | 'canned_responses'
  | 'brands'
  | 'cal_tickets'
  | 'queue_metrics'
  | 'shifts_csv'
  | 'events_csv'
  | 'recurring_sheets_exports';

export type SyncJob = {
  service: string;
  sync_task: SyncTask;
  lock_expiration: Date | null | undefined;
  sync_time: Date;
  sync_error: string | null | undefined;
  updated_at: Date;
  created_at: Date;
  active: boolean;
};

export type BackfillJob = {
  platform: string;
  sync_task: SyncTask;
  lock_expiration: Date | null | undefined;
  last_run: Date | null | undefined;
  backfilled_until: Date;
  target_time: Date;
  error: string | null | undefined;
  updated_at: Date;
  created_at: Date;
  active: boolean;
};

export const CONFLICT_PREFER_EXISTING: ConflictType = 'prefer_existing';
export const CONFLICT_REPLACE: ConflictType = 'replace';
export const CONFLICT_ALLOW: ConflictType = 'allow';
export type ConflictType = 'prefer_existing' | 'replace' | 'allow';

export type Optimization = {
  id: string;
  title: string;
  interval_size: number;
  optimize_aggregates: boolean;
  fixed_event_type_ids: Array<string>;
  agent_requirements: Array<OptimizationAgentRequirement>;
  updated_by: SimpleUser | null | undefined;
  updated_at: string;
  created_by: SimpleUser | null | undefined;
  created_at: string;
  enforce_order: boolean;
  randomize_time_ranges: boolean;
};

export type OptimizationUpdateRequest = {
  id: string;
  title: string;
  interval_size: number;
  optimize_aggregates: boolean;
  fixed_event_type_ids: Array<string>;
  agent_requirements: Array<OptimizationAgentRequirement>;
  enforce_order: boolean;
  randomize_time_ranges: boolean;
};

export type OptimizationAgentRequirement = {
  id?: string;
  event_type_id: string;
  frequency: OptimizationAgentRequirementFrequency;
  required: number;
  length_seconds: number;
  priority: number;
  candidate_filters: OptimizationCandidateFilters;
  event_position: number;
};

export type OptimizationAgentRequirementFrequency = 'daily' | 'weekly';

export type OptimizationCandidateFilters = {
  from_start: OptimizationRangeFilter | null | undefined;
  from_end: OptimizationRangeFilter | null | undefined;
  spacing_filters: Array<OptimizationSpacingFilter> | null | undefined;
};

export type OptimizationRangeFilter = {
  min_seconds?: number | null | undefined;
  max_seconds?: number | null | undefined;
};

export type OptimizationSpacingFilter = {
  event_type_ids: Array<string>;
  spacing: OptimizationRangeFilter;
};

export type CannedOptimizationPreset = {
  id: string;
  body: string;
  eventTypes: Array<string>;
  frequency: OptimizationAgentRequirementFrequency;
  eventOrder: Array<OptimizationEventOrder>;
  title: string;
  optimizeAggregates: boolean;
  enforceOrder: boolean;
  randomizeTimeRanges: boolean;
};

export type CannedOptimizationBase = {
  id: string;
  body: string;
  eventTypes: {
    [key: string]: number;
  };
  eventOrder: Array<OptimizationEventOrder>;
  title: string;
};

export type OptimizationEventOrder = {
  name: string;
  length_seconds: number;
} & OptimizationCandidateFilters;

export type Notification = {
  id: string;
  company_id: string;
  channel: string;
  message: string;
  recipient_user_id: string;
  created_at: string;
};

export type BacklogBucket = {
  min_age: number;
  max_age: number;
  new_tickets: number;
  responded_tickets: number;
};

export type BacklogRecord = {
  start: string;
  end: string;
  channel: Channel;
  queue: string | null | undefined;
  buckets: Array<BacklogBucket>;
};

export type UndoEventChangesOpts = {
  agent_external_id: string | null | undefined;
};

export type AgentOption = {
  label: string;
  value: string;
};

export type TicketAndUnitsOfWorkSummary = {
  ticket_summary: TicketSummary;
  units_of_work_summary?: UnitsOfWorkSummary;
  truncated_threshold_seconds?: number;
};

export type TicketSummary = {
  ticket_count_by_queue: {
    [key: string]: number;
  };
  ticket_count_by_channel: {
    [key: string]: number;
  };
  excluded_ticket_count: number;
  total_queued_tickets: number;
  total_ticket_count: number;
  total_not_queued_tickets: number;
};

export type UnitsOfWorkSummary = {
  unit_count_by_queue: {
    [key: string]: number;
  };
  unit_count_by_channel: {
    [key: string]: number;
  };
  excluded_unit_count: number;
  total_queued_unit_count: number;
  total_unit_count: number;
  total_not_queued_units: number;
};

export type MatchKeywordAndValue = {
  Keyword: ObjectMapping;
  match: boolean;
  value: string | number;
};

export type GroupedMappingMatchKeywordAndValue = {
  keyword_type_to_joined_values: Record<string, string>;
  keyword_mapping_group: KeywordMappingGroup;
  match: boolean;
};

export type ConflictDetails = {
  exclusion_name: string;
  higher_priority_queue_name: string;
  matched_higher_priority_queue: boolean;
};

export type QueueDetails = {
  conflict_details: ConflictDetails | null;
  exclusion_final_match: MatchKeywordAndValue | null;
  queue_final_match: MatchKeywordAndValue | null;
  queue_grouped_mappings_final_match: GroupedMappingMatchKeywordAndValue | null;
  exclusion_grouped_mappings_final_match: GroupedMappingMatchKeywordAndValue | null;
};

export type TicketWithHistory = {
  id: string;
  // this can be a raw ticket of many integrations
  // TODO? we should maybe consider typing them all out
  raw: any;
  ticket: any;
  keywords: Record<string, string[]>;
  queue_details: QueueDetails;
  history: any;
};

export const UnitOfWorkStatusList = [
  'new',
  'open',
  'pending',
  'paused',
  'reassigned',
  'transferred',
  'solved',
  'closed',
  'abandoned',
  'missed',
  '',
] as const;

export type UnitOfWorkStatus = (typeof UnitOfWorkStatusList)[number];

export const isUnitOfWorkStatus = (status: string): status is UnitOfWorkStatus =>
  UnitOfWorkStatusList.some((s) => s === status);

export type UnitOfWork = {
  platform_ticket_id: string;
  platform_id: string;
  platform_assignee_id: string | null;
  platform: Platform;
  created_at: string;
  started_at: string | null;
  first_responded_at: string | null;
  ended_at: string | null;
  channel: Channel;
  queue: string | null | undefined;
  queue_name: string;
  excluded: boolean;
  status: UnitOfWorkStatus;
};

export type UnitOfWorkWithDetails = {
  unit_of_work: UnitOfWork;
  keywords: Record<string, string[]>;
  queue_details: QueueDetails;
  raw: any;
};

export type UnitOfWorkWithHistory = {
  unit_of_work: UnitOfWork;
  keywords: Record<string, string[]>;
  queue_details: QueueDetails;
  raw: any;
  history: UnitOfWorkWithDetails[];
};

export type mappingHistory = {
  mapping_history_v1: any;
  mapping_history_v2: UnitOfWorkWithDetails[] | null | undefined;
};

export const EVENT_GROUP_CONTAINER_TEMPLATE = 'template';

export type EventGroupContainerType = 'template' | 'schedule';

export type EventGroup = {
  id: string;
  agent_id: string | null | undefined;
  container_id: string;
  container_type: EventGroupContainerType;
};

export const EVENT_GROUP_CREATE: EventGroupChangeType = 'create';
export const EVENT_GROUP_DELETE: EventGroupChangeType = 'delete';
export const EVENT_GROUP_ASSIGN: EventGroupChangeType = 'assign';
export type EventGroupChangeType = 'create' | 'delete' | 'assign';

export type ChangeEventGroupRequest = {
  change_type: EventGroupChangeType;
  agent_id: string | null | undefined;
  event_group_id?: string;
};

export type OvertimeSlot = {
  id: string;
  capacity: number;
  num_approved_requests: number;
  num_pending_requests: number;
  num_denied_requests: number;
  channel: string;
  queue_external_id?: string;
  site_external_id?: string;
  team_external_id?: string;
  skill_external_id?: string;
};

export type EventChangeRequest = {
  id: string;
  description: string;
  requester: SimpleUser;
  approver: SimpleUser | null | undefined;
  requested_changes: EventChange[];
  agent: Agent;
  created_at: string;
  updated_at: string;
  start_time: string;
  end_time: string;
  status: EventChangeRequestStatus;
  update_comment: string | null | undefined;
  overtime_slots?: OvertimeSlot[];
};

export type GetEventChangeRequestsResponse = {
  requests: EventChangeRequest[];
  pagination: Pagination;
};

export type EventChangeData = {
  undoIndex: number;
  setUndoIndex: (arg1: number | ((arg1: number) => number)) => void;
  eventChanges: Array<ChangeGroup>;
  setEventChanges: (arg1: Array<ChangeGroup> | ((arg1: Array<ChangeGroup>) => Array<ChangeGroup>)) => void;
  onPublishEventChanges: () => Promise<void>;
};

export type MultipleEventsModalDetailsState = {
  agent: Agent | null | undefined;
  target: any;
  start: Moment | null | undefined;
  instances: TimelineEventInstance[];
  cellIndex: number | null | undefined;
  agentEventsId: string | null | undefined;
  eventGroup: EventGroup | null | undefined;
};

export type ContextMenuState = {
  target: any;
  start: Moment | null | undefined;
  cellIndex: number | null | undefined;
  agentEventsId: string | null | undefined;
};

export type EventChangeRequestsSettings = {
  featureEnabledForCompany: boolean;
  allAgentsRequesterEnabled: boolean;
  queuesEnabledForRequesters?: Array<FilterValue>;
  skillsEnabledForRequesters?: Array<FilterValue>;
  teamsEnabledForRequesters?: Array<FilterValue>;
};

export type EventChangeRequestsSettingsResponse = {
  feature_enabled_for_company: boolean;
  all_agents_requester_enabled: boolean;
  queues_enabled_for_requesters?: Array<FilterValue>;
  skills_enabled_for_requesters?: Array<FilterValue>;
  teams_enabled_for_requesters?: Array<FilterValue>;
};

export type MultiFilters = {
  channels: Array<Channel>;
  sites: Array<string>;
  skills: Array<string>;
  teams: Array<string>;
  queues: Array<string>;
};

// TODO: this will change once we add real roles
type CustomRole = UserRole | 'bpo_lead';

export type EventChangePermissionType = 'none' | 'request' | 'edit';

export type EventChangePermission = {
  id: string;
  custom_role: CustomRole;
  custom_event_type_external_id: string | null;
  permission_type: EventChangePermissionType;
  edit_window_start_offset_seconds: number | null;
  edit_window_end_offset_seconds: number | null;
  active: boolean;
  created_at: number;
  updated_at: number;
};

export type EventChangeAuthorizationResponse = {
  action_available: EventChangePermissionType;
  error: EventChangePermissionError | null;
};

type EventChangeDenyReason = 'uneditable_event' | 'request_for_multiple_agents' | 'outside_edit_window';

export type EventChangePermissionError = {
  reason_type: EventChangeDenyReason;
  uneditable_event: string | null;
  request_for_multiple_agents: RequestForMultipleAgentsError | null;
  outside_edit_window: OutsideEditWindowError | null;
};

export type RequestForMultipleAgentsError = {
  event_types: string;
  agent_external_ids: Array<string>;
};

export type OutsideEditWindowError = {
  event_type: string;
  start_time: string | null;
  end_time: string | null;
};

export type CachedMetricsUpdatedAt = {
  min_updated_at: number;
  max_updated_at: number;
};

export type CachedMetricsMetadata = {
  computing_cached_metrics: boolean;
  reading_cached_metrics: boolean;
  last_recomputed_at: number;
  value_types_last_recomputed_at: Record<CachedMetricsValueType, CachedMetricsUpdatedAt> | null;
};

export type CachedMetricsBackfillMetadata = {
  company_id: string;
  updater_type: CachedMetricsUpdaterType;
  value_types_filter: Array<CachedMetricsValueType>;
  queued: boolean;
  queued_at: string | null;
  running: boolean;
  running_at: string | null;
  last_heartbeat_at: string | null;
  last_computed_at: string | null;
  retries: number | null;
  queued_start: string | null;
  queued_end: string | null;
  running_start: string | null;
  running_end: string | null;
};

// keep in sync with StaleChangesResponse in backend
export type PotentialStaleChanges = {
  conflicting_event_ids: string[];
  partitioned_changes: { [agent_external_id: string]: { [day: string]: EventChange[] } };
};

export type EditedEventsMap = Record<string, Set<string>>;

export type AgentStateSummaryType = {
  external_id: string;
  state: AgentStateName;
  distinct_agents: number;
  count: number;
};

export type AgentStatesSummaryType = {
  platform: Platform;
  states: Array<AgentStateSummaryType>;
};

export type AgentStateMappingUpdate = {
  platform: Platform;
  agentStateName: AgentStateName;
  eventTypes: Array<string>;
  channels: Array<Channel>;
  categories: Array<AgentStateGroup>;
};

export type OccupancyUpdate = {
  agent_state_type_external_id: string;
  occupancy_type: OccupancyType;
};

export type StoreMetricRequest = {
  external_id: string;
  name: string;
  value: number;
  unit: MetricUnit;
  attributes: MetricAttributes;
  error: string | null;
  metricId?: string; // this field is only in frontend to transit to MetricValue
};

export const AGENT_STATE_OFFLINE = 'offline';

export enum CachedMetricsUpdaterType {
  CachedMetricUpdaterTypeContactStats = 'contact_stats',
  CachedMetricUpdaterTypeReopenedSolved = 'reopened_solved',
  CachedMetricUpdaterTypeMessages = 'messages',
  CachedMetricUpdaterTypeAgentTicketStats = 'agent_ticket_stats',
  CachedMetricUpdaterTypeAgentStateSolvedHandleTimes = 'agent_state_solved_handle_times',
  CachedMetricUpdaterTypeAdherence = 'adherence',
  CachedMetricUpdaterTypeAgentMessagesSent = 'agent_messages_sent',
}

export enum CachedMetricsValueType {
  CachedMetricValueContacts = 'contacts',
  CachedMetricsValueCaseVolume = 'case_volume',
  CachedMetricValueReopened = 'reopened',
  CachedMetricValueMessages = 'messages',
  CachedMetricValueProductivity = 'productivity',
  CachedMetricValueSolved = 'solved',
  CachedMetricValueAbandoned = 'abandoned',
  CachedMetricValueCasesAnswered = 'cases_answered',
  CachedMetricValueHandleTimeCount = 'handle_time_count',
  CachedMetricValueHandleTimeTotal = 'handle_time_total',
  CachedMetricValueHandleTimeAverage = 'handle_time_average',
  CachedMetricValueSegmentHandleTimeCount = 'segment_handle_time_count',
  CachedMetricValueSegmentHandleTimeTotal = 'segment_handle_time_total',
  CachedMetricValueSegmentHandleTimeAverage = 'segment_handle_time_average',
  CachedMetricValueSegmentSLAMetWithinBusinessHours = 'segment_sla_met_within_business_hours',
  CachedMetricValueSegmentSLAMissedWithinBusinessHours = 'segment_sla_missed_within_business_hours',
  CachedMetricValueSegmentSLAPercentageWithinBusinessHours = 'segment_sla_percentage_within_business_hours',
  CachedMetricValueCasesSolvedLastQueue = 'solved_last_queue',
  CachedMetricValueCasesReopenedLastQueue = 'reopened_last_queue',
  CachedMetricValueCSATCount = 'csat_count',
  CachedMetricValueCSATTotal = 'csat_total',
  CachedMetricValueCSATAverage = 'csat_average',
  CachedMetricValueSLACount = 'sla_count',
  CachedMetricValueSLAMet = 'sla_met',
  CachedMetricValueSLAMissed = 'sla_missed',
  CachedMetricValueSLAPercentage = 'sla_percentage',
  CachedMetricValueFirstResponseCount = 'first_response_count',
  CachedMetricValueFirstResponseTotal = 'first_response_total',
  CachedMetricValueFirstResponseAverage = 'first_response_average',
  CachedMetricValueReassignedAway = 'reassigned_away',
  CachedMetricValueTransferredAway = 'transferred_away',
  CachedMetricValueUnitsOfWorkHandled = 'units_of_work_handled',
  CachedMetricValueAdherenceActual = 'adherence_actuals',
}

export type ReleaseStatus = 'alpha' | 'beta' | 'new';

export interface Route {
  path: string;
  releaseStatus?: ReleaseStatus;
  /**
   * @deprecated Use {@link Route.isInaccessible} instead.
   */
  shouldShow?: boolean;
  isInaccessible?: boolean;
  key?: string;
  component?: React.ReactNode;
  exact?: boolean;
  pageTitle?: string;
  className?: string;
  navChildren?: Array<NavRoute>;
  navIconName?: string;
  icon?: Icon;
  navLabel?: string;
}

export interface NavRoute extends Route {
  navLabel: string;
}

export interface LegacyIconNavRoute extends NavRoute {
  icon?: never;
  navIconName: string;
}

export function isLegacyIconNavRoute(route: NavRoute): route is LegacyIconNavRoute {
  return typeof route.navIconName === 'string';
}

export interface IconNavRoute extends NavRoute {
  icon: Icon;
  navIconName?: never;
}

// We should require top nav items to have either an old-style icon name, or a new icon, but not both
export type TopNavRoute = LegacyIconNavRoute | IconNavRoute;

export type GoogleSheetsExportRequest = {
  folderID?: string | null;
  spreadsheetID?: string | null;
  sheetID?: number | null;
  linkString?: string;
  spreadsheetName?: string;
  sheetName?: string;
  exportFrequency?: SelectOption<string>;
  reportType?: string;
  appendData?: boolean;
  scheduledTime?: moment.Moment;
};

export enum SftpService {
  NiceIEX = 'nice_iex',
  Verint = 'verint',
  Injixo = 'injixo',
  Alvaria = 'alvaria',
  CustomPlatform = 'custom',
}

export type SftpServiceDetails = {
  name: string;
  selectLabel?: string;
  instructions?: React.ReactNode;
};

export type RoleInfo = {
  id: string;
  name: string;
  description?: string;
};

export type Role = RoleInfo & {
  legacyRole: UserRole;
  userCount: number;
  inactiveUserCount: number;
  createdAt: moment.Moment;
  createdBy?: SimpleUser;
  updatedAt: moment.Moment;
  updatedBy?: SimpleUser;
};

export type Interval = 'hourly' | 'daily' | 'weekly' | 'monthly' | 'quarterly';

export type Message = {
  id: string;
  third_party_id: string;
  third_party_source: string;
  third_party_ticket_id: string;
  channel: Channel;
  queue: string | null;
  body: string;
  from_id: string;
  from_role: string;
  external_timestamp: number;
  public: boolean;
};

export type prophetModelParams = {
  SmoothingParams: SmoothingParams;
  Holidays: null;
};

export type SmoothingParams = {
  external_id: string;
  model_external_id: string;
  optimize_parameters: boolean;
  changepoint_prior_scale: number;
  seasonality_prior_scale: number;
  holidays_prior_scale: number;
  seasonality_mode: string;
  changepoint_range: number;
};
