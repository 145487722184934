import { get } from 'lodash-es';
import React from 'react';

import { HEADER_ROW_ZINDEX } from '../../constants/StaffingTimelineConstants';
import type { CSSStyle, Theme } from '../../models';
import type { EventTypeOption } from './Events';
import { getColorMode } from './ColorMode';

export type SelectFunction = (base: Partial<CSSStyle>, state: any) => Partial<CSSStyle>;
export type SelectStyles = {
  [key: string]: SelectFunction;
};

const SELECT_MENU_ZINDEX = HEADER_ROW_ZINDEX + 1;
const indicatorPadding = '6px';

// addSelectStyles allows one to combine react-select styles into a single set
// of styles. The baseStyles will be overwritten by newStyles if necessary.
export function addSelectStyles(baseStyles: SelectStyles, newStyles: SelectStyles): SelectStyles {
  const finalStyles: SelectStyles = { ...baseStyles };
  Object.keys(newStyles).forEach((key) => {
    if (Object.prototype.hasOwnProperty.call(baseStyles, key)) {
      finalStyles[key] = (base: Partial<CSSStyle>, state: any): Partial<CSSStyle> => {
        const intermediateStyles = baseStyles[key](base, state);
        return {
          ...newStyles[key](intermediateStyles, state),
        };
      };
    } else {
      finalStyles[key] = newStyles[key];
    }
  });
  return finalStyles;
}

const menu: SelectFunction = (base: Partial<CSSStyle>): Partial<CSSStyle> => {
  return {
    ...base,
    boxShadow: `var(--popup-box-shadow-x-offset) var(--popup-box-shadow-y-offset) var(--popup-box-shadow-blur)
      var(--popup-box-shadow-spread) var(--popup-box-shadow-base)`,
    zIndex: SELECT_MENU_ZINDEX.toString(),
    backgroundColor: 'var(--form-field-surface-base)',
  };
};

const menuList: SelectFunction = (base) => {
  return {
    ...base,
    '::-webkit-scrollbar': {
      width: 'var(--scrollbar-width)',
    },
    '::-webkit-scrollbar-thumb': {
      background: 'var(--form-field-scrollbar-surface-base)',
      borderRadius: 'var(--scrollbar-border-radius)',
      border: 'var(--scrollbar-thumb-border-width) solid var(--form-field-surface-base)',
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: 'var(--form-field-scrollbar-surface-higher)',
    },
  };
};

const control: SelectFunction = (base, state) => {
  let styles: Partial<CSSStyle> = {
    ...base,
    ...state.selectProps.controlStyles,
    backgroundColor: 'var(--form-field-surface-base)',
    borderColor: 'var(--form-field-border-base)',
    color: 'var(--text-strongest)',
    boxShadow: 'var(--form-field-inset-box-shadow-base)',
    minHeight: 'var(--block-size-md)',
    ':hover': {
      ...base[':hover'],
      borderColor: state.isFocused ? 'var(--link-text-base)' : 'var(--form-field-border-stronger)',
    },
  };
  if (state.selectProps.menuIsOpen || state.isFocused) {
    styles = {
      ...styles,
      borderColor: 'var(--link-text-base)',
      outline: '1px solid var(--link-text-base)',
    };
  }
  if (state.isDisabled) {
    styles = {
      ...styles,
      backgroundColor: 'var(--form-field-surface-lower)',
      borderColor: 'var(--form-field-border-disabled)',
      boxShadow: 'var(--form-field-inset-box-shadow-disabled)',
      fontStyle: 'italic',
      color: 'var(--form-field-text-disabled)',
      cursor: 'not-allowed',
      pointerEvents: 'auto',
      ':hover': {
        ...styles[':hover'],
        borderColor: 'var(--form-field-border-disabled)',
      },
    };
  }
  return styles;
};

const indicatorSeparator: SelectFunction = () => {
  return { display: 'none' };
};

const clearIndicator: SelectFunction = (base) => {
  return {
    ...base,
    padding: indicatorPadding,
    color: 'var(--form-field-icon-base)',
    cursor: 'pointer',
    ':hover': {
      ...base[':hover'],
      color: 'var(--form-field-icon-strongest)',
    },
  };
};

const dropdownIndicator: SelectFunction = (base, state) => {
  let styles: Partial<CSSStyle> = {
    ...base,
    color: 'var(--form-field-icon-base)',
    padding: indicatorPadding,
    ':hover': {
      ...base[':hover'],
      color: 'var(--form-field-icon-strongest)',
    },
  };
  if (state.selectProps.menuIsOpen) {
    styles.color = 'var(--form-field-icon-weaker)';
  }
  if (state.isDisabled) {
    styles = {
      ...styles,
      color: 'var(--form-field-icon-weaker)',
      ':hover': {
        ...styles[':hover'],
        color: 'var(--form-field-icon-weaker)',
      },
    };
  }
  return styles;
};

const singleValue: SelectFunction = (base, state) => {
  const styles: Partial<CSSStyle> = {
    ...base,
    color: 'var(--text-strongest)',
    padding: '0 4px',
  };
  if (state.selectProps.isDisabled || state.isDisabled) {
    styles.color = 'var(--form-field-text-disabled)';
  }
  return styles;
};

const option: SelectFunction = (base, state) => {
  let color = 'var(--text-base)';
  let fontStyle = 'inherit';
  if (state.isDisabled) {
    color = 'var(--form-field-text-disabled)';
    fontStyle = 'italic';
  }
  if (state.isSelected) {
    color = 'var(--white)';
  }
  let backgroundColor: string;
  if (state.isSelected) {
    backgroundColor = 'var(--form-field-option-surface-selected)';
  } else if (state.isFocused) {
    backgroundColor = 'var(--form-field-option-surface-hover)';
  } else {
    backgroundColor = 'transparent';
  }
  return {
    ...base,
    color,
    backgroundColor,
    fontStyle,
    outline: state.isSelected && state.isFocused ? '1px solid var(--form-field-option-outline)' : undefined,
    ':hover': {
      ...base[':hover'],
      backgroundColor: state.isSelected || state.isDisabled ? '' : 'var(--form-field-option-surface-hover)',
    },
    ':active': {
      ...base[':active'],
      // Without !important, the hover background color takes precedence for unknown reasons
      backgroundColor: state.isDisabled ? '' : 'var(--form-field-option-surface-active) !important',
    },
  };
};

const input: SelectFunction = (base) => {
  return {
    ...base,
    color: 'var(--text-strongest)',
    margin: '0',
    padding: '0 4px',
  };
};

const multiValue: SelectFunction = (base) => {
  return {
    ...base,
    backgroundColor: 'var(--form-field-surface-higher)',
    color: 'var(--text-strongest)',
  };
};

const multiValueLabel: SelectFunction = (base) => {
  return {
    ...base,
    color: 'var(--text-strongest)',
  };
};

const multiValueRemove: SelectFunction = (base) => {
  return {
    ...base,
    color: 'var(--form-field-icon-stronger)',
    cursor: 'pointer',
    ':hover': {
      ...base[':hover'],
      backgroundColor: 'var(--form-field-surface-higher)',
      color: 'var(--form-field-icon-strongest)',
    },
  };
};

const valueContainer: SelectFunction = (base) => {
  return {
    ...base,
    padding: '2px',
  };
};

const placeholder: SelectFunction = (base, state) => {
  return {
    ...base,
    color: state.isDisabled ? 'var(--form-field-text-disabled)' : 'var(--text-weakest)',
    left: '6px',
  };
};

const reactSelectStyles: SelectStyles = {
  menu,
  menuList,
  control,
  indicatorSeparator,
  clearIndicator,
  dropdownIndicator,
  singleValue,
  option,
  input,
  multiValue,
  multiValueLabel,
  multiValueRemove,
  valueContainer,
  placeholder,
};

const reactSelectStylesLightMode: SelectStyles = addSelectStyles(reactSelectStyles, {
  control: (base: Partial<CSSStyle>, state: any): Partial<CSSStyle> => {
    const styles = { ...base, backgroundColor: 'white', color: '#fff' };
    if (state.selectProps.menuIsOpen) {
      // TODO: define styles
    }
    return styles;
  },
  singleValue: (base: Partial<CSSStyle>, state: any): Partial<CSSStyle> => {
    if (state.selectProps.menuIsOpen) {
      return base;
    }
    return { ...base, color: state.selectProps.isDisabled ? 'var(--text-weakest)' : 'var(--text-strongest)' };
  },
});

function getReactSelectStyles(theme?: Theme): SelectStyles {
  if (theme && theme === 'light') {
    return reactSelectStylesLightMode;
  }
  return reactSelectStyles;
}

const errorSelectStyles: SelectStyles = {
  container: (base: Partial<CSSStyle>): Partial<CSSStyle> => {
    return {
      ...base,
      border: '1px solid #843534;',
      borderRadius: '5px',
      boxShadow: 'inset 0 1px 1px rgba(0,0,0,.075), 0 0 6px #ce8483;',
    };
  },
};

function selectionOptionsStyle(): React.CSSProperties {
  const theme = getColorMode();
  const backgroundColor = theme === 'light' ? 'var(--warm-gray-10)' : 'var(--cool-gray-70)';
  return {
    display: 'flex',
    borderRadius: '2px',
    color: 'var(--text-strongest)',
    padding: '4px 6px',
    backgroundColor,
    fontSize: '12px',
  };
}

function previewSwatchBase(backgroundColor?: string | null, radius: string = '4px'): React.CSSProperties {
  const res: React.CSSProperties = {
    borderRadius: radius,
    boxSizing: 'border-box',
    border: '1px solid rgba(0,0,0,0.2)',
    content: '" "',
    display: 'inline-block',
    marginInlineEnd: '0.33em',
    marginBlockStart: '0.2em',
    blockSize: '1em',
    inlineSize: '1em',
    verticalAlign: 'middle',
  };
  if (backgroundColor) {
    res.backgroundColor = backgroundColor;
  }
  return res;
}

function previewSwatch(eventTypeOpt?: EventTypeOption | null, radius: string = '4px'): React.CSSProperties {
  const backgroundColor = get(eventTypeOpt, ['style', 'backgroundColor'], undefined);
  return previewSwatchBase(backgroundColor, radius);
}

export {
  errorSelectStyles,
  getReactSelectStyles,
  previewSwatch,
  previewSwatchBase,
  reactSelectStyles,
  reactSelectStylesLightMode,
  SELECT_MENU_ZINDEX,
  selectionOptionsStyle,
};
