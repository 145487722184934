import { FeatureFlags } from '../../models';
import type { ActionType } from '../actions';

type FeatureFlagsState = {
  flags: FeatureFlags;
  flagsLoaded: boolean;
};

type UpdateFeatureFlagsAction = {
  type: ActionType;
  flags: FeatureFlags;
};

const initialState = {
  flags: {},
  flagsLoaded: false,
} as const;

const FeatureFlagsReducer = (state: FeatureFlagsState = initialState, action: UpdateFeatureFlagsAction) => {
  switch (action.type) {
    case 'UPDATE_FEATURE_FLAGS': {
      const update = Object.keys(action.flags).reduce<Record<string, any>>((n, flagName) => {
        n[flagName] = action.flags[flagName];
        return n;
      }, {});
      return { ...state, ...update, flagsLoaded: true };
    }
    default:
      return state;
  }
};

export type { FeatureFlagsState };
export { FeatureFlagsReducer };
