import { Person } from '../../models';
import type { ActionType } from '../actions';

type UpdateActiveAgentsAction = {
  type: ActionType;
  agents: Person[];
};

export type ActiveAgentsState = {
  activeAgents: Person[];
};

const ActiveAgentsReducer = (state: ActiveAgentsState = { activeAgents: [] }, action: UpdateActiveAgentsAction) => {
  switch (action.type) {
    case 'UPDATE_ALL_ACTIVE_AGENTS':
      return {
        ...state,
        activeAgents: action.agents,
      };
    default:
      return state;
  }
};

export { ActiveAgentsReducer };
