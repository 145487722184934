import * as React from 'react';
import { PrimaryHeading, Text } from 'assemblage';

import styles from './ErrorPageContainer.module.css';

const defaultErrorPageImgSrc =
  'https://assets-global.website-files.com/610225ff318d10884843dede/621a89cea245b9025bb02151_sad2.png';
const defaultErrorPageImgWidth = 50;

type ErrorPageContainerProps = {
  title: React.ReactNode;
  message?: React.ReactNode;
  imgSrc?: string;
  imgWidth?: number;
};

function ErrorPageContainer(props: ErrorPageContainerProps) {
  const { title, message, imgSrc, imgWidth } = props;

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <img
          src={imgSrc || defaultErrorPageImgSrc}
          width={imgWidth || defaultErrorPageImgWidth}
          alt="Nervous Cal illustration"
        />
        <PrimaryHeading className={styles.title}>{title}</PrimaryHeading>
        {message ? <Text>{message}</Text> : null}
      </div>
    </div>
  );
}

export { ErrorPageContainer };
