import {
  Alert as BootstrapAlert,
  Badge as BootstrapBadge,
  Button as BootstrapButton,
  ButtonGroup as BootstrapButtonGroup,
  ButtonToolbar as BootstrapButtonToolbar,
  Checkbox as BootstrapCheckbox,
  Col as BootstrapCol,
  ControlLabel as BootstrapControlLabel,
  Label as BootstrapLabel,
  ListGroup as BootstrapListGroup,
  ListGroupItem as BootstrapListGroupItem,
  Dropdown as BootstrapDropdown,
  DropdownButton as BootstrapDropdownButton,
  Form as BootstrapForm,
  FormControl as BootstrapFormControl,
  FormGroup as BootstrapFormGroup,
  Grid as BootstrapGrid,
  HelpBlock as BootstrapHelpBlock,
  InputGroup as BootstrapInputGroup,
  MenuItem as BootstrapMenuItem,
  Modal as BootstrapModal,
  Nav as BootstrapNav,
  NavItem as BootstrapNavItem,
  Overlay as BootstrapOverlay,
  OverlayTrigger as BootstrapOverlayTrigger,
  Pagination as BootstrapPagination,
  Panel as BootstrapPanel,
  Popover as BootstrapPopover,
  ProgressBar as BootstrapProgressBar,
  Radio as BootstrapRadio,
  Row as BootstrapRow,
  Table as BootstrapTable,
  ToggleButton as BootstrapToggleButton,
  ToggleButtonGroup as BootstrapToggleButtonGroup,
  Tooltip as BootstrapTooltip,
} from 'react-bootstrap';

// For some reason there is a type issue with InputGroupAddon where it needs to be explicity imported
// from the lib file
import BootstrapInputGroupAddon from 'react-bootstrap/lib/InputGroupAddon';

/** @deprecated Use Assemblage [AlertBanner](https://assemblage.gokome.com/?path=/docs/stable-notifications-alertbanner--docs) */
export const Alert = BootstrapAlert;

/** @deprecated Use Assemblage [Badge](https://assemblage.gokome.com/?path=/docs/stable-icons-images-and-badges-promobadge--docs) instead. */
export const Badge = BootstrapBadge;

/** @deprecated Use Assemblage [Button](https://assemblage.gokome.com/?path=/docs/stable-buttons-button--docs) instead. */
export const Button = BootstrapButton;

/** @deprecated Use Assemblage [SegmentedControl](https://assemblage.gokome.com/?path=/docs/stable-segmented-control-tabs-segmentedcontrol--docs) */
export const ButtonGroup = BootstrapButtonGroup;

export const ButtonToolbar = BootstrapButtonToolbar;

/** @deprecated Use Assemblage [Checkbox](https://assemblage.gokome.com/?path=/docs/stable-inputs-checkbox--docs) */
export const Checkbox = BootstrapCheckbox;

export const Col = BootstrapCol;

/** @deprecated Use an Assemblage form component which includes a label e.g.
 *  [TextInput](https://assemblage.gokome.com/?path=/docs/development-form-textinput--docs),
 *  or if necessary the underlying InputLabel component. */
export const ControlLabel = BootstrapControlLabel;

/** @deprecated Use Assemblage [Dropdown](https://assemblage.gokome.com/?path=/docs/stable-menus-dropdownmenu--docs) */
export const Dropdown = BootstrapDropdown;

/** @deprecated Use Assemblage [Dropdown](https://assemblage.gokome.com/?path=/docs/stable-menus-dropdownmenu--docs) */
export const DropdownButton = BootstrapDropdownButton;

/** @deprecated Use Assemblage [Form](https://assemblage.gokome.com/?path=/docs/stable-form-form--docs) */
export const Form = BootstrapForm;

/** @deprecated Use Assemblage [TextInput](https://assemblage.gokome.com/?path=/docs/development-form-textinput--docs) */
export const FormControl = BootstrapFormControl;

/** @deprecated FormGroup is no longer necessary — Assemblage [Form components](https://assemblage.gokome.com/?path=/docs/stable-form-form--docs) already have the appropriate spacing. */
export const FormGroup = BootstrapFormGroup;

export const Grid = BootstrapGrid;

/** @deprecated Use an Assemblage component instead:
 * - [BlockMessage](https://assemblage.gokome.com/?path=/docs/stable-notifications-blockmessage--docs)
 *   or [InlineMessage](https://assemblage.gokome.com/?path=/docs/stable-notifications-inlinemessage--docs) for helpful tips
 * - [Text](https://assemblage.gokome.com/?path=/docs/stable-typography-text--docs) or
 *   [SmallText](https://assemblage.gokome.com/?path=/docs/stable-typography-smalltext--docs)
 *   for all other text */
export const HelpBlock = BootstrapHelpBlock;

export const InputGroup = BootstrapInputGroup;

export const InputGroupAddon = BootstrapInputGroupAddon;

/** @deprecated Use an Assemblage component:
 * - [Badge](https://assemblage.gokome.com/?path=/docs/stable-icons-images-tags-and-badges-badge--docs) for badges
 * - [InlineMessage] to show a status or notification */
export const Label = BootstrapLabel;

export const ListGroup = BootstrapListGroup;

export const ListGroupItem = BootstrapListGroupItem;

export const MenuItem = BootstrapMenuItem;

/** @deprecated Use Assemblage [Dialog](https://assemblage.gokome.com/?path=/docs/stable-dialogs-tooltips-and-popovers-dialog--docs) instead. */
export const Modal = BootstrapModal;

export const Nav = BootstrapNav;

export const NavItem = BootstrapNavItem;

export const Overlay = BootstrapOverlay;

export const OverlayTrigger = BootstrapOverlayTrigger;

export const Panel = BootstrapPanel;

export const Pagination = BootstrapPagination;

export const ProgressBar = BootstrapProgressBar;

/** @deprecated Use Assemblage [Popover](https://assemblage.gokome.com/?path=/docs/stable-dialogs-tooltips-and-popovers-popover--docs) instead. */
export const Popover = BootstrapPopover;

/** @deprecated Use Assemblage [RadioGroup](https://assemblage.gokome.com/?path=/docs/stable-inputs-radiogroup--docs) */
export const Radio = BootstrapRadio;

export const Row = BootstrapRow;

/** @deprecated Use Assemblage [Table](https://assemblage.gokome.com/?path=/docs/stable-table-table--docs) instead. */
export const Table = BootstrapTable;

/** @deprecated Use Assemblage [SegmentedControl](https://assemblage.gokome.com/?path=/docs/stable-segmented-control-tabs-segmentedcontrol--docs) instead. */
export const ToggleButton = BootstrapToggleButton;

/** @deprecated Use Assemblage [SegmentedControl](https://assemblage.gokome.com/?path=/docs/stable-segmented-control-tabs-segmentedcontrol--docs) instead. */
export const ToggleButtonGroup = BootstrapToggleButtonGroup;

/** @deprecated Use Assemblage [Tooltip](https://assemblage.gokome.com/?path=/docs/stable-dialogs-tooltips-and-popovers-tooltip--docs) instead. */
export const Tooltip = BootstrapTooltip;
