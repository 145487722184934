export function isEmpty(obj: any): boolean {
  return JSON.stringify(obj) === '{}';
}

export function isUUID(uuid: string): boolean {
  let s = `${uuid}`;

  // @ts-expect-error - TS2322 - Type 'RegExpMatchArray | null' is not assignable to type 'string'.
  s = s.match('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$');
  if (s === null) {
    return false;
  }
  return true;
}
