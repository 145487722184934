import moment from 'moment';

import { Schedule } from '../../models';
import { formatMomentAsDateTime } from './DateTimes';
import { formatChannel, formatUser, formatMomentRelative } from './FormatTyped';

// roundTo will round a number to the decimal places specified. It's a weird function
// but gets around the vagaries of floats and weird javascript edge cases. We took this
// directly from https://stackoverflow.com/a/18358056
function roundTo(val: number, decimalPlaces: number = 0): number {
  return +`${Math.round(Number(`${val}e+${decimalPlaces}`))}e-${decimalPlaces}`;
}

function padString(str: string, width: number, pad: string) {
  pad = pad || '0';
  str = `${str}`;
  return str.length >= width ? str : new Array(width - str.length + 1).join(pad) + str;
}

function formatScheduleTitle(schedule: Schedule) {
  if (schedule.live) {
    return `${schedule.title} (Primary)`;
  }
  return schedule.title;
}

// Converts objects that can be represented as date-times into a common string
// @ts-expect-error - TS2366 - Function lacks ending return statement and return type does not include 'undefined'.
function formatDateTime(dt: string | number): string {
  const m = getMomentFromStringOrNumber(dt);
  if (m) return formatMomentAsDateTime(m, false);
}

function formatDateTimeRelative(dt: string | number): string {
  const eventTime = getMomentFromStringOrNumber(dt);
  return formatMomentRelative(eventTime);
}

function getMomentFromStringOrNumber(dt: string | number): moment.Moment {
  if (typeof dt === 'number') {
    // moment doesn't seem to handle negative epochs by default
    const d = new Date(0);
    d.setUTCSeconds(dt);
    return moment(d);
  }
  return moment(dt);
}

// Converts objects that can be represented as date-times into a common string
// if the date is >= numberOfRelativeRecentDays ago, use the actual date, otherwise, use the time relative to 'now'
// @ts-expect-error - TS2366 - Function lacks ending return statement and return type does not include 'undefined'.
function formatDateTimeWithRelativeRecentDays(dt: string | number, numberOfRelativeRecentDays: number): string {
  const currentTime = moment();
  const eventTime = getMomentFromStringOrNumber(dt);
  if (eventTime) {
    if (currentTime.diff(eventTime, 'days') >= numberOfRelativeRecentDays) {
      return formatMomentAsDateTime(eventTime, false);
    }
    return eventTime.fromNow();
  }
}

function formatDuration(duration: null | number, toFixed = 0) {
  if (duration == null) {
    return '-';
  }
  const hours = duration / (1e9 * 60 * 60);
  return formatFloat(hours, toFixed);
}

// Given a float in [0, 1], format it as a percentage.
function formatPercentage(value: number, digits = 0) {
  return formatFloat(value * 100, digits, '%');
}

function formatAgent(agent: any, nullDisplay = '') {
  if (!agent) {
    return nullDisplay;
  }

  let label = agent.name;
  if (agent.teams && agent.teams.length > 0) {
    // TODO: Assume that each agent only has a single team (true as of 9/14/18)
    label += ` (${agent.teams[0].name})`;
  } else if (agent.site) {
    label += ` (${agent.site.name})`;
  }
  return label;
}

function formatFloat(
  val: null | number | string,
  toFixed: number | null = null,
  suffix: string | null = null,
  commaSeparator: boolean = false
): string {
  if (val == null) {
    return '-';
  }
  if (typeof val === 'number') {
    if (toFixed != null) {
      val = val.toFixed(toFixed).replace(/[.,]0+$/, '');
      if (commaSeparator) {
        val = Number(val).toLocaleString();
      }
    } else {
      val = val.toString();
    }
    if (val === '-0') {
      val = '0';
    }
  }

  if (suffix) {
    return `${val}${suffix}`;
  }
  return val;
}

const formatDurationSinceNow = (time: number | undefined | null): string | null => {
  if (time && time > 0) {
    return moment.unix(time).fromNow(true);
  }
  return null;
};

export {
  formatAgent,
  formatChannel,
  formatDateTime,
  formatDateTimeRelative,
  formatDuration,
  formatFloat,
  formatScheduleTitle,
  formatUser,
  formatPercentage,
  formatDurationSinceNow,
  padString,
  formatDateTimeWithRelativeRecentDays,
  getMomentFromStringOrNumber,
  roundTo,
};
