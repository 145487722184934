import type { SelectedMap } from '../components/timeline/Utils';

export type StaffingTimelineState = {
  pasteMode: boolean;
  copiedEvents: SelectedMap | null | undefined;
};

const initialState: StaffingTimelineState = {
  pasteMode: false,
  copiedEvents: null,
};

type EnterPasteModeAction = {
  type: 'SET_PASTE_MODE';
  data: boolean;
};

type SetCopiedEventsAction = {
  type: 'SET_COPIED_EVENTS';
  data: SelectedMap | null | undefined;
};

type StaffingTimelineStateAction = EnterPasteModeAction | SetCopiedEventsAction;

const StaffingTimelineReducer = (
  state: StaffingTimelineState = initialState,
  action: StaffingTimelineStateAction
): StaffingTimelineState => {
  switch (action.type) {
    case 'SET_PASTE_MODE':
      return { ...state, pasteMode: action.data };
    case 'SET_COPIED_EVENTS':
      return { ...state, copiedEvents: action.data };
    default:
      return state;
  }
};

export { StaffingTimelineReducer };

export function setPasteMode(pasteMode: boolean): EnterPasteModeAction {
  return {
    type: 'SET_PASTE_MODE',
    data: pasteMode,
  };
}

export function setCopiedEvents(events?: SelectedMap | null): SetCopiedEventsAction {
  return {
    type: 'SET_COPIED_EVENTS',
    data: events,
  };
}
