import { isNil } from 'lodash-es';

import type { StaffingSettings, StaffingSettingsUpdate } from '../../models';

type StaffingSettingsAction = {
  type: 'UPDATE_STAFFING_SETTINGS';
  update: Partial<StaffingSettingsUpdate>;
};

const initialState: StaffingSettings = {
  hideOfflineAgents: false,
  hideGCalProvenance: false,
  showGridLine: false,
  showTimeInBlock: false,
  sortBy: [],
  eventFilters: {},
  extraColumns: [],
  hideExtraColumns: false,
  timeWindow: null,
  secondaryTimezones: [],
  staffingTimelineV2: true,

  isLoaded: false,
};

const StaffingSettingsReducer = (state: StaffingSettings = initialState, action: StaffingSettingsAction) => {
  switch (action.type) {
    case 'UPDATE_STAFFING_SETTINGS':
      return {
        ...state,
        isLoaded: true,
        ...(isNil(action.update.hideOfflineAgents) ? {} : { hideOfflineAgents: action.update.hideOfflineAgents }),
        ...(isNil(action.update.hideGCalProvenance)
          ? {}
          : {
              hideGCalProvenance: action.update.hideGCalProvenance,
            }),
        ...(isNil(action.update.showGridLine) ? {} : { showGridLine: action.update.showGridLine }),
        ...(isNil(action.update.showTimeInBlock) ? {} : { showTimeInBlock: action.update.showTimeInBlock }),
        ...(isNil(action.update.timeWindow) ? {} : { timeWindow: action.update.timeWindow }),
        ...(isNil(action.update.extraColumns) ? {} : { extraColumns: action.update.extraColumns }),
        ...(isNil(action.update.eventFilters) ? {} : { eventFilters: action.update.eventFilters }),
        ...(isNil(action.update.hideExtraColumns) ? {} : { hideExtraColumns: action.update.hideExtraColumns }),
        ...(isNil(action.update.sortBy) ? {} : { sortBy: action.update.sortBy }),
        ...(isNil(action.update.secondaryTimezones) ? {} : { secondaryTimezones: action.update.secondaryTimezones }),
        ...(isNil(action.update.staffingTimelineV2) ? {} : { staffingTimelineV2: action.update.staffingTimelineV2 }),
      };
    default:
      return state;
  }
};

export { StaffingSettingsReducer };
