import { IntegrationMappingPlatformList } from '../../models';
import type { IntegrationMappingPlatform, IntegrationMappings, ObjectMapping } from '../../models';

export type AddIntegrationMapping = {
  type: 'ADD_INTEGRATION_MAPPING';
  platform: IntegrationMappingPlatform;
  payload: ObjectMapping;
};

export type UpdateIntegrationMapping = {
  type: 'UPDATE_INTEGRATION_MAPPING';
  platform: IntegrationMappingPlatform;
  payload: ObjectMapping;
};

export type DeleteIntegrationMapping = {
  type: 'DELETE_INTEGRATION_MAPPING';
  platform: IntegrationMappingPlatform;
  id: string;
};

export type LoadIntegrationMappings = {
  type: 'LOAD_INTEGRATION_MAPPINGS';
  payload: Array<ObjectMapping>;
};

export type IntegrationMappingsAction =
  | AddIntegrationMapping
  | UpdateIntegrationMapping
  | DeleteIntegrationMapping
  | LoadIntegrationMappings;

const initialState: IntegrationMappings = {
  googlecalendar_read: [],
  intercom: [],
  kustomer: [],
  zendesk: [],
  zendesk_chat: [],
  salesforce: [],
  api: [],
};

const createMapping = (state: IntegrationMappings, action: AddIntegrationMapping): IntegrationMappings => {
  if (!state.hasOwnProperty(action.platform)) {
    state[action.platform] = [];
  }
  const newList = state[action.platform]?.slice() || [];
  newList.push(action.payload);
  const res: Record<string, ObjectMapping[]> = {};
  res[action.platform] = newList;
  return { ...state, ...res };
};

const deleteMapping = (state: IntegrationMappings, action: DeleteIntegrationMapping): IntegrationMappings => {
  if (!state.hasOwnProperty(action.platform)) {
    return state;
  }

  const newList = state[action.platform]?.slice().filter((om) => om.id !== action.id);

  const res: Record<string, any> = {};
  res[action.platform] = newList;
  return { ...state, ...res };
};

const updateMapping = (state: IntegrationMappings, action: UpdateIntegrationMapping): IntegrationMappings => {
  if (!state.hasOwnProperty(action.platform)) {
    return state;
  }
  const newList =
    state[action.platform]?.map((objectMapping) => {
      if (objectMapping.id === action.payload.id) {
        return action.payload;
      }
      return objectMapping;
    }) || [];

  const res: Record<string, ObjectMapping[]> = {};
  res[action.platform] = newList;
  return { ...state, ...res };
};

const loadMappings = (state: IntegrationMappings, action: LoadIntegrationMappings): IntegrationMappings => {
  const newState = action.payload.reduce<Record<string, any>>(
    (acc: Partial<Record<IntegrationMappingPlatform, Array<ObjectMapping>>>, v: ObjectMapping) => {
      let platform = v.integration;
      // zendesk talk is combined with zendesk
      if (platform === 'zendesk_talk') {
        platform = 'zendesk';
      }
      if (!IntegrationMappingPlatformList.includes(platform)) {
        console.error(`IntegrationMappingPlatformList does not include integration "${platform}"`);
        return acc;
      }
      if (!(platform in acc)) {
        acc[platform] = [];
      }
      acc[platform]?.push(v);
      return acc;
    },
    {}
  );

  // initialState is to make sure the empty lists exist, original state is so we don't delete data we didn't get,
  // and newState is, well, the new state we want.
  return { ...initialState, ...state, ...newState };
};

const IntegrationMappingsReducer = (state: IntegrationMappings = initialState, action: IntegrationMappingsAction) => {
  switch (action.type) {
    case 'ADD_INTEGRATION_MAPPING':
      return createMapping(state, action);
    case 'UPDATE_INTEGRATION_MAPPING':
      return updateMapping(state, action);
    case 'DELETE_INTEGRATION_MAPPING':
      return deleteMapping(state, action);
    case 'LOAD_INTEGRATION_MAPPINGS':
      return loadMappings(state, action);
    default:
      return state;
  }
};

export { IntegrationMappingsReducer };
