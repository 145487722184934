import { applyMiddleware, combineReducers, createStore } from 'redux';
import { batchActions, enableBatching } from 'redux-batched-actions';
// NOTE: If you want to log changes to the redux store, you may uncomment this, along with the usage at the bottom of this file.
// import { createLogger } from "redux-logger";
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { History } from 'history';

import { handleFetchErrors } from '../components/utils/Errors';
import {
  initFilters,
  updateAgentStateTypes,
  updateCompanyConfig,
  updateCompanyPlatforms,
  updateCSRF,
  updateCustomEventTypes,
  updateFeatureFlags,
  updateFilterParam,
  updateMultipleVariables,
  updateResolvedAgentStateTypes,
} from './actions';
import { EventTypesReducer } from './reducers/EventTypes';
import { StaffingSettingsReducer } from './reducers/StaffingSettings';
import { GenerationSettingsReducer } from './reducers/GenerationSettings';
import { VariablesReducer } from './reducers/Variables';
import { FilterParamsReducer } from './reducers/FilterParams';
import { FilterOptionsReducer } from './reducers/FilterOptions';
import { FeatureFlagsReducer } from './reducers/FeatureFlags';
import { FetchDetailsReducer } from './reducers/FetchDetails';
import { getCSRFToken } from '../components/utils/SendFetch';
import { TemplatesReducer } from './reducers/Templates';
import { UndoEventHistoryReducer } from './reducers/UndoEventHistory';
import { IntegrationMappingsReducer } from './reducers/IntegrationMappings';
import { DemoReducer } from './reducers/Demo';
import { ViewSettingsReducer } from './reducers/ViewSettings';
import { StaffingTimelineReducer } from './StaffingTimeline';
import { CompanyConfigReducer } from './reducers/CompanyConfigReducer';
import { ActiveAgentsReducer } from './reducers/ActiveAgents';
import { AgentStatesReducer } from './reducers/AgentStates';
import { FilterOptions } from '../models';

const initCSRF = (store: any) => {
  if (store.getState().fetchDetails.csrfToken) {
    return;
  }
  return fetch('/api/csrf', {
    method: 'GET',
    credentials: 'same-origin',
  }).then((response: Response) => {
    const token = getCSRFToken(response);
    store.dispatch(updateCSRF(token));
  });
};

// (2019-07-03 ryan): fetch all event types, even if inactive, so that we
// can display events created with event types that were later deleted.
const eventTypesFetch = (store: Store) =>
  fetch('/api/events/types?include_inactive=true', {
    method: 'GET',
    credentials: 'same-origin',
  })
    .then(handleFetchErrors)
    .then((res) => {
      if (!res) {
        return;
      }
      store.dispatch(updateCustomEventTypes(res));
    });

const filtersFetch = (store: Store) =>
  fetch('/api/filters', {
    method: 'GET',
    credentials: 'same-origin',
  })
    .then(handleFetchErrors)
    .then((res: FilterOptions) => {
      if (!res) {
        return;
      }

      // If schedule_id is already set in the store, do not overwrite it
      // with the master schedule_id. We do this because, if it is already set, it came from
      // a query param, which should take precedence over the master schedule id
      // (otherwise the query param is useless).
      if (store?.getState()?.filterParams.schedule_id) {
        store.dispatch(initFilters(res));
        return;
      }

      let scheduleId;
      if (res.schedules) {
        const master = res.schedules.find((sched) => sched.live);
        if (master) {
          scheduleId = master.id;
        }
      }

      store.dispatch(
        batchActions([initFilters(res), updateFilterParam('schedule_id', scheduleId)], 'INIT_FILTERS_AND_SCHEDULE_ID')
      );
    });

const flagFetch = (store: Store) =>
  fetch('/api/flags', {
    method: 'GET',
    credentials: 'same-origin',
  })
    .then(handleFetchErrors)
    .then((res) => {
      store.dispatch(updateFeatureFlags(res || {}));
    })
    .catch(() => {
      // this dispatch is also what sets feature flags as "loaded", a state which
      // is used downstream as a proxy for e.g. whether or not the store has been
      // fully initialized
      //
      // TODO: should have an explicit storeInitialized field
      store.dispatch(updateFeatureFlags({}));
    });

const companyConfigFetch = (store: Store) =>
  fetch('/api/company/config', {
    method: 'GET',
    credentials: 'same-origin',
  })
    .then(handleFetchErrors)
    .then((res) => {
      store.dispatch(updateCompanyConfig(res || {}));
    });

const staffingParametersFetch = (store: Store) =>
  fetch('/api/staffing_parameters', {
    method: 'GET',
    credentials: 'same-origin',
  })
    .then(handleFetchErrors)
    .then((res) => {
      if (!res) {
        return;
      }
      store.dispatch(updateMultipleVariables(res, false));
    });

const fetchPlatformCompanies = (store: Store) =>
  fetch('/api/platforms/company', {
    method: 'GET',
    credentials: 'same-origin',
  })
    .then(handleFetchErrors)
    .then((res) => {
      store.dispatch(updateCompanyPlatforms(res || []));
    });

const fetchAgentStateTypes = (store: Store) =>
  fetch('/api/agent_state_types', {
    method: 'GET',
    credentials: 'same-origin',
  })
    .then(handleFetchErrors)
    .then((res) => {
      store.dispatch(updateAgentStateTypes(res || []));
    });

const fetchResolvedAgentStateTypes = (store: Store) =>
  fetch('/api/mappings/agent_state_type/resolved', {
    method: 'GET',
    credentials: 'same-origin',
  })
    .then(handleFetchErrors)
    .then((res) => {
      store.dispatch(updateResolvedAgentStateTypes(res || []));
    });

const initStore = (store: Store) => {
  const promises: Promise<void>[] = [
    eventTypesFetch(store),
    filtersFetch(store),
    flagFetch(store),
    companyConfigFetch(store),
    staffingParametersFetch(store),
    fetchPlatformCompanies(store),
    fetchAgentStateTypes(store),
    fetchResolvedAgentStateTypes(store),
  ];

  return Promise.all(promises);
};

type AnyState = { [key: string]: any } | null | undefined;

const buildStore = (history: History<unknown>, preloadedState: AnyState = {}) => {
  const appReducer = combineReducers<AnyState>({
    router: connectRouter(history),
    // @ts-expect-error - TS2322 - Type '(state: EventTypes | null | undefined, action: EventTypesAction) => EventTypes' is not assignable to type 'Reducer<any>'.
    eventTypes: EventTypesReducer,
    // @ts-expect-error - TS2322 - Type '(state: VariablesState | null | undefined, action: VariablesAction) => VariablesState | null' is not assignable to type 'Reducer<any>'.
    variables: VariablesReducer,
    // @ts-expect-error - TS2322 - Type '(state: FetchDetails | null | undefined, action: FetchDetailsAction) => FetchDetails' is not assignable to type 'Reducer<any>'.
    fetchDetails: FetchDetailsReducer,
    // @ts-expect-error - TS2322 - Type '(state: FilterParams | null | undefined, action: FilterParamsAction) => FilterParams' is not assignable to type 'Reducer<any>'.
    filterParams: FilterParamsReducer,
    // @ts-expect-error - TS2322 - Type '(state: FilterOptions | null | undefined, action: FilterOptionsAction) => FilterOptions' is not assignable to type 'Reducer<any>'.
    filterOptions: FilterOptionsReducer,
    // @ts-expect-error - TS2322 - Type '(state: FeatureFlagsState | null | undefined, action: UpdateFeatureFlagsAction) => FeatureFlagsState | null' is not assignable to type 'Reducer<any>'.
    featureFlags: FeatureFlagsReducer,
    // @ts-expect-error - TS2322 - Type '(state: CompanyConfig | null | undefined, action: UpdateFeatureFlagsAction) => FeatureFlagsState | null' is not assignable to type 'Reducer<any>'.
    companyConfig: CompanyConfigReducer,
    // @ts-expect-error - TS2322 - Type '(state: GenerationSettings | null | undefined, action: GenerationSettingsAction) => GenerationSettings | null' is not assignable to type 'Reducer<any>'.
    generationSettings: GenerationSettingsReducer,
    // @ts-expect-error - TS2322 - Type '(state: IntegrationMappings | null | undefined, action: IntegrationMappingsAction) => Partial<Record<IntegrationMappingPlatform, ObjectMapping[]>> | null' is not assignable to type 'Reducer<any>'.
    integrationMappings: IntegrationMappingsReducer,
    // @ts-expect-error - TS2322 - Type '(state: StaffingSettings | null | undefined, action: StaffingSettingsAction) => { staffingTimelineV2?: boolean | undefined; secondaryTimezones?: string[] | undefined; sortBy?: SortKey[] | undefined; hideExtraColumns?: boolean | undefined; ... 7 more ...; isLoaded: boolean; } | null' is not assignable to type 'Reducer<any>'.
    staffingSettings: StaffingSettingsReducer,
    // @ts-expect-error - TS2322 - Type '(state: StaffingTimelineState | null | undefined, action: StaffingTimelineStateAction) => StaffingTimelineState' is not assignable to type 'Reducer<any>'.
    staffingTimeline: StaffingTimelineReducer,
    // @ts-expect-error - TS2322 - Type '(state: TemplatesReducerState | null | undefined, action: UpdateTemplatesAction) => TemplatesReducerState' is not assignable to type 'Reducer<any>'.
    templates: TemplatesReducer,
    // @ts-expect-error - TS2322 - Type '(state: UndoEventHistoryState | null | undefined, action: UndoEventHistoryAction) => UndoEventHistoryState' is not assignable to type 'Reducer<any>'.
    undoEventHistory: UndoEventHistoryReducer,
    // @ts-expect-error - TS2322 - Type '(state: DemoState | null | undefined, action: UpdateDemoStateAction) => DemoState' is not assignable to type 'Reducer<any>'.
    demo: DemoReducer,
    viewSettings: ViewSettingsReducer,
    // @ts-expect-error - TS2322: Type '(state: ActiveAgentsState | undefined, action: UpdateActiveAgentsAction) => ActiveAgentsState' is not assignable to type 'Reducer<any>'.
    agents: ActiveAgentsReducer,
    // @ts-ignore
    agentStates: AgentStatesReducer,
  });

  // See: https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store
  // Note that we want to keep CSRF info and drop everything else. This is why we preserve
  // fetchDetails in the state
  const rootReducer = (state: AnyState, action: any) => {
    if (action.type === 'LOGOUT_USER') {
      if (state) {
        const { fetchDetails } = state; // keep CSRF
        state = { fetchDetails };
      } else {
        state = undefined;
      }
    }

    return appReducer(state, action);
  };

  const middleware = [routerMiddleware(history)];

  if (process.env.NODE_ENV === 'development') {
    // NOTE: If you want to log changes to the redux store, you may uncomment this, along with the import at the top of this file.
    // middleware.push(createLogger());
  }

  return createStore(enableBatching(rootReducer), preloadedState, applyMiddleware(...middleware));
};

export type Store = ReturnType<typeof buildStore>;

export { buildStore, initStore, initCSRF };
