const Ranges = {
  day: 'day',
  week: 'week',
  month: 'month',
  custom: 'custom',
} as const;

const TIMELINE_RANGES = {
  day: 'day',
  week: 'week',
  custom: 'custom',
} as const;

export type Range = 'day' | 'week' | 'month' | 'custom';

export { Ranges, TIMELINE_RANGES };
