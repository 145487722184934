import moment, { Moment } from 'moment/moment';

import UserManager from '../../../utils/UserManager';
import { DayState, ShiftRecords } from '../types/models';
import { DAY_OPTIONS, OUTBOUND_DATE_FORMAT, SHORT_DAY_OPTIONS, SUMMARY_DAY_OPTIONS } from './constants';
import { Shift } from '../../../../models';
import { formatDurationSeconds } from '../../../utils/FormatTyped';

// This method takes a list of day string abbreviations and returns a concise, 'merged'
// representation of the list.  For example, the input ['M', 'Tu', 'W', 'Th', 'F'] will
// return 'M-F', while the input ['M', 'W', 'F'] will return 'M, W, F'.
export function mergeContiguousDaysOfWeek(daysOfWeek: string[]): string {
  if (!Array.isArray(daysOfWeek) || daysOfWeek.length === 0) {
    return '';
  }

  // The default list of ordered abbreviated day strings, which starts with Monday.
  let order = Object.values(SUMMARY_DAY_OPTIONS);

  const defaultStartOfWeek = 0;
  const startOfWeek = UserManager.user?.company?.start_of_week || defaultStartOfWeek;

  // If the company has explicitly set a start date (e.g. Sunday instead of Monday), rearrange the
  // ordering to reflect that.
  if (startOfWeek !== defaultStartOfWeek) {
    order = [...order.slice(startOfWeek), ...order.slice(0, startOfWeek)];
  }

  // Sort the days of the week based on the ordering.
  const sortedDaysOfWeek = [...daysOfWeek].sort((day1: string, day2: string) => {
    return order.indexOf(day1) - order.indexOf(day2);
  });

  const mergedDays: string[] = [];
  let currentRange: string[] = [sortedDaysOfWeek[0]];

  for (let i = 1; i < sortedDaysOfWeek.length; i += 1) {
    const currentDay = sortedDaysOfWeek[i];
    const prevDay = currentRange[currentRange.length - 1];

    // Check if the current day is contiguous to the previous day
    if (order.indexOf(currentDay) === order.indexOf(prevDay) + 1) {
      currentRange.push(currentDay);
    } else {
      // If not contiguous, push the current range as a string
      if (currentRange.length === 1) {
        mergedDays.push(prevDay);
      } else {
        mergedDays.push(`${currentRange[0]}-${prevDay}`);
      }
      currentRange = [currentDay];
    }
  }

  // Push the last range
  if (currentRange.length === 1) {
    mergedDays.push(currentRange[0]);
  } else {
    mergedDays.push(`${currentRange[0]}-${currentRange[currentRange.length - 1]}`);
  }

  return mergedDays.join(', ');
}

export function computeShiftDurationSummary(startMoment: moment.Moment, endMoment: moment.Moment): string {
  const hours = endMoment.diff(startMoment, 'hours');
  const minutes = endMoment.diff(startMoment, 'minutes') % 60;

  if (minutes > 0) {
    return `${hours}h${minutes}m`;
  }

  return `${hours}h`;
}

export function formatDuration(numSeconds: number): string {
  return formatDurationSeconds(numSeconds, { hideSeconds: true, shorten: true });
}

// convertToShift takes a DayState and converts it to a Shift for the backend
export function convertToShift(dayState: DayState): Shift {
  const startDateTime = moment(dayState.startTime).utc();

  const endDateTime = moment(dayState.endTime).utc();

  return {
    start_time: startDateTime.format(OUTBOUND_DATE_FORMAT),
    end_time: endDateTime.format(OUTBOUND_DATE_FORMAT),
  };
}

// convertShiftsToShiftRecords will take a shift from the backend and convert it for display in the shift pattern table
// should return something like: { dayOfWeek: 'monday', startTime: '08:00', endTime: '17:00', duration: '9h' }
export function convertShiftsToShiftRecords(shifts: Shift[]): ShiftRecords {
  const convertedShifts = shifts.map((shift) => {
    const startTime = moment.utc(shift.start_time);
    const endTime = moment.utc(shift.end_time);

    const dayOfWeek = DAY_OPTIONS[startTime.isoWeekday()];
    const shortDayOfWeek = SHORT_DAY_OPTIONS[startTime.isoWeekday()];

    const durationInHours = moment.duration(endTime.diff(startTime)).asHours();

    return {
      shortDayOfWeek,
      dayOfWeek,
      startTime,
      endTime,
      durationInHours,
    };
  });

  const earliestStartTimeInWeek = moment.min(...convertedShifts.map((shiftRecord) => shiftRecord.startTime));

  // Create an empty ShiftRecords object
  const shiftRecords: ShiftRecords = {};

  // Populate the ShiftRecords object
  convertedShifts.forEach((convertedShift) => {
    const dayOfWeek = convertedShift.dayOfWeek?.toLowerCase();
    if (dayOfWeek) {
      shiftRecords[dayOfWeek] = { ...convertedShift, earliestStartTimeInWeek };
    }
  });

  return shiftRecords;
}

export function formatISOStringAsHoursMins(time: string): string {
  return moment(time).utc().format('HH:mm');
}

export function getTimeRangeString(startTime: Moment, endTime: Moment, format: string) {
  const startTimeString = startTime.format(format);
  const endTimeString = endTime.format(format);
  return `${startTimeString} - ${endTimeString}`;
}
