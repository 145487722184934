import * as React from 'react';
import { Link } from 'assemblage';

import { ErrorPageContainer } from './ErrorPageContainer';

function InternalError() {
  return (
    <ErrorPageContainer
      title="Something went wrong."
      message={
        <>
          An unexpected error occured. If you keep seeing this, please reach out to{' '}
          <Link href="mailto:support@assembled.com" target="_blank">
            support@assembled.com
          </Link>
          , and we'll look into this as soon as possible.
        </>
      }
    />
  );
}

export { InternalError };
