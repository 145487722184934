import { CompanyConfig } from '../../models';
import type { ActionType } from '../actions';

type UpdateCompanyConfigAction = {
  type: ActionType;
  companyConfig: CompanyConfig;
};

const CompanyConfigReducer = (
  state: CompanyConfig = {
    vendor_requirement_interval: ['week'],
    prevent_save_if_stale: true,
    end_of_shift_exclusion: false,
    exclusion_trigger_minutes: 10,
    zendesk_unified_agent_status: false,
    agent_state_editing_enabled: false,
    event_classification: 'default',
    schedule_adherence_enabled: false,
    show_gcal_event_details: true,
    bpo_planner_comparison_threshold: [-10, 10],
  },
  action: UpdateCompanyConfigAction
) => {
  switch (action.type) {
    case 'UPDATE_COMPANY_CONFIG':
      return {
        ...state,
        ...action.companyConfig,
      };
    default:
      return state;
  }
};

export { CompanyConfigReducer };
