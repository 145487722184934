import moment from 'moment';

import { parseQueryString } from '../../components/utils/Location';
import { Ranges } from '../../constants/RangeConstants';
import type { ActionType } from '../actions';
import type { FilterParams } from '../../models';

type SetDefaultFilterParamsAction = {
  type: ActionType;
  update: any;
  payload: any;
  value: unknown;
  paramName: string;
};

type UpdateFilterParamAction = {
  type: ActionType;
  update: any;
  payload: any;
  value: unknown;
  paramName: string;
};

type UpdateFilterParamsAction = {
  type: ActionType;
  update: any;
  payload: any;
  value: unknown;
  paramName: string;
};

type LocationChangeAction = {
  type: ActionType;
  update: any;
  payload: any;
  value: unknown;
  paramName: string;
};

type FilterParamsAction =
  | SetDefaultFilterParamsAction
  | UpdateFilterParamAction
  | UpdateFilterParamsAction
  | LocationChangeAction;

export const initialFilterParamsState = {
  channel: null,
  site: null,
  restricted_site: null,
  skill: null,
  team: null,
  queue: null,
  schedule_id: null,
  date: moment().startOf('day'),
  start: moment().startOf('day'),
  end: moment().startOf('day').add(1, 'day'),
  range: Ranges.day,
  limit: 100,
  agentSelect: null,
  limitModified: false,
  setByLocationChange: false,
  dateRangeInterval: undefined,
} as const;

const bulkUpdateReducer = (state: FilterParams, rawUpdate: any = {}, warn = true): FilterParams => {
  const update = Object.keys(rawUpdate).reduce<Record<string, any>>((acc, paramName) => {
    if (!initialFilterParamsState.hasOwnProperty(paramName)) {
      if (warn) {
        console.warn(`Invalid filter param name: ${paramName}`);
      }
      return acc;
    }

    let newVal = rawUpdate[paramName];
    // we'll occasionally parse from a query string, in which case we
    // should convert values from strings
    if (paramName === 'limit' && typeof newVal === 'string') {
      newVal = parseInt(newVal, 10);
      acc.limitModified = true;
    }

    acc[paramName] = newVal;
    return acc;
  }, {});

  return { ...state, ...update };
};

const singleUpdateReducer = (
  state: FilterParams,
  action: {
    value: any;
    paramName: string;
  }
): FilterParams => {
  const { value, paramName } = action;
  if (!initialFilterParamsState.hasOwnProperty(paramName)) {
    console.warn(`Invalid filter param name: ${paramName}`);
    return state;
  }
  const update: Record<string, any> = {};
  update[paramName] = value;
  return { ...state, ...update };
};

const setDefaultReducer = (
  state: FilterParams,
  rawUpdate: {
    [key: string]: any;
  }
): FilterParams => {
  const update = Object.keys(rawUpdate).reduce<Record<string, any>>((acc, paramName) => {
    if (paramName === 'limit') {
      if (!state.limitModified) {
        acc[paramName] = rawUpdate[paramName];
      }
      // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'FilterParams'.
    } else if (state[paramName] === null) {
      acc[paramName] = rawUpdate[paramName];
    }
    return acc;
  }, {});

  return bulkUpdateReducer(state, update);
};

const FilterParamsReducer = (
  state: FilterParams = initialFilterParamsState,
  action: FilterParamsAction
): FilterParams => {
  switch (action.type) {
    case 'SET_DEFAULT_FILTER_PARAMS':
      if (!state.setByLocationChange) {
        return setDefaultReducer(state, action.update);
      }
      return state;

    case 'UPDATE_FILTER_PARAM':
      return singleUpdateReducer(state, action);
    case 'UPDATE_FILTER_PARAMS':
      return bulkUpdateReducer(state, action.update);
    case 'LOCATION_CHANGE': {
      const rawUpdate = parseQueryString(action.payload);
      const update = bulkUpdateReducer(state, rawUpdate, false);
      if (Object.keys(rawUpdate).length > 0) {
        update.setByLocationChange = true;
      }
      return update;
    }
    default:
      return state;
  }
};

export { FilterParamsReducer };
