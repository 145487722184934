import type { Channel, FeatureFlags } from '../../../models';
import { ALL_CHANNELS_WITHOUT_ALL } from '../../FilterConstants';

function atLeastOneChannelTicketsV2Enabled(featureFlags: FeatureFlags): boolean {
  return ALL_CHANNELS_WITHOUT_ALL.some((channel) => featureFlags[`tickets_v2.metrics.${channel}`]);
}

function ticketsV2Enabled(channel: Channel, featureFlags: FeatureFlags): boolean {
  if (channel === 'all') {
    return atLeastOneChannelTicketsV2Enabled(featureFlags);
  }
  const flagName = 'tickets_v2.metrics.'.concat(channel);
  return featureFlags[flagName];
}

export { atLeastOneChannelTicketsV2Enabled, ticketsV2Enabled };
