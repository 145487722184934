import { AgentStateType, ObjectMapping } from '../../models';
import { PlatformInfo } from '../../components/utils/Platforms';

export type UpdateAgentStateTypesAction = {
  type: 'UPDATE_AGENT_STATE_TYPES';
  agentStateTypes: AgentStateType[];
};

export type UpdateResolvedAgentStateTypesAction = {
  type: 'UPDATE_RESOLVED_AGENT_STATE_TYPES';
  resolvedAgentStateTypes: ObjectMapping[];
};

export type UpdateCompanyPlatformsAction = {
  type: 'UPDATE_COMPANY_PLATFORMS';
  companyPlatforms: PlatformInfo[];
};

type UpdateAgentStatesTypeAction =
  | UpdateAgentStateTypesAction
  | UpdateResolvedAgentStateTypesAction
  | UpdateCompanyPlatformsAction;

export type AgentStatesReducerType = {
  agentStateTypes: AgentStateType[];
  resolvedAgentStateTypes: ObjectMapping[];
  companyPlatforms: PlatformInfo[];
};

export const initialState = {
  agentStateTypes: [],
  resolvedAgentStateTypes: [],
  companyPlatforms: [],
} as const;

export const AgentStatesReducer = (state: AgentStatesReducerType, action: UpdateAgentStatesTypeAction) => {
  if (!state) {
    return initialState;
  }
  switch (action.type) {
    case 'UPDATE_AGENT_STATE_TYPES':
      return {
        ...state,
        agentStateTypes: action.agentStateTypes,
      };
    case 'UPDATE_RESOLVED_AGENT_STATE_TYPES':
      return {
        ...state,
        resolvedAgentStateTypes: action.resolvedAgentStateTypes,
      };
    case 'UPDATE_COMPANY_PLATFORMS':
      return {
        ...state,
        companyPlatforms: action.companyPlatforms,
      };
    default:
      return state;
  }
};
