import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import ReactGA from 'react-ga';
import LogRocket from 'logrocket';
import { find, get } from 'lodash-es';

import type { User } from '../../models';
import { CustomError } from './Errors';

const LOGROCKET_ID = process.env.REACT_APP_LOGROCKET_ID!;

const LOGROCKET_DISABLED_COMPANIES = [
  '6620d521-f50b-41ef-a085-b997a9ae49f4', // Looker
  '60fec482-091a-4297-ad9e-accec99d11a2', // Intercom
  '80b855d8-b942-41e0-bc6d-e1e26508473b', // Monday
  'c2e1c064-f3b3-403c-9311-89bf110b4745', // Datadog
];

const PENDO_DISABLED_COMPANIES = [
  '6620d521-f50b-41ef-a085-b997a9ae49f4', // Looker
  '60fec482-091a-4297-ad9e-accec99d11a2', // Intercom
  '80b855d8-b942-41e0-bc6d-e1e26508473b', // Monday
  'c2e1c064-f3b3-403c-9311-89bf110b4745', // Datadog
];

const TEAM_ONCALL = 'team-oncall';
const TEAM_METRICS = 'team-metrics';
const TEAM_AGENT_FLEXIBILITY = 'team-agent-flexibility';
const TEAM_MANAGER_EXPERIENCE = 'team-manager-experience';

// NOTE(Jason): These rules are applied in the order they are seen. So if you
// want to override a more generic endpoint cover, you should add them to the top
// For example /reports/forecasted-vs-actual will be bucketed to team forecast, whereas
// the rest of /reports will be bucketed to team metrics.
const SENTRY_ASSIGNEE_RULES = [
  {
    endpoint: '/staffing/timeline',
    sentry_assignee: TEAM_MANAGER_EXPERIENCE,
  },
  {
    endpoint: '/realtime/v3',
    sentry_assignee: TEAM_AGENT_FLEXIBILITY,
  },
  {
    endpoint: '/realtime/v4',
    sentry_assignee: TEAM_AGENT_FLEXIBILITY,
  },
  {
    endpoint: '/staffing/generations',
    sentry_assignee: TEAM_AGENT_FLEXIBILITY,
  },
];

function lookupSentryAssignee(url: string): string {
  const res = find(SENTRY_ASSIGNEE_RULES, ({ endpoint }) => {
    return url.includes(endpoint);
  });
  if (res) {
    return res.sentry_assignee;
  }
  return TEAM_ONCALL;
}

function initSentry() {
  if (process.env.BUILD_ENV === 'production') {
    Sentry.addTracingExtensions();
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,

      // See: https://docs.sentry.io/platforms/javascript/configuration/filtering/
      ignoreErrors: [
        // 400
        /Bad Request/,
        // 401
        /Unauthorized/,
        // 403
        /Forbidden/,
        // 404
        /Not Found/,
        // 409
        /409 Conflict/,
        // 502
        /Bad Gateway/,
        // ignore zendesk sdk cannot find client errors
        /ZAF SDK/,
        // ignore failed to fetch errors these are really noisey and don't give us any info
        // and most of the time is due to adblockers or network problems, not assembled
        /Failed to fetch/,
        // this always happens on the zendesk app on initial load. its a 401 that we don't want to log
        // https://assembled.sentry.io/issues/3935008358/?project=245755&query=is:unresolved&sort=freq&statsPeriod=14d
        /API Key not found/,
        // we can safely ignore this error, it doesn't break the app
        // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
        /ResizeObserver loop limit exceeded/,
        // CSS chunk load errors. This regex is pretty specific but currently matches for Chrome, Firefox, and Safari.
        /Loading CSS chunk \d+ failed/,
      ],

      beforeSend(event, hint) {
        if (get(hint, ['originalException', 'error']) === 'idpiframe_initialization_failed') {
          return null;
        }

        const exception = hint.originalException;
        if (exception instanceof CustomError) {
          event.fingerprint = ['{{ default }}', exception.message];
        }

        // todo(wei-wei): re-enable this once we wanna clean up FE issues. For posterity: https://assembled-hq.slack.com/archives/C6WTZRNF7/p1659367798773309
        event.tags = get(event, ['tags'], {});
        const assignee = lookupSentryAssignee(get(event, ['request', 'url']));
        if (assignee === TEAM_ONCALL && event.level !== 'fatal') {
          return null;
        }
        event.tags.sentry_assignee = assignee;

        // associating sentry error with logrocket session https://docs.logrocket.com/docs/sentry
        const logRocketSession = LogRocket.sessionURL;
        if (logRocketSession !== null && event.extra) {
          event.extra.LogRocket = logRocketSession;
        }

        return event;
      },

      // todo(wei-wei): set to 1 this once we wanna clean up FE issues. For posterity: https://assembled-hq.slack.com/archives/C6WTZRNF7/p1659367798773309
      sampleRate: 0.5,

      // configuring releases for Sentry
      // https://docs.sentry.io/product/releases/
      environment: process.env.BUILD_ENV,
      release: process.env.COMMIT_SHA,

      // https://docs.sentry.io/platforms/javascript/guides/react/
      tracesSampleRate: 0.01,
      integrations: [new BrowserTracing()],

      maxValueLength: 1000,
    });
  }
}

function initGoogleAnalytics() {
  // null isn't valid typescript, and we've chosen not to do the ! operation to assert non-null;
  let googleTrackingID = process.env.REACT_APP_GOOGLE_TRACKING_ID || '';
  if (process.env.BUILD_ENV === 'production') {
    ReactGA.initialize(googleTrackingID);
  } else {
    // leaving this as an empty string still shows the console error for a null sting
    googleTrackingID = 'XX-TEST-GA-ID';
    ReactGA.initialize(googleTrackingID, { testMode: true });
  }
}

function initChatWindow(user: User) {
  if (window.Intercom) {
    if (user) {
      window.Intercom('boot', {
        app_id: process.env.REACT_APP_INTERCOM_APP_ID,
        email: user.email,
        created_at: Number(user.created_at),
        name: `${user.first_name} ${user.last_name}`,
        user_id: user.id,
        user_hash: user.intercom_hmac || undefined,
        company: user.company
          ? {
              name: user.company.name,
              company_id: user.company.id,
              company_name: user.company.name,
              created_at: Number(user.company.created_at),
            }
          : undefined,
      });
    }
  }
}

function stopChatWindow() {
  if (window.Intercom) {
    window.Intercom('shutdown');
  }
}

function initLogRocket() {
  if (process.env.BUILD_ENV === 'production') {
    LogRocket.init(LOGROCKET_ID, {
      console: {
        isEnabled: {
          log: false,
          info: false,
          debug: false,
          warn: false,
          error: true,
        },
      },
      browser: {
        urlSanitizer: (url) => {
          let sanitizedUrl = url;
          const forgotPath = '/forgot/finish/';
          const forgotIndex = sanitizedUrl.indexOf(forgotPath);
          if (forgotIndex !== -1) {
            sanitizedUrl = `${sanitizedUrl.substring(0, forgotIndex + forgotPath.length)}REDACTED`;
          }
          return sanitizedUrl;
        },
      },
      network: {
        isEnabled: false,
      },
    });
  }
}

export {
  initSentry,
  initChatWindow,
  initGoogleAnalytics,
  stopChatWindow,
  initLogRocket,
  LOGROCKET_DISABLED_COMPANIES,
  PENDO_DISABLED_COMPANIES,
  // Exporting for testing purposes
  lookupSentryAssignee,
  TEAM_METRICS,
  TEAM_ONCALL,
  TEAM_AGENT_FLEXIBILITY,
  TEAM_MANAGER_EXPERIENCE,
};
