import * as React from 'react';
import { Select, Option, SelectProps } from '@assembled/react-select';
import { Link } from 'react-router-dom';

import { reactSelectStyles } from './utils/Select';
import { MENU_WIDTH } from '../constants/MenuConstants';
import { EVENT_MENU_ZINDEX } from '../constants/StaffingTimelineConstants';
import { isLightMode } from './utils/ColorMode';

// Don't change this unless you have a really good reason!
// This behavior actually scrolls to `left: 0`. This is really bad for the
// staffing timeline, since we will release the mouse up event on a different
// component from the one we originally clicked on and will cause rootClose of
// any open overlays and really aggravating jumpy behavior.
const REACT_SELECT_SCROLL_INTO_VIEW = false;

export interface SelectOption {
  label: string;
  value: string;
}

export class MenuSelectInput<T extends SelectOption = SelectOption> extends React.PureComponent<{
  value?: T;
  options?: T[];
  filterOption?: (option: Option, rawInput: string) => boolean;
  isClearable?: boolean;
  hasError?: boolean;
  placeholder?: string;
  onChange: (value: T) => void;
  components?: object;
  maxMenuHeight?: number;
  selectRef?: SelectProps['ref'];
}> {
  render() {
    return (
      <Select
        styles={reactSelectStyles}
        className="react-select-container"
        classNamePrefix="react-select"
        isClearable={this.props.isClearable}
        filterOption={this.props.filterOption}
        placeholder={this.props.placeholder}
        options={this.props.options}
        maxMenuHeight={this.props.maxMenuHeight || 225}
        menuPlacement="bottom"
        menuShouldScrollIntoView={REACT_SELECT_SCROLL_INTO_VIEW}
        value={this.props.value}
        /* Every consumer expects only valid options to be returned in the onChange handler,
           even though Select can return null or undefined values */
        onChange={(option) => {
          if (option) {
            this.props.onChange(option);
          }
        }}
        components={this.props.components}
        ref={this.props.selectRef}
      />
    );
  }
}

type MenuHeaderProps = {
  style?: React.CSSProperties;
};

export class MenuHeader extends React.Component<MenuHeaderProps> {
  render() {
    const style = {
      backgroundColor: isLightMode ? 'var(--surface-lower)' : 'var(--surface-higher)',
      ...this.props.style,
    };

    return (
      <MenuOption style={style} hideHoverStyles>
        {this.props.children}
      </MenuOption>
    );
  }
}

type LinkableMenuContainerProps = {
  to?: string | null | undefined;
  className?: string;
  style?: React.CSSProperties;
  children: React.ReactNode;
};

// LinkableMenuContainer allows you to link to the `to` prop. You can also leave
// the `to` prop empty and this container will default to a div. This component
// acts like a regular link but looks like a regular div without Link styling.
export function LinkableMenuContainer(props: LinkableMenuContainerProps) {
  if (!props.to) {
    return (
      <div className={props.className} style={props.style}>
        {props.children}
      </div>
    );
  }

  // Overwrite <a> tag styling to make it seem like a regular menu option,
  // not a link.
  const style = {
    textDecoration: 'inherit',
    backgroundColor: 'inherit',
    color: 'inherit',
    display: 'block',
    ...props.style,
  };

  return (
    <Link className={props.className} style={style} to={props.to}>
      {props.children}
    </Link>
  );
}

type MenuOptionProps = {
  style?: React.CSSProperties;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  linkURL?: string;
  allowOverflow?: boolean;
  hideHoverStyles?: boolean;
  hoverBackgroundColor?: string;
};

type MenuOptionState = {
  hovering: boolean;
};

export class MenuOption extends React.Component<MenuOptionProps, MenuOptionState> {
  constructor(props: MenuOptionProps) {
    super(props);
    this.state = {
      hovering: false,
    };
  }

  render() {
    const parentStyle: React.CSSProperties = { display: 'block' };
    if (!this.props.hideHoverStyles && this.state.hovering) {
      parentStyle.backgroundColor = this.props.hoverBackgroundColor || 'var(--menu-option-surface-hover)';
    }

    const baseStyle = {
      position: 'relative',
      cursor: 'pointer',
      maxHeight: '100%',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      padding: '0px 12px',
      overflow: 'hidden',
    };

    if (this.props.hideHoverStyles) {
      // @ts-expect-error - TS2790 - The operand of a 'delete' operator must be optional.
      delete baseStyle.cursor;
    }

    if (this.props.allowOverflow) {
      baseStyle.whiteSpace = 'initial';
      baseStyle.overflow = 'initial';
    }

    const style = Object.assign(baseStyle, this.props.style);

    return (
      <div
        style={parentStyle}
        onMouseEnter={() => this.setState({ hovering: true })}
        onMouseLeave={() => this.setState({ hovering: false })}
        onClick={this.props.onClick}
      >
        <LinkableMenuContainer className="menu-option" style={style} to={this.props.linkURL}>
          {this.props.children}
        </LinkableMenuContainer>
      </div>
    );
  }
}

type MenuOverlayProps = {
  style?: React.CSSProperties;
  className?: string;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
};

export class MenuOverlay extends React.PureComponent<MenuOverlayProps> {
  render() {
    const style: React.CSSProperties = {
      width: `${MENU_WIDTH}px`,
      fontWeight: 'normal',
      position: 'absolute',
      boxShadow:
        'var(--popup-box-shadow-x-offset) var(--popup-box-shadow-y-offset) var(--popup-box-shadow-blur) var(--popup-box-shadow-spread) var(--popup-box-shadow-base)',
      textAlign: 'left',
      height: 'fit-content',
      transformOrigin: '50% 50% 0px',
      borderRadius: '4px',
      backgroundColor: 'var(--popover-surface)',
      color: 'var(--text-base)',
      cursor: 'default',
      padding: '5px 0',
      zIndex: EVENT_MENU_ZINDEX,
      ...this.props.style,
    };

    let className = 'menu-overlay';
    if (this.props.className) {
      className += ` ${this.props.className}`;
    }
    return (
      <div
        className={className}
        style={style}
        onMouseEnter={this.props.onMouseEnter}
        onMouseLeave={this.props.onMouseLeave}
      >
        {this.props.children}
      </div>
    );
  }
}

type CheckableMenuOptionProps = {
  isChecked: boolean;
  onCheck: React.MouseEventHandler;
  componentClass: React.ElementType;
};

export class CheckableMenuOption extends React.PureComponent<CheckableMenuOptionProps> {
  static defaultProps = {
    componentClass: MenuOption,
  };

  render() {
    const mainStyle: React.CSSProperties = {
      display: 'inline',
    };

    if (!this.props.isChecked) {
      mainStyle.paddingLeft = '18px';
    }

    const Component = this.props.componentClass;

    return (
      <Component style={{ overflow: 'initial' }} onClick={this.props.onCheck}>
        <div
          style={{
            marginTop: '3px',
          }}
          className="overflow-ellipsis"
        >
          {this.props.isChecked && <span className="icon icon-check" style={{ marginRight: '4px' }} />}
          <div style={mainStyle}>{this.props.children}</div>
        </div>
      </Component>
    );
  }
}
