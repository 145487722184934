import * as React from 'react';
import moment from 'moment';
import { Col, Alert } from '@assembled/react-bootstrap';
import { Slide, toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { isLightMode } from './utils/ColorMode';
import { CloseIcon } from './CloseIcon';

import '../css/notifications.css';

const alertStyle = {
  border: '0',
  borderRadius: '0',
  color: '#25272F',
  marginBottom: '0',
  padding: '0px 5px',
  fontFamily: "'IBM Plex Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
  whiteSpace: 'pre-wrap',
} as const;

const infoCloseStyle = {
  backgroundColor: isLightMode ? '#E0E9FF' : '#3D528A',
  color: isLightMode ? '#676B79' : '#E5EBFB',
} as const;

const BANNER_TOAST_ID = 'banner';

function BannerContainer() {
  return (
    <ToastContainer
      enableMultiContainer
      containerId={BANNER_TOAST_ID}
      position="top-center"
      transition={Slide}
      closeButton={false}
    />
  );
}

/** @deprecated Use Assemblage [toast](https://assemblage.gokome.com/?path=/docs/stable-notifications-toast--docs) instead */
class Banner {
  static show(
    type: string,
    style: string,
    icon: React.ReactNode,
    message: React.ReactNode,
    autoClose: number | boolean = true
  ) {
    // @ts-expect-error - TS2345 - Argument of type 'ReactNode' is not assignable to parameter of type 'ToastId'.
    if (toast.isActive(message)) {
      return;
    }

    const alertVariant = `${type}-alert-${isLightMode ? 'light' : 'dark'}`;

    toast(
      ({ closeToast }: { closeToast: () => void }) => (
        <Alert className={`d-flex justify-center ${alertVariant}`} bsStyle={style} style={alertStyle}>
          <div className={`p-0 d-flex justify-content-between align-items-stretch ${alertVariant}`}>
            <Col xs={11} style={{ padding: '15px 8px' }}>
              <div className="d-flex justify-center" style={{ display: 'flex', alignItems: 'center' }}>
                <Col className={`${type}-alert-icon`}>{icon}</Col>
                <Col style={{ overflowWrap: 'break-word', textAlign: 'center' }}>{message}</Col>
              </div>
            </Col>
            <Col
              className={`d-flex justify-center align-items-center alert-close ${type}-alert-close`}
              style={type === toast.TYPE.DEFAULT ? infoCloseStyle : {}}
              onClick={closeToast}
            >
              <CloseIcon />
            </Col>
          </div>
        </Alert>
      ),
      {
        // @ts-expect-error - TS2322 - Type 'string' is not assignable to type 'TypeOptions | undefined'.
        type,
        // @ts-expect-error - TS2322 - Type 'number | boolean' is not assignable to type 'number | false | undefined'.
        autoClose,
        // @ts-expect-error - TS2322 - Type 'ReactNode' is not assignable to type 'ToastId | undefined'.
        toastId: message,
        containerId: BANNER_TOAST_ID,
        // @ts-expect-error - TS2322 - Type 'string' is not assignable to type 'ComponentType<{}> | undefined'.
        transition: '',
        draggable: false,
      }
    );
  }

  static info(message: React.ReactNode, autoClose: number | boolean = true, localStorageExpirationKey?: string | null) {
    if (!localStorageExpirationKey) {
      this.show(toast.TYPE.DEFAULT, '', <span className="icon icon-info" />, message, autoClose);
    } else {
      const lastSeen = localStorage.getItem(localStorageExpirationKey);
      if (!lastSeen || moment().diff(moment(lastSeen), 'day') > 7) {
        this.show(toast.TYPE.DEFAULT, '', <span className="icon icon-info" />, message, autoClose);
        localStorage.setItem(localStorageExpirationKey, moment().format('MM-DD-YYYY'));
      }
    }
  }

  static success(message: React.ReactNode, autoClose: number | boolean = true) {
    this.show(toast.TYPE.SUCCESS, 'success', <span className="icon icon-check m-r-sm" />, message, autoClose);
  }

  static error(message: React.ReactNode) {
    this.show(toast.TYPE.ERROR, 'danger', <span className="icon icon-warning m-r-sm" />, message, false);
  }

  static warning(message: React.ReactNode) {
    this.show(toast.TYPE.WARNING, 'warning', <span className="icon icon-warning m-r-sm" />, message, false);
  }

  static showErrors(errors?: Array<any>) {
    if (!errors || errors.length === 0) {
      return;
    }
    errors.forEach((error) => Banner.error(error));
  }

  static dismissAll() {
    toast.dismiss();
  }
}

export { BannerContainer };
export default Banner;
