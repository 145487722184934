// WebSocket doesn't support relative paths as in fetch. Instead, construct a
// URL correctly depending on the environment
import * as React from 'react';

import { catchErrors } from './Errors';

export function websocketPath(relativePath: string): string {
  // Only use ws:// for local-dev, remote-dev is all proxied with SSL passthrough
  if (window.location.hostname === 'localhost') {
    return `ws://localhost:3000${relativePath}`;
  }

  return `wss://${window.location.hostname}${relativePath}`;
}

export type WebSocketOptions = {
  relativePath: string;
  onOpen: (ws: WebSocket) => void;
  onMessage: (msg: string) => void;
  reconnect: boolean;
  onError?: (e: Event) => void;
  onClose?: () => void;
};

export type WebSocketControls = {
  wsRef: React.MutableRefObject<WebSocket | null>;
  wsOpts: WebSocketOptions | null;
  setWsOpts: React.Dispatch<React.SetStateAction<WebSocketOptions | null>>;
};

function useWebSocket(): WebSocketControls {
  const wsRef = React.useRef<WebSocket | null>(null);
  const [wsOpts, setWsOpts] = React.useState<WebSocketOptions | null>(null);

  React.useEffect(() => {
    if (wsOpts) {
      setupWebSocket(
        wsOpts.relativePath,
        wsOpts.onOpen,
        wsOpts.onMessage,
        wsOpts.reconnect,
        true,
        wsRef,
        wsOpts.onError,
        wsOpts.onClose
      );
    }
    return () => {
      if (wsRef.current) {
        // We do this to prevent reconnect behavior and error logging when we really want to close it
        wsRef.current.onclose = () => {};
        wsRef.current.onerror = () => {};
        wsRef.current.onclose = () => {};
        wsRef.current.close(1000);
        wsRef.current = null;
      }
    };
  }, [wsOpts]);

  return { wsRef, wsOpts, setWsOpts };
}

// When hooks=true, we won't try to auto-magically catch the error, since that
// is a legacy route that requires the use of setState on the component.
function setupWebSocket(
  relativePath: string,
  onOpen: (ws: WebSocket) => void,
  onMessage: (msg: string) => void,
  reconnect: boolean,
  hooks: boolean,
  wsRef: {
    current: WebSocket | null | undefined;
  },
  onError?: (e: Event) => void,
  onClose?: () => void
) {
  const client = new WebSocket(websocketPath(relativePath));
  client.onerror = (e) => {
    if (onError) {
      onError(e);
    }
    // These are errors to do with a connection is closed. In practice, our
    // endpoint closes the connection on error but it may be more efficient
    // to pass error messages back and forth.
    //
    // See: https://developer.mozilla.org/en-US/docs/Web/API/WebSocket/error_event
    if (!hooks) {
      // @ts-expect-error - TS2683 - 'this' implicitly has type 'any' because it does not have a type annotation.
      catchErrors.bind(this)(e);
    }

    if (reconnect) {
      // @ts-expect-error - TS2683 - 'this' implicitly has type 'any' because it does not have a type annotation.
      setupWebSocket.bind(this)(relativePath, onOpen, onMessage, false, hooks, wsRef);
    }
  };
  client.onclose = () => {
    if (onClose) {
      onClose();
    }
    const err = new Error('Oops, something went wrong. Please refresh the page.');
    if (!hooks) {
      // @ts-expect-error - TS2683 - 'this' implicitly has type 'any' because it does not have a type annotation.
      catchErrors.bind(this)(err);
    }

    // This handles disconnects such as when the server drops the connection
    // due to a restart; the reconnect parameter may not be necessary but in
    // theory prevents infinite loops that may pop up for unforeseen reasons
    if (reconnect) {
      // @ts-expect-error - TS2683 - 'this' implicitly has type 'any' because it does not have a type annotation.
      setupWebSocket.bind(this)(relativePath, onOpen, onMessage, false, hooks, wsRef);
    }
  };
  client.onmessage = (e: MessageEvent) => {
    const { data } = e;
    if (typeof data === 'string') {
      onMessage(data);
    }
  };
  client.onopen = () => {
    onOpen(client);
  };
  wsRef.current = client;
}

export { setupWebSocket, useWebSocket };
