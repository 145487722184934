import { cleanTimezoneName, computeTimezoneOffset, formatTimezoneOffset } from '../../../utils/Timezone';

function formatShiftTimezone(timezone: string): string {
  const tz = cleanTimezoneName(timezone);
  const offset = formatTimezoneOffset(computeTimezoneOffset(timezone), 'UTC');

  return `${tz} (${offset})`;
}

export default formatShiftTimezone;
