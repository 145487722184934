import { updateCSRF } from '../../redux/actions';

export type FetchDetails = {
  csrfToken: string | null | undefined;
};

const CSRFTokenHeader = 'X-CSRF-Token';

function getCSRFToken(response: Response): string | null | undefined {
  if (response && response.headers) {
    return response.headers.get(CSRFTokenHeader);
  }
  return null;
}

type Headers = {
  [key: string]: string;
};

// Just to make it more clear what we're passing in to the interceptor
type ReduxStore = any;

function computeFetchHeaders(state: FetchDetails): Headers {
  const res: Record<string, any> = {};
  if (state.csrfToken) {
    res[CSRFTokenHeader] = state.csrfToken;
  }
  return res;
}

type FetchOptions = {
  method: string;
  credentials?: 'omit' | 'same-origin' | 'include';
  body?: any;
  signal?: AbortSignal;
  headers?: Headers;
  mode?: 'cors' | 'no-cors' | 'same-origin';
};

// sendFetch acts just like the browser based fetch method, but adds some Assembled specific
// details (like CSRF tokens and credentials). This method should be used for all non-GET
// requests. To add fetchDetails, simply connect the redux router and pull the fetchDetails
// state down into your component.
//
//   Example usage:
//     fetch("/api/something",
//       {
//         method: "POST",
//         body: JSON.stringify({}),
//       },
//       this.props.fetchDetails,
//     );
//
// Background: sendFetch is meant to be the one stop shop that adds any headers or additional
// tokens needed by the backend. For example, we pass CSRF tokens down to the server through
// this method so that we can prevent malicious CSRF attacks, without every component needing
// to know about CSRF.
function sendFetch(url: string, opts: FetchOptions, fetchDetails?: FetchDetails): Promise<Response> {
  const finalOpts = { ...opts };
  if (fetchDetails) {
    const fetchHeaders = computeFetchHeaders(fetchDetails);
    finalOpts.headers = { ...finalOpts.headers, ...fetchHeaders };
  }
  if (!finalOpts.credentials) {
    finalOpts.credentials = 'same-origin';
  }
  return fetch(url, finalOpts);
}

// Unfortunately opts is generic because we don't have the type for the actual fetch (we do, but can't import)
type fetchFunc = (url: string, opts: any) => Promise<Response>;

function CSRFInterceptor(originalFetch: fetchFunc, store: ReduxStore): fetchFunc {
  return (url: string, opts: FetchOptions) => {
    // Only interfere with API requests
    if (!url.startsWith('/api/')) {
      return originalFetch(url, opts);
    }
    const { fetchDetails } = store.getState();
    const newOpts = { ...opts };
    if (fetchDetails) {
      const fetchHeaders = computeFetchHeaders(fetchDetails);
      newOpts.headers = { ...newOpts.headers, ...fetchHeaders };
    }

    if (!newOpts.credentials) {
      newOpts.credentials = 'same-origin';
    }

    // @ts-expect-error - TS2683 - 'this' implicitly has type 'any' because it does not have a type annotation.
    return originalFetch.call(this, url, newOpts).then(
      (response: Response) => {
        const token = getCSRFToken(response);
        if (token) {
          store.dispatch(updateCSRF(token));
        }
        return response;
      },
      (err: Response) => {
        const token = getCSRFToken(err);
        if (token) {
          store.dispatch(updateCSRF(token));
        }
        return Promise.reject(err);
      }
    );
  };
}

export { getCSRFToken, sendFetch, CSRFInterceptor };
