import { InternalLink, Side, SmallText, Tooltip } from 'assemblage';
import { capitalize, isEmpty, sortBy, trim, truncate } from 'lodash-es';
import moment from 'moment';
import pluralize from 'pluralize';
import * as React from 'react';
import classNames from 'classnames';

import type {
  Channel,
  EventTypes,
  FilterType,
  FilterValue,
  FilterValueName,
  MetricUnit,
  Moment,
  Optimization,
  Person,
  SelectOption,
  ShiftPattern,
  SimpleUser,
  User,
} from '../../models';
import { CHANNEL_OPTS } from '../FilterConstants';
import ShiftSummary from '../settings/shift_patterns/components/ShiftSummary';
import { formatMoment, formatMomentAsMonthDay, formatMomentAsTime } from './DateTimes';
import { formatFloat, formatPercentage } from './Format';
import HoverableText from './HoverableText';
import { CSV_CUSTOM_METRIC_HEADER_LABEL } from '../reports/TeamPerformance/MetricTypes';
import { ContactInput } from '../../models';

import styles from './FormatTyped.module.css';

export const FILTER_NAME_TRUNCATE_LENGTH = 25;
export const FILTER_LIST_TRUNCATE_LENGTH = 75;

function formatChannel(channel?: Channel | null, emptyDisplay: string = ''): string {
  if (!channel) {
    return emptyDisplay;
  }

  const channelOpt = CHANNEL_OPTS[channel];
  if (channelOpt) {
    return channelOpt.label;
  }
  return formatUnderscoreCase(channel);
}

function formatChannels(channels?: Array<Channel> | null, emptyDisplay: React.ReactNode = null): React.ReactNode {
  if (!channels || channels.length === 0) {
    return emptyDisplay;
  }

  const names = channels.map((channel) => formatChannel(channel));

  return <>{names.join(', ')}</>;
}

function formatChannelsList(channels: Array<Channel>): string {
  if (!channels || channels.length === 0) return '';

  const formattedChannels = channels.map((channel) => formatChannel(channel)) || [];
  const lastChannel = formattedChannels.pop();

  if (!formattedChannels.length && lastChannel) {
    return lastChannel;
  }

  return `${formattedChannels.join(', ')} and ${lastChannel}`;
}

function formatChannelQueue(
  channel: Channel | null | undefined,
  queue: string | null | undefined,
  queueValues: Array<FilterValue>
): string {
  if (!queue) {
    return formatChannel(channel);
  }

  const queueName = formatFilterValue(queue, queueValues);
  return `${formatChannel(channel)} ▸ ${queueName}`;
}

function formatFilter(filter?: FilterValueName | null, emptyDisplay: React.ReactNode = null): React.ReactNode {
  if (!filter) {
    return emptyDisplay;
  }
  return trim(filter.name);
}

function formatFilters(filters?: Array<FilterValue> | null, emptyDisplay: React.ReactNode = null): React.ReactNode {
  if (!filters || filters.length === 0) {
    return emptyDisplay;
  }

  const names = sortBy(filters, ['name']).map(formatFilter);
  const joined = names.reduce((prev, curr, i) => [prev, <span key={i}>,&nbsp;&nbsp;</span>, curr]);
  return <>{joined}</>;
}

function formatTruncatedFilters(filters?: Array<FilterValueName> | null, emptyDisplay: string = ''): string {
  if (!filters || filters.length === 0) {
    return emptyDisplay;
  }

  return truncate(
    filters
      .filter((filter) => filter) // lol
      .map((filter) => {
        const formattedFilter = trim(filter.name);
        return truncate(formattedFilter, { length: FILTER_NAME_TRUNCATE_LENGTH, omission: '…' });
      })
      .join(', '),
    { length: FILTER_LIST_TRUNCATE_LENGTH, omission: '…' }
  );
}

function formatFiltersAsString(filters?: Array<FilterValueName> | null, emptyDisplay: string = ''): string {
  if (!filters || filters.length === 0) {
    return emptyDisplay;
  }

  return filters.map(formatFilter).join(', ');
}

function formatShiftPattern(
  shiftPatternID?: string,
  shiftPatterns?: Array<ShiftPattern>,
  optimizations?: Array<Optimization>,
  eventTypes?: EventTypes,
  emptyDisplay: string = '',
  position: Side = 'bottom',
  hasStickyZIndex: boolean = false
): React.ReactNode {
  const shiftPattern = shiftPatterns?.find((sp) => sp.external_id === shiftPatternID);

  if (shiftPattern) {
    const shiftPatternName = shiftPattern.name;
    return (
      <Tooltip
        className={classNames(styles.shiftPatternTooltip, {
          [styles.stickyZIndex]: hasStickyZIndex,
        })}
        side={position}
        content={<ShiftSummary shiftPattern={shiftPattern} />}
      >
        <HoverableText>{truncate(shiftPatternName, { length: 30 })}</HoverableText>
      </Tooltip>
    );
  }

  return <span>{emptyDisplay}</span>;
}

function formatClause([eventName, duration]: [string, number]): string {
  const formattedDuration = formatDurationSeconds(duration, { hideSeconds: true, shorten: true });
  return `${formattedDuration} ${eventName}`;
}

function formatEventDurationsSecondsAsString(durationByEventName: Record<string, number>): string {
  if (isEmpty(durationByEventName)) {
    return '';
  }
  const durationByEventNameArray = Object.entries(durationByEventName);

  const clauses = durationByEventNameArray.map(formatClause);

  if (clauses.length === 1) {
    return clauses[0];
  }
  if (clauses.length === 2) {
    return clauses.join(' and ');
  }
  const lastClause = clauses.pop();
  return `${clauses.join(', ')}, and ${lastClause}`;
}

// Remove last letter from `filterType`: Queues --> Queue
function formatFilterType(filterType: FilterType): string {
  return filterType.substr(0, filterType.length - 1);
}

function formatChannelLinked(
  channel: Channel | null | undefined,
  updateFilterParams: (arg1: { [key: string]: any }) => void,
  emptyDisplay: React.ReactNode = null
): React.ReactNode {
  if (!channel) {
    return emptyDisplay;
  }

  const onClick = () => {
    updateFilterParams({ channel });
  };

  return (
    <InternalLink key={channel} onClick={onClick} to={`/staffing/timeline?channel=${channel}`}>
      {capitalize(channel)}
    </InternalLink>
  );
}

function formatChannelsLinked(
  channels: Array<Channel> | null | undefined,
  updateFilterParams: (arg1: { [key: string]: any }) => void,
  emptyDisplay: React.ReactNode = null
): React.ReactNode {
  if (!channels || channels.length === 0) {
    return emptyDisplay;
  }
  const displays = channels
    .map((ch) => formatChannelLinked(ch, updateFilterParams))
    .reduce((prev, curr) => [prev, ', ', curr]);
  return <span>{displays}</span>;
}

function formatFilterLinked(
  filter: FilterValue | null | undefined,
  key: string,
  updateFilterParams: (arg1: { [key: string]: any }) => void,
  emptyDisplay: React.ReactNode = null
): React.ReactNode {
  if (!filter) {
    return emptyDisplay;
  }

  const onClick = () => {
    updateFilterParams({ [key]: filter.value });
  };

  return (
    <InternalLink onClick={onClick} key={filter.name} to={`/staffing/timeline?${key}=${filter.value}`}>
      {filter.name}
    </InternalLink>
  );
}

function formatFiltersLinked(
  filters: Array<FilterValue> | null | undefined,
  key: string,
  updateFilterParams: (arg1: { [key: string]: any }) => void,
  emptyDisplay: React.ReactNode = null
): React.ReactNode {
  if (!filters || filters.length === 0) {
    return emptyDisplay;
  }

  const names = filters
    .map((f) => formatFilterLinked(f, key, updateFilterParams))
    .reduce((prev, curr) => [prev, ', ', curr]);
  return <span>{names}</span>;
}

function formatFilterValue(value: string | null | undefined, allValues: Array<FilterValue>): string {
  const filter = allValues.find((v) => v.value === value);
  if (!filter || !filter.name) {
    return value ? capitalize(value) : '';
  }
  return filter.name;
}

function formatUnderscoreCase(s: string): string {
  return capitalize(s.replace(/_/g, ' '));
}

function formatUnderscore(s: string): string {
  return s.replace(/_/g, ' ');
}

function formatSnakeCase(s: string): string {
  return s.toLowerCase().replace(/\s/g, '_');
}

function localizedLastEdited({
  lastEditedAt,
  lastEditedBy,
  editedText,
  hideEmail = false,
  locale = 'en',
}: {
  lastEditedAt: Moment;
  lastEditedBy: SimpleUser | null | undefined;
  editedText?: string | React.ReactNode;
  hideEmail?: boolean;
  locale?: string;
}): React.ReactNode {
  return (
    <span>
      {editedText || 'Last edited '}
      <span lang={locale || 'en'}>{lastEditedAt.fromNow()}</span>
      {lastEditedBy ? ` by ${formatUser(lastEditedBy, hideEmail)}` : ''}
    </span>
  );
}

function formatLastEdited(
  lastEditedAt: Moment,
  lastEditedBy: SimpleUser | null | undefined,
  editedText?: string,
  hideEmail: boolean = false
): string {
  const components = [editedText || 'Last edited'];
  components.push(lastEditedAt.fromNow());

  if (lastEditedBy) {
    components.push(`by ${formatUser(lastEditedBy, hideEmail)}`);
  }
  return components.join(' ');
}

function formatUser(user: SimpleUser | User, hideEmail: boolean = false): string {
  if (!user) {
    return '-';
  }
  if (hideEmail) {
    return `${user.first_name} ${user.last_name}`;
  }
  return `${user.first_name} ${user.last_name} <${user.email}>`;
}

function formatPerson(person: Person, hideEmail: boolean = false): string {
  if (hideEmail) {
    return `${person.first_name} ${person.last_name}`;
  }
  return `${person.first_name} ${person.last_name} <${person.email}>`;
}

function formatMomentRelative(t: Moment): string {
  return t.fromNow();
}

function formatChangeInline(changedBy: SimpleUser | null | undefined, changedAt: string): string {
  if (!changedBy) {
    return formatMomentRelative(moment(changedAt));
  }
  return `${formatMomentRelative(moment(changedAt))} by ${formatUser(changedBy)}`;
}

function formatChangeMoment(
  changedBy: SimpleUser | null | undefined,
  changedAt: Moment,
  useSmallText?: boolean
): React.ReactNode {
  if (!changedBy) {
    return formatMomentRelative(changedAt);
  }
  return (
    <>
      <div>{formatMomentRelative(changedAt)}</div>
      {useSmallText ? (
        <SmallText className={styles.overflowCellContent} color="--text-weakest">
          {formatUser(changedBy)}
        </SmallText>
      ) : (
        <div>{formatUser(changedBy)}</div>
      )}
    </>
  );
}

function formatChange(
  changedBy: SimpleUser | null | undefined,
  changedAt: string,
  useSmallText?: boolean
): React.ReactNode {
  if (!changedBy) {
    return formatMomentRelative(moment(changedAt));
  }
  return (
    <>
      <div>{formatMomentRelative(moment(changedAt))}</div>
      {useSmallText ? (
        <SmallText className={styles.overflowCellContent} color="--text-weakest">
          {formatUser(changedBy)}
        </SmallText>
      ) : (
        <div>{formatUser(changedBy)}</div>
      )}
    </>
  );
}

export type FormatDurationOptions = {
  hideSeconds?: boolean;
  hideMinutes?: boolean;
  shorten?: boolean;
  truncate?: boolean;
  verboseLabels?: boolean;
  showDays?: boolean;
};

function addDurationGroup(
  durationGroups: Array<string>,
  unitValue: number,
  label: string,
  opts?: FormatDurationOptions | null
): Array<string> {
  if (opts && opts.truncate && durationGroups.length > 0) {
    return durationGroups;
  }
  if (opts && opts.shorten && durationGroups.length > 0 && unitValue === 0) {
    return durationGroups;
  }
  durationGroups.push(`${unitValue}${label}`);
  return durationGroups;
}

// Takes in a numeric seconds value and returns a formatted result (e.g. 1m 25s)
function formatDurationSeconds(nSeconds: number | null, opts?: FormatDurationOptions | null): string {
  if (nSeconds == null) return '-';

  let prefix = '';
  if (nSeconds < 0) {
    nSeconds = Math.abs(nSeconds);
    prefix = '-';
  }

  const duration = moment.duration(nSeconds, 'seconds');
  let durationGroups: Array<string> = [];

  if (opts && opts.showDays && nSeconds >= 86400) {
    const days = Math.floor(duration.asDays());
    const daysLabel = opts && opts.verboseLabels ? ` ${pluralize('day', days)}` : 'd';
    durationGroups = addDurationGroup(durationGroups, days, daysLabel, opts);
    duration.subtract(moment.duration(days, 'days'));
  }

  const hours = opts && opts.showDays ? Math.floor(duration.asHours()) % 24 : Math.floor(duration.asHours());
  const hoursLabel = opts && opts.verboseLabels ? ` ${pluralize('hour', hours)}` : 'h';
  if (opts && opts.hideMinutes) {
    // If we're not including minutes (and seconds), then hours is the last group and
    // we should always show it
    durationGroups = addDurationGroup(durationGroups, hours, hoursLabel, opts);
    return prefix + durationGroups.join(' ');
  }

  // If we're including minutes, then hours won't be the last group, so we treat them
  // as normal, but always show either minutes or seconds
  if (nSeconds >= 60 * 60) {
    durationGroups = addDurationGroup(durationGroups, hours, hoursLabel, opts);
  }

  const minutes = duration.minutes();
  const minutesLabel = opts && opts.verboseLabels ? ` ${pluralize('minute', minutes)}` : 'm';
  if (opts && opts.hideSeconds) {
    // If we're not including seconds, then minutes is the last group and
    // we should always show it
    durationGroups = addDurationGroup(durationGroups, minutes, minutesLabel, opts);
    return prefix + durationGroups.join(' ');
  }

  // If we're including seconds, then seconds is the last group and we should
  // treat minutes as a normal duration group, but always show seconds
  if (nSeconds >= 60) {
    durationGroups = addDurationGroup(durationGroups, minutes, minutesLabel, opts);
  }

  const seconds = duration.seconds();
  const secondsLabel = opts && opts.verboseLabels ? ` ${pluralize('second', minutes)}` : 's';
  durationGroups = addDurationGroup(durationGroups, seconds, secondsLabel, opts);

  return prefix + durationGroups.join(' ');
}

// alternative to dddd M/D
function formatDayOfWeekMonthDay(m: Moment, includeYear: boolean = false): string {
  return `${formatMoment(m, 'dddd')} ${formatMomentAsMonthDay(m, includeYear)}`;
}

// alternative to h:mma
function formatTime(m: Moment): string {
  return formatMomentAsTime(m, false).toLowerCase();
}

function formatTimeRangeExact(
  start: Moment,
  end: Moment,
  excludeDayOfWeek: boolean = false,
  includeYear: boolean = false
): string {
  const formatDateFunction = (m: Moment) => {
    if (excludeDayOfWeek) {
      return formatMomentAsMonthDay(m, includeYear);
    }

    return formatDayOfWeekMonthDay(m, includeYear);
  };

  if (end.isSame(start, 'day')) {
    // E.g. Friday 3/12 10:00am – 11:00am
    const startDisplay = `${formatDateFunction(start)} ${formatTime(start)}`;
    const endDisplay = formatTime(end);
    return `${startDisplay} – ${endDisplay}`;
  }
  // E.g. Friday 3/12 10:00am – Saturday 3/13 11:00am
  const startDisplay = `${formatDateFunction(start)} ${formatTime(start)}`;
  const endDisplay = `${formatDateFunction(end)} ${formatTime(end)}`;
  return `${startDisplay} – ${endDisplay}`;
}

function formatTimeRange(
  start: Moment,
  end: Moment,
  excludeDayOfWeek: boolean = false,
  includeYear: boolean = false
): string {
  const formatDateFunction = (m: Moment) => {
    if (excludeDayOfWeek) {
      return formatMomentAsMonthDay(m, includeYear);
    }

    return formatDayOfWeekMonthDay(m, includeYear);
  };

  // DayofWeek in the following examples is optional
  if (end.clone().subtract(1, 'day').isSame(start) && start.isSame(start.clone().startOf('day'))) {
    // E.g. Friday 3/12
    const dayDisplay = formatDateFunction(start);
    return dayDisplay;
  }

  if (end.hours() === start.hours() && start.isSame(start.clone().startOf('day'))) {
    // E.g. Friday 3/12 – Saturday 3/13
    const startDisplay = formatDateFunction(start);
    const endDisplay = formatDateFunction(end.clone().subtract(1, 'day'));
    return `${startDisplay} – ${endDisplay}`;
  }

  return formatTimeRangeExact(start, end, excludeDayOfWeek, includeYear);
}

function formatShortTime(time: Moment): string {
  return formatMomentAsTime(time, false).toLowerCase();
}

// the key is the metric id, the value is a function to convert from the numeric value to the formatted string
export type CustomMetricFormatter = {
  [key: string]: (arg1: number) => string;
};

function formatCSVMetricHeaderLabel(metricLabel: string): string {
  const customLabel = CSV_CUSTOM_METRIC_HEADER_LABEL && metricLabel && CSV_CUSTOM_METRIC_HEADER_LABEL[metricLabel];
  if (customLabel) {
    return customLabel;
  }
  return metricLabel;
}

function formatCSVMetricValue(
  metricValue: number,
  metricUnit: MetricUnit,
  metricId?: string | null,
  customFormatter?: CustomMetricFormatter | null,
  CSVCustomFormatter?: CustomMetricFormatter | null,
  toFixed: number = 2,
  commaSeparator: boolean = false
): string {
  const formatter = CSVCustomFormatter && metricId && CSVCustomFormatter[metricId];
  if (formatter) {
    return formatter(metricValue);
  }
  return formatMetricValue(metricValue, metricUnit, metricId, customFormatter, toFixed, commaSeparator);
}

function formatMetricValue(
  metricValue: number,
  metricUnit: MetricUnit,
  metricId?: string | null,
  customFormatter?: CustomMetricFormatter | null,
  toFixed: number = 2,
  commaSeparator: boolean = false
): string {
  const formatter = customFormatter && metricId && customFormatter[metricId];
  if (formatter) {
    return formatter(metricValue);
  }

  switch (metricUnit) {
    case 'integer':
      return commaSeparator ? Math.floor(metricValue).toLocaleString() : Math.floor(metricValue).toString();
    case 'seconds': {
      const opts = { showDays: true, hideSeconds: false };
      if (metricValue > 86400) {
        opts.hideSeconds = true;
      }
      return formatDurationSeconds(metricValue, opts);
    }
    case 'minutes':
      return formatDurationSeconds(metricValue, { hideSeconds: true });
    case 'hours':
      return formatDurationSeconds(metricValue, { hideMinutes: true });
    case 'percentage':
      return formatPercentage(metricValue, toFixed);
    case 'float':
      return formatFloat(metricValue, toFixed, null, commaSeparator);
  }
}

function formatSelectOption<T extends string>(keywordType: T, ktOptions: Array<SelectOption<T>>): string {
  const opt = ktOptions.find((ktOpt: SelectOption<T>) => {
    return ktOpt.value === keywordType;
  });
  if (!opt) {
    return capitalize(keywordType);
  }
  return opt.label;
}

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/PluralRules/PluralRules
const pluralRules = new Intl.PluralRules('en-US', { type: 'ordinal' });
const pluralSuffixes = new Map([
  ['one', 'st'],
  ['two', 'nd'],
  ['few', 'rd'],
  ['other', 'th'],
]);

const formatOrdinals = (n: number) => {
  const rule = pluralRules.select(n);
  const suffix = pluralSuffixes.get(rule);
  return `${n}${suffix}`;
};

function formatContactInput(contactInput: ContactInput | undefined): string {
  if (!contactInput) {
    return '';
  }
  switch (contactInput) {
    case 'case_volume':
      return 'case volume';
    case 'units_of_work':
      return 'unit of work';
  }
}

function formatContactInputUnit(contactInput: ContactInput, plural?: boolean): string {
  switch (contactInput) {
    case 'case_volume':
      return plural ? 'cases' : 'case';
    case 'units_of_work':
      return plural ? 'units of work' : 'unit of work';
  }
}

export {
  formatContactInput,
  formatContactInputUnit,
  formatChange,
  formatChangeMoment,
  formatChangeInline,
  formatChannel,
  formatChannelQueue,
  formatChannels,
  formatChannelsLinked,
  formatChannelsList,
  formatClause,
  formatDayOfWeekMonthDay,
  formatDurationSeconds,
  formatEventDurationsSecondsAsString,
  formatLastEdited,
  formatTruncatedFilters,
  formatFilter,
  formatFilterLinked,
  formatFilterType,
  formatFilterValue,
  formatFilters,
  formatFiltersAsString,
  formatFiltersLinked,
  formatCSVMetricHeaderLabel,
  formatCSVMetricValue,
  formatMetricValue,
  formatMomentRelative,
  formatOrdinals,
  formatPerson,
  formatSelectOption,
  formatShiftPattern,
  formatShortTime,
  formatSnakeCase,
  formatTimeRange,
  formatTimeRangeExact,
  formatUnderscore,
  formatUnderscoreCase,
  formatUser,
  localizedLastEdited,
};
