import type { Moment } from 'moment';
import moment from 'moment/moment';

// Locale-aware formats for moment.js
// See: https://momentjs.com/docs/#/displaying/format/

const MINUTES_IN_HOUR = 60;
const SECONDS_IN_MINUTE = 60;
export const SECONDS_IN_HOUR = MINUTES_IN_HOUR * SECONDS_IN_MINUTE;
export const SECONDS_IN_DAY = 24 * SECONDS_IN_HOUR;
export const HOURS_IN_DAY = 24;
export const NUM_DAYS_PER_WEEK = 7;

export type LocaleAwareMomentFormat =
  | 'LT'
  | 'MMM'
  | 'ddd'
  | 'dddd'
  | 'l'
  | 'll' // custom combinations for legacy consistency
  | 'llll'
  | 'ddd LT'
  | 'ddd l'
  | 'ddd l LT'
  | 'dddd l'
  | 'l LT'
  | 'MMM D'
  | 'MMM D LT'
  | 'MMMM YYYY'
  | '[Week of] l'
  | 'MMM D, YYYY LTS'
  | 'LLLL';

function formatMoment(m: Moment, format: LocaleAwareMomentFormat): string {
  return m.format(format);
}

function formatMomentsAsDateRange(start: Moment, end: Moment): string {
  const sameDay: boolean = start.isSame(end, 'day');
  const formatStartDate = (m: Moment) =>
    sameDay ? formatMomentAsTime(m, false) : `${formatMomentAsTime(m, false)} ${formatMoment(m, 'll')}`;
  return `${formatStartDate(start)} to ${formatMomentAsTime(end, false)} ${formatMoment(end, 'll')}`;
}

// Locale-aware alternative to MM/DD/YYYY hh:mma
function formatMomentAsDateTime(m: Moment, excludeMinute: boolean): string {
  return `${formatMoment(m, 'l')} ${formatMomentAsTime(m, excludeMinute)}`;
}

// Locale-aware alternative to M/D or MM/DD
function formatMomentAsMonthDay(m: Moment, includeYear: boolean = false): string {
  /* We want the browser to format the date because it has locale-aware methods,
     but our time zone code elsewhere relies on Moment's time zone data, which may
     differ from the browser in exceptional cases (one has newer TZ data).

     So we're creating a date, setting the UTC month and day, and then using a UTC
     formatting option to create the localized string. */
  const opts: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'numeric',
    timeZone: 'UTC',
  };
  if (includeYear) {
    opts.year = 'numeric';
  }

  const d = new Date(Date.UTC(m.year(), m.month(), m.date(), 12, 0));
  return d.toLocaleDateString(m.locale(), opts);
}

// Locale-aware alternative to hh:mmA. Use .toLowerCase for hh:mma. Pass
// excludeMinute=true to get only hours.
function formatMomentAsTime(m: Moment, excludeMinute: boolean): string {
  /* We want the browser to format the time because it has locale-aware methods,
     but our time zone code elsewhere relies on Moment's time zone data, which may
     differ from the browser in exceptional cases (one has newer TZ data).

     So we're creating a date, setting the UTC hour and minute, and then using a UTC
     formatting option to create the localized string. */
  const opts: Intl.DateTimeFormatOptions = { hour: 'numeric', timeZone: 'UTC' };
  if (!excludeMinute) {
    opts.minute = 'numeric';
  }

  const d = new Date();
  d.setUTCHours(m.hour());
  d.setUTCMinutes(m.minutes());
  return d.toLocaleTimeString(m.locale(), opts).replace(' ', '');
}

function computeDaysOfWeek(time: Moment): Array<Moment> {
  let start = time.clone().startOf('week');
  const end = start.clone().add(1, 'week');

  const days: Array<Moment> = [];
  while (start.isBefore(end)) {
    days.push(start);
    start = start.clone().add(1, 'day');
  }

  return days;
}

function isCompleteTime(timeString: string): boolean {
  const trimmed = timeString.replace(/\s/g, '');
  return (
    // 1-9:mm am and 1-9:mm pm
    /^[1-9](:[0-5][0-9])?[ap]/i.test(trimmed) ||
    // 10-12:mm am and 10-12:mm pm
    /^1[0-2](:[0-5][0-9])?[ap]/i.test(trimmed) ||
    // 0:mm, 00:mm, 13-19:mm, 20-24:mm
    /^(0|00|1[3-9]|2[0-4]):[0-5][0-9]/.test(trimmed)
  );
}

const roundOffToNearestMinute = (date: Moment, minute: number) => {
  const coefficient = 60 * minute;
  return moment.unix(Math.round(date.unix() / coefficient) * coefficient);
};

function formatDurationInHoursAndMinutes(numSeconds: number): string {
  const numHours = Math.floor(numSeconds / SECONDS_IN_HOUR);
  const numMinutes = Math.floor((numSeconds % SECONDS_IN_HOUR) / SECONDS_IN_MINUTE);

  let durationLabel = '';
  if (numHours > 0) {
    durationLabel += `${numHours}h `;
  }
  if (numMinutes > 0) {
    durationLabel += `${numMinutes}m`;
  }

  return durationLabel.trim();
}

export {
  computeDaysOfWeek,
  formatDurationInHoursAndMinutes,
  formatMoment,
  formatMomentAsDateTime,
  formatMomentAsMonthDay,
  formatMomentAsTime,
  formatMomentsAsDateRange,
  isCompleteTime,
  roundOffToNearestMinute,
};
