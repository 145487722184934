import moment, { Moment } from 'moment';
import * as React from 'react';
import { Badge } from 'assemblage';
import { useSelector } from 'react-redux';

import { computeTimezoneOffset } from '../../../utils/Timezone';
import UserManager from '../../../utils/UserManager';
import {
  convertShiftsToShiftRecords,
  computeShiftDurationSummary,
  formatDuration,
  getTimeRangeString,
  mergeContiguousDaysOfWeek,
} from '../utils/dates';
import { DEFAULT_SHIFTS } from '../utils/constants';
import { ShiftPattern } from '../../../../models';
import formatShiftTimezone from '../utils/timezones';
import type { ReduxState } from '../../../../redux/models';

import styles from './ShiftSummary.module.css';

export default function ShiftSummary({ shiftPattern }: { shiftPattern: ShiftPattern }) {
  const { restricted_sites: restrictedSites } = shiftPattern;
  const shiftRecords = convertShiftsToShiftRecords(shiftPattern.shifts);

  let timeLocaleFormat: string = 'h:mma';
  let timezoneOffset: number = 0;
  if (UserManager?.user?.locale) {
    timeLocaleFormat = moment.localeData(UserManager.locale()).longDateFormat('LT');
    timezoneOffset = computeTimezoneOffset(UserManager.user.timezone);
  }

  const shiftTimes: Array<{ start: Moment; end: Moment; days: Array<string> }> = [];
  Object.entries(shiftRecords).forEach(([day, shift]) => {
    const { startTime, endTime } = shift;

    // Fetch the day details so that we have access to its short label.
    const dayDetails = DEFAULT_SHIFTS.find((defaultShift) => defaultShift.value === day);
    if (!dayDetails) {
      return;
    }
    const dayLabel = dayDetails.shortLabel;

    // Check if we've already encountered this time range before.
    const existingShiftTimeIndex = shiftTimes.findIndex(
      (existingShiftTime) =>
        getTimeRangeString(existingShiftTime.start, existingShiftTime.end, timeLocaleFormat) ===
        getTimeRangeString(startTime, endTime, timeLocaleFormat)
    );

    // If we haven't encountered this time range, create a new entry for it.
    if (existingShiftTimeIndex === -1) {
      shiftTimes.push({
        start: startTime,
        end: endTime,
        days: [dayLabel],
      });
    }
    // If we've encountered this time range, add the current day to the
    // existing entry's list of dates.
    else {
      shiftTimes[existingShiftTimeIndex].days.push(dayLabel);
    }
  });

  const exclusionString =
    shiftPattern.excluded_duration_seconds <= 0
      ? ''
      : `${formatDuration(shiftPattern.excluded_duration_seconds)} exclusion per shift`;

  const filterOptions = useSelector((state: ReduxState) => state.filterOptions);
  // Find the restrictedSite from filterOptions.sites that matches the value in restrictedSites
  // The inner `map` call is just out of paranoia since there is the possibility for both filterOptions.sites and restrictedSites to be string[]
  const restrictedSiteDetails = filterOptions.sites.find(
    (site) => restrictedSites.includes(site.value) && restrictedSites.map((currentSite) => currentSite === site.value)
  );

  return (
    <div className={styles.summary}>
      <div>
        {shiftTimes.map((shiftTime) => {
          const durationString = computeShiftDurationSummary(shiftTime.start, shiftTime.end);
          const daysLabel = mergeContiguousDaysOfWeek(shiftTime.days);

          return (
            <div key={daysLabel} className={styles.summaryRow}>
              <div className={styles.days}>{daysLabel}</div>
              <div className={styles.times}>
                {getTimeRangeString(shiftTime.start, shiftTime.end, timeLocaleFormat)}
                {shiftTime.end.day() !== shiftTime.start.day() ? <sup>+1</sup> : null} ({durationString})
              </div>
            </div>
          );
        })}
      </div>
      {exclusionString && <div className={styles.exclusionsRow}>{exclusionString}</div>}
      <div className={styles.timezone}>{formatShiftTimezone(shiftPattern.timezone)}</div>
      {restrictedSiteDetails && <Badge className={styles.badge} labelText={`${restrictedSiteDetails.name} only`} />}
    </div>
  );
}
