// This is commented out so that it doesn't flood the devtools console during regular development
// To use, uncomment the next line: and follow instructions at https://github.com/welldone-software/why-did-you-render
// import "../wdyr"; // <--- needs to be first import according to https://github.com/welldone-software/why-did-you-render
import * as ReactDOM from 'react-dom';
import * as React from 'react';
import { Provider, ReactReduxContext } from 'react-redux';
import * as Sentry from '@sentry/react';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';
import { parse, stringify } from 'query-string';
import { createBrowserHistory } from 'history';
import { Switch } from 'react-router';
import { ConnectedRouter } from 'connected-react-router';
import { defaults } from 'react-chartjs-2';
// Required for any charts to use annotations:
import 'chartjs-plugin-annotation';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { HelmetProvider } from 'react-helmet-async';
import { Tooltip, NotificationProvider } from 'assemblage';
// Assemblage design system
import 'assemblage/dist/styles.css';

import { buildStore, initCSRF } from './redux';
import UserManager from './components/utils/UserManager';
import { BannerContainer } from './components/Banner';
import { FullPageLoading } from './components/Loading/Loading';
import { InternalError } from './components/errors/InternalError';
import { initSentry, initGoogleAnalytics } from './components/utils/ThirdPartyPlugins';
import { CSRFInterceptor } from './components/utils/SendFetch';
import NotificationContainer from './components/Notification';
import { textColor } from './styles/Colors';
import { getColorMode, initTheme, saveColorMode } from './components/utils/ColorMode';
import { queryClient } from './queryClient';
import Favicon from './components/Favicon';
import { Platform } from './models';
import { PlatformInfo } from './components/utils/Platforms';
import { isCal } from './components/utils/Cal';
import { handleShopifyAuth } from './AuthCallbacks';
import { PendoResourceCenterTrigger } from './components/PendoResourceCenterTrigger/PendoResourceCenterTrigger';

// App constants
import './css/constants.css';
// Global pendo styles
import './css/pendo.css';

const CalRoutes = React.lazy(() => import(/* webpackChunkName: "Cal" */ './CalRoutes'));
const AssembledRoutes = React.lazy(() => import(/* webpackChunkName: "Assembled" */ './AssembledRoutes'));

if (isCal() && getColorMode() !== 'light') {
  saveColorMode('light');
}

initTheme();
initSentry();
initGoogleAnalytics();

const browserHistory = createBrowserHistory({});
const store = buildStore(browserHistory);

// NOTE: this is replacing the global 'fetch' function. This should generally be ok, but if you're
// seeing headers appearing from no where (in particular credentials and x-csrf-token, it's worth
// checking CSRFInterceptor
// Also, this is _after initCSRF, otherwise we'd be trying to send csrf tokens while getting csrf tokens.
// sad times.
async function initCSRFAndUserManager() {
  await initCSRF(store);
  // @ts-expect-error - TS2630 - Cannot assign to 'fetch' because it is a function.
  // eslint-disable-next-line no-global-assign
  fetch = CSRFInterceptor(fetch, store);
  UserManager.setHistory(browserHistory);
  await handleShopifyAuth();
  await UserManager.handleMagicLink();
  UserManager.setStore(store);
  UserManager.checkSession();
}

initCSRFAndUserManager();

export const PlatformInfoContext: React.Context<Partial<Record<Platform, PlatformInfo>>> = React.createContext({});

// set default font for charts
defaults.global.defaultFontFamily = 'IBM Plex Sans';
defaults.global.defaultFontColor = textColor;

const appElement = document.getElementById('app');
if (appElement !== null) {
  ReactDOM.render(
    // @ts-expect-error - TS2769 - No overload matches this call.
    <Provider store={store} context={ReactReduxContext}>
      {isCal() && (
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=0" />
      )}
      <ConnectedRouter history={browserHistory} context={ReactReduxContext}>
        <HelmetProvider>
          <QueryParamProvider
            adapter={ReactRouter5Adapter}
            options={{
              searchStringToObject: parse,
              objectToSearchString: stringify,
            }}
          >
            <QueryClientProvider client={queryClient}>
              <NotificationProvider>
                <Tooltip.Provider>
                  <Sentry.ErrorBoundary
                    fallback={<InternalError />}
                    beforeCapture={(scope) => {
                      scope.setLevel('fatal');
                    }}
                  >
                    <React.Suspense fallback={<FullPageLoading fullWidth />}>
                      <Favicon />
                      <PendoResourceCenterTrigger />
                      <Switch>{isCal() ? <CalRoutes /> : <AssembledRoutes />}</Switch>
                    </React.Suspense>
                  </Sentry.ErrorBoundary>
                </Tooltip.Provider>
              </NotificationProvider>
              <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
            </QueryClientProvider>
            <BannerContainer />
            <NotificationContainer />
          </QueryParamProvider>
        </HelmetProvider>
      </ConnectedRouter>
    </Provider>,
    appElement
  );
}
