import type { ActionType } from '../actions';
import type { EventTypes, CustomEventType } from '../../models';

export type UpdateCustomEventTypesAction = {
  type: ActionType;
  allCustomEventTypes: Array<CustomEventType>;
};

type EventTypesAction = UpdateCustomEventTypesAction;

function computeEventTypes(allCustomEventTypes: Array<CustomEventType>): EventTypes {
  const activeCustomEventTypes: Array<CustomEventType> = [];
  const activeCustomEventTypesMap: Record<string, any> = {};
  const allCustomEventTypesMap: Record<string, any> = {};

  allCustomEventTypes.forEach((v) => {
    if (v.active) {
      activeCustomEventTypes.push(v);
      activeCustomEventTypesMap[v.value] = v;
    }
    allCustomEventTypesMap[v.value] = v;
  });

  return {
    activeCustomEventTypes,
    activeCustomEventTypesMap,
    allCustomEventTypes,
    allCustomEventTypesMap,
  };
}

const initialState: EventTypes = {
  activeCustomEventTypes: [],
  activeCustomEventTypesMap: {},
  allCustomEventTypes: [],
  allCustomEventTypesMap: {},
};

const EventTypesReducer = (state: EventTypes = initialState, action: EventTypesAction): EventTypes => {
  switch (action.type) {
    case 'UPDATE_CUSTOM_EVENT_TYPES': {
      const updatedState = computeEventTypes(action.allCustomEventTypes);
      return { ...state, ...updatedState };
    }
    default:
      return state;
  }
};

export { computeEventTypes, EventTypesReducer };
