import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';

import { ReduxState } from '../redux/models';
import UserManager from './utils/UserManager';
import grayFaviconImgUrl from '../media/logos/assembled/favicon-gray.png';
import orangeFaviconImgUrl from '../media/logos/assembled/favicon-orange.png';
import multigreenOnTransparentFaviconImgUrl from '../media/logos/assembled/favicon-multigreen-on-transparent.png';
import redFaviconImgUrl from '../media/logos/assembled/favicon-red.png';

function Favicon() {
  const isDemo = useSelector((state: ReduxState) => state.demo.demo);

  const [isImpersonated, setIsImpersonated] = React.useState(() => !!UserManager.isImpersonated());

  React.useEffect(() => {
    const idx = UserManager.bindOnChange(() => {
      setIsImpersonated(!!UserManager.isImpersonated());
    });

    return () => {
      UserManager.unbindOnChangeFn(idx);
    };
  }, []);

  const favicon = React.useMemo(() => {
    switch (process.env.BUILD_ENV) {
      case 'development':
        return grayFaviconImgUrl;
      case 'staging':
        return orangeFaviconImgUrl;
      case 'production':
      default:
        if (isImpersonated && !isDemo) {
          return redFaviconImgUrl;
        }
        return multigreenOnTransparentFaviconImgUrl;
    }
  }, [isDemo, isImpersonated]);

  return (
    <Helmet>
      <link rel="icon" type="image/png" href={favicon} />
    </Helmet>
  );
}

export default Favicon;
