import moment from 'moment';
import { values } from 'lodash-es';

import { Ranges } from '../constants/RangeConstants';
import UserManager from './utils/UserManager';
import type {
  AgentStaffable,
  Channel,
  FilterOptions,
  IntervalUnit,
  SelectOption,
  StaffingTimeWindow,
  AgentStatus,
  LookbackPeriod,
} from '../models';
import type { Range } from '../constants/RangeConstants';
import type { EventType } from './reports/scorecard/utils';

export type ChannelOpt = {
  value: Channel;
  label: string;
  group: 'Aggregate' | 'Individual';
};

const ALL_CHANNELS: Channel[] = ['all', 'back_office', 'phone', 'chat', 'email', 'sms', 'social'];
const ALL_CHANNELS_WITHOUT_ALL: Channel[] = ['back_office', 'phone', 'chat', 'email', 'sms', 'social'];

export const CHANNEL_OPTS: Readonly<Record<Channel, ChannelOpt>> = {
  all: { value: 'all', label: 'All channels', group: 'Aggregate' },
  back_office: { value: 'back_office', label: 'Back office', group: 'Individual' },
  email: { value: 'email', label: 'Email', group: 'Individual' },
  chat: { value: 'chat', label: 'Chat', group: 'Individual' },
  phone: { value: 'phone', label: 'Phone', group: 'Individual' },
  sms: { value: 'sms', label: 'SMS', group: 'Individual' },
  social: { value: 'social', label: 'Social', group: 'Individual' },
};
export const FILTER_LABELS: Record<
  keyof Omit<FilterOptions, 'sites_restricted' | 'teams_restricted' | 'queues_restricted'>,
  string
> = {
  sites: 'Site',
  channels: 'Channel',
  queues: 'Queue',
  teams: 'Team',
  skills: 'Skill',
  schedules: 'Schedule',
  restricted_sites: 'Restricted sites',
  templates: 'Template',
  allocation_statuses: 'Allocation status',
  roles: 'Role',
  agent_statuses: 'Agent status',
  agent_staffable_options: 'Agent availability',
  shift_patterns: 'Shift patterns',
  event_type_classification: 'Classification',
};

const agentStatuses: Array<SelectOption<AgentStatus>> = [
  { value: 'active', label: 'Active' },
  { value: 'deleted', label: 'Deactivated' },
];

const agentStaffableOptions: Array<SelectOption<AgentStaffable>> = [
  { value: 'true', label: 'Can be scheduled' },
  { value: 'false', label: 'Cannot be scheduled' },
];

const eventClassificationOptions: Array<SelectOption<EventType>> = [
  { label: 'Default', value: 'default' },
  { label: 'Productive', value: 'productive' },
  { label: 'Time off', value: 'timeoff' },
];

const lookbackPeriodOptions: Array<SelectOption<LookbackPeriod>> = [
  {
    value: 'lookback_period_today',
    label: 'Today',
  },
  {
    value: 'lookback_period_15_minutes',
    label: '15m',
  },
  {
    value: 'lookback_period_30_minutes',
    label: '30m',
  },
  {
    value: 'lookback_period_1_hour',
    label: '1hr',
  },
  {
    value: 'lookback_period_3_hours',
    label: '3hrs',
  },
  {
    value: 'lookback_period_6_hours',
    label: '6hrs',
  },
  {
    value: 'lookback_period_24_hours',
    label: '24hrs',
  },
];

// Converts map of supported channel options into an array of options
function getNonCompanySpecificChannelOpts(removeAggregates?: boolean): Array<ChannelOpt> {
  let opts = values(CHANNEL_OPTS);
  if (removeAggregates) {
    opts = opts.filter((opt) => opt && opt.group !== 'Aggregate');
  }
  return opts;
}

function channelOpts(removeAggregates?: boolean, channelOptions?: Array<Channel>): Array<ChannelOpt> {
  const activeChannels = channelOptions || UserManager.channels();
  if (!activeChannels) {
    // We can't use Object.values() because of flow (see https://github.com/facebook/flow/issues/2221)
    // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ readonly all: { readonly value: "all"; readonly label: "All channels"; readonly group: "Aggregate"; }; readonly back_office: { readonly value: "back_office"; readonly label: "Back Office"; readonly group: "Individual"; }; ... 4 more ...; readonly social: { ...; }; }'.
    return Object.keys(CHANNEL_OPTS).map((key) => CHANNEL_OPTS[key]);
  }

  // TODO: Should perhaps cache this in redux
  let opts: Array<ChannelOpt> = [];
  if (activeChannels.length > 1 && !activeChannels.includes('all')) {
    opts.push(CHANNEL_OPTS.all);
  }

  activeChannels.forEach((ch) => opts.push(CHANNEL_OPTS[ch]));
  if (removeAggregates) {
    opts = opts.filter((opt) => opt.group !== 'Aggregate');
  }
  return opts;
}

const isAggregateChannel = (channel?: Channel | null) => {
  if (channel === 'all') {
    return true;
  }
  return false;
};

// Converts a range into a unit consumable by the `moment` library in
// methods such as `add`, `subtract`, and `isSame`
//
// TODO: Should deprecate this, it does not respect baseInterval()
const unitForRange = (range?: Range | null): IntervalUnit => {
  let unit: IntervalUnit;
  switch (range) {
    case Ranges.day:
      unit = 'day';
      break;
    case Ranges.week:
      unit = 'week';
      break;
    case Ranges.month:
      unit = 'month';
      break;
    default:
      console.warn(`Unknown range type, falling back to 'day'`, range);
      unit = 'day';
  }
  return unit;
};

function applyTimeWindow(date: any, hour: number, minute: number, timezone: string): any {
  // Convert hours, minutes from timezone --> user timezone
  const localOffset = date.utcOffset();
  const timeWindowOffset = date.clone().tz(timezone).utcOffset();
  const minWithOffset = ((localOffset - timeWindowOffset) % 60) + minute;
  const hourWithOffset = Math.floor((localOffset - timeWindowOffset) / 60) + hour;

  const updatedDate = date.clone().set('hour', hourWithOffset).set('minute', minWithOffset);
  const res = moment.unix(updatedDate.unix());
  return res;
}

const startEndParams = (
  range: Range | null | undefined,
  date: any,
  timeWindow: StaffingTimeWindow | null = null,
  duration: number = 1
) => {
  const unit = unitForRange(range);

  let start = date.clone().startOf(unit);
  let end = start.clone().add(duration, unit);
  if (timeWindow && range === Ranges.day) {
    const origStart = start;
    start = applyTimeWindow(origStart, timeWindow.start_hour, timeWindow.start_minute, timeWindow.timezone);
    end = applyTimeWindow(origStart, timeWindow.end_hour, timeWindow.end_minute, timeWindow.timezone);
  } else if (range === Ranges.month) {
    start = start.clone().startOf('week');
    end = end.clone().endOf('week');
  }

  return { start: start.unix(), end: end.unix() };
};

// Converts a range into an integer interval (in seconds) consumable by the
// Assembled backend
//
// TODO: Should deprecate this, it does not respect baseInterval()
const intervalForRange = (range?: Range | null) => {
  let interval;
  switch (range) {
    case Ranges.day:
      interval = 3600;
      break;
    case Ranges.custom:
    case Ranges.week:
      interval = 3600;
      break;
    case Ranges.month:
      interval = 86400;
      break;
    default:
      console.warn(`Unknown range type, falling back to 'day'`, range);
      interval = 3600;
  }
  return interval;
};

export {
  ALL_CHANNELS_WITHOUT_ALL,
  ALL_CHANNELS,
  getNonCompanySpecificChannelOpts,
  isAggregateChannel,
  channelOpts,
  intervalForRange,
  applyTimeWindow,
  startEndParams,
  unitForRange,
  lookbackPeriodOptions,
  agentStatuses,
  agentStaffableOptions,
  eventClassificationOptions,
  Ranges,
};
