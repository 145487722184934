import type { ActionType } from '../actions';
import type { StoredEventChangeSummary } from '../../components/timeline/EventChanges';

export type UndoEventHistoryState = {
  showModal: boolean;
  eventChangeSummary: StoredEventChangeSummary | null | undefined;
};

export type UndoEventHistoryAction = {
  type: ActionType;
  update: Partial<UndoEventHistoryState>;
};

const initialState: UndoEventHistoryState = {
  showModal: false,
  eventChangeSummary: null,
};

const UndoEventHistoryReducer = (
  state: UndoEventHistoryState = initialState,
  action: UndoEventHistoryAction
): UndoEventHistoryState => {
  switch (action.type) {
    case 'UPDATE_UNDO_EVENT_HISTORY':
      return { ...state, ...action.update };
    default:
      return state;
  }
};

export { UndoEventHistoryReducer };
