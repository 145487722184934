import UserManager from '../utils/UserManager';
import { FeatureFlags } from '../../models';

export function shouldShowTrigger(featureFlags: FeatureFlags): boolean {
  if (UserManager.hasRole('basic') && featureFlags['pendo.show_resource_center_role_basic']) {
    return true;
  }
  if (UserManager.hasRoleStandard() && featureFlags['pendo.show_resource_center_role_standard']) {
    return true;
  }
  if (UserManager.hasRole('lead') && featureFlags['pendo.show_resource_center_role_lead']) {
    return true;
  }
  if (UserManager.hasRole('manager') && featureFlags['pendo.show_resource_center_role_manager']) {
    return true;
  }
  if (UserManager.hasRole('admin') && featureFlags['pendo.show_resource_center_role_admin']) {
    return true;
  }
  return false;
}
