import type { DemoState } from '../../models';

const LOCAL_STORAGE_DEMO_KEY = 'demo';

export type UpdateDemoStateAction = {
  type: 'UPDATE_DEMO_STATE';
  demo: boolean;
};

const initialState: DemoState = {
  demo: localStorage.getItem(LOCAL_STORAGE_DEMO_KEY) === 'true',
};

const DemoReducer = (state: DemoState = initialState, action: UpdateDemoStateAction): DemoState => {
  switch (action.type) {
    case 'UPDATE_DEMO_STATE': {
      localStorage.setItem(LOCAL_STORAGE_DEMO_KEY, action.demo.toString());
      const updatedState = {
        demo: action.demo,
      } as const;
      return { ...state, ...updatedState };
    }
    default:
      return state;
  }
};

export { DemoReducer };
