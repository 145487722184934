import * as React from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'assemblage';

import UserManager from '../utils/UserManager';
import type { ReduxState } from '../../redux/models';
import { shouldShowTrigger } from './permissions';

import styles from './PendoResourceCenterTrigger.module.css';

const PENDO_RESOURCE_CENTER_TRIGGER_ID = 'pendo-resource-center-trigger';

/**
 * A component to trigger the [Pendo Resource Center](https://app.pendo.io/resource-center/), which looks for the specific `PENDO_RESOURCE_CENTER_TRIGGER_ID` as the trigger.
 * The activation for this is defined inside the settings of the [Pendo Resource Center](https://app.pendo.io/resource-center/).
 *
 * More information can be found in the [Pendo documentation](https://help.pendo.io/resources/docs/guide/pendo-resource-center).
 */
export function PendoResourceCenterTrigger() {
  const featureFlags = useSelector((state: ReduxState) => state.featureFlags);
  const isLoggedIn = UserManager.isLoggedIn();

  if (isLoggedIn && shouldShowTrigger(featureFlags)) {
    return (
      <Button variant="primary" className={styles.root} id={PENDO_RESOURCE_CENTER_TRIGGER_ID}>
        Get started
      </Button>
    );
  }

  return null;
}
