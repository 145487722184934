import { QueryClient, QueryCache, MutationCache } from '@tanstack/react-query';

import { isError, reportError } from './components/utils/Errors';

// default options https://tanstack.com/query/v4/docs/guides/important-defaults
// we set a query cache and mutation cache for global error reporting
export const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (err) => {
      if (isError(err)) {
        reportError(err);
      }
    },
  }),
  mutationCache: new MutationCache({
    onError: (err) => {
      if (isError(err)) {
        reportError(err);
      }
    },
  }),
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});
