import type { ActionType } from '../actions';

type ViewSettingsState = {
  fullscreen: boolean;
  showCommandBar: boolean;
};

const initialState: ViewSettingsState = {
  fullscreen: false,
  showCommandBar: false,
};

type ViewSettingsAction = FullscreenModeAction;

type FullscreenModeAction = {
  type: ActionType;
};

const ViewSettingsReducer = (
  state: ViewSettingsState = initialState,
  action: ViewSettingsAction
): ViewSettingsState => {
  switch (action.type) {
    case 'TOGGLE_FULLSCREEN_MODE':
      return { ...state, fullscreen: !state.fullscreen };
    case 'EXIT_FULLSCREEN_MODE':
      return { ...state, fullscreen: false };
    case 'TOGGLE_COMMAND_BAR':
      return { ...state, showCommandBar: !state.showCommandBar };
    case 'CLOSE_COMMAND_BAR':
      return { ...state, showCommandBar: false };
    default:
      return state;
  }
};

export type { ViewSettingsState, FullscreenModeAction };
export { ViewSettingsReducer };
