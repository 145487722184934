import ReactSelect, { components as SelectComponents } from 'react-select';
import ReactSelectAsync from 'react-select/async';
import ReactSelectCreatable from 'react-select/creatable';

export { createFilter } from 'react-select';

/**
 * @deprecated Use an Assemblage component instead:
 * - [Select](https://assemblage.gokome.com/?path=/docs/stable-inputs-select--docs)
 * - [MultiSelect](https://assemblage.gokome.com/?path=/docs/stable-inputs-multiselect--docs)
 * - [GroupSelect](https://assemblage.gokome.com/?path=/docs/stable-inputs-groupselect--docs)
 */
export const components = SelectComponents;

/**
 * @deprecated Use an Assemblage component instead:
 * - [Select](https://assemblage.gokome.com/?path=/docs/stable-inputs-select--docs)
 * - [MultiSelect](https://assemblage.gokome.com/?path=/docs/stable-inputs-multiselect--docs)
 * - [GroupSelect](https://assemblage.gokome.com/?path=/docs/stable-inputs-groupselect--docs)
 */
export const Select = ReactSelect;

/**
 * @deprecated Use an Assemblage component instead:
 * - [Select](https://assemblage.gokome.com/?path=/docs/stable-inputs-select--docs)
 * - [MultiSelect](https://assemblage.gokome.com/?path=/docs/stable-inputs-multiselect--docs)
 * - [GroupSelect](https://assemblage.gokome.com/?path=/docs/stable-inputs-groupselect--docs)
 */
export const AsyncSelect = ReactSelectAsync;

/**
 * @deprecated Use an Assemblage component instead:
 * - [Select](https://assemblage.gokome.com/?path=/docs/stable-inputs-select--docs)
 * - [MultiSelect](https://assemblage.gokome.com/?path=/docs/stable-inputs-multiselect--docs)
 * - [GroupSelect](https://assemblage.gokome.com/?path=/docs/stable-inputs-groupselect--docs)
 */
export const CreatableSelect = ReactSelectCreatable;
