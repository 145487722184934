import * as React from 'react';
import { ExternalLink } from 'assemblage';

export function supportMailTo(): React.ReactElement {
  return (
    <ExternalLink target="_blank" href="mailto:support@assembled.com">
      support@assembled.com
    </ExternalLink>
  );
}
