import * as React from 'react';
import { Alert } from '@assembled/react-bootstrap';
import * as Sentry from '@sentry/react';

import { supportMailTo } from './Links';
import UserManager from './UserManager';

const MAX_FILE_SIZE = '20MB';

type ErrorMetadata = {
  errors: string[];
  requestID?: string | null;

  // for backwards compat, we allow arbitraray key value pairs
  [key: string]: any;
};

class CustomError extends Error {
  json?: ErrorMetadata;

  constructor(message: string, metadata?: ErrorMetadata) {
    super(message);

    this.name = 'CustomError';
    this.json = metadata;
  }
}

const wasUniquenessConstraintViolated = (errorMessage: string): boolean =>
  errorMessage.includes('pq: duplicate key value violates unique constraint');

const formatErrorMessage = (errorMessage: string): string => {
  if (wasUniquenessConstraintViolated(errorMessage)) {
    return 'Some unique constraints were violated. Please check your form fields to make sure they are unique';
  }

  return errorMessage;
};

type ErrorMessageAlertProps = {
  error: string;
  mainMessage?: string;
};

function ErrorMessageAlert(props: ErrorMessageAlertProps) {
  if (!props.error) {
    return null;
  }

  const mainMessage = props.mainMessage || 'Oops, there seem to be some problems';

  return (
    <div className="d-flex p-a-sm alert-error">
      <span className="icon icon-warning m-r-xs" />
      <h2 className="panel-title">
        <b>Error:</b> {mainMessage}
      </h2>
    </div>
  );
}

const handleFetchErrors = async function (response: Response): Promise<any> {
  const contentType = response.headers.get('content-type');

  if (response.ok) {
    if (contentType && contentType.indexOf('text/csv') !== -1) {
      // If the content type is a CSV, return as file blob instead of JSON
      return response.blob();
    }
    if (contentType && contentType.indexOf('application/zip') !== -1) {
      // If the content type is a ZIP, return as file blob instead of JSON
      return response.blob();
    }
    return response.json();
  }

  if (response.status === 401) {
    // this usually means the user has *already* been logged out, but try
    // to end the session just in case. don't use UserManager.logout() to
    // avoid potential infinite loop
    UserManager.unsetUser();
    fetch('/api/sessions/end', {
      method: 'POST',
      credentials: 'same-origin',
    }).catch((e) => console.warn(e));
  }

  const httpMsg = `${response.status} ${response.statusText}`;
  if (contentType && contentType.indexOf('application/json') !== -1) {
    const json = await response.json();
    let msg = httpMsg;
    if (json.errors && json.errors.length > 0) {
      msg = `${httpMsg}: ${json.errors.join(', ')}`;
    } else if (json.error) {
      msg = `${httpMsg}: ${json.error}`;
    } else if (json.message) {
      msg = `${httpMsg}: ${json.message}`;
    }
    throw new CustomError(msg, json);

    // Non-API responses, such as NGINX timeouts, won't be application/json
  } else if (response.status >= 500) {
    const text = 'Oops, something went wrong! Please contact support@assembled.com';
    const requestID = response.headers.get('X-Request-ID');

    throw new CustomError(`${httpMsg}: ${text}`, { errors: [text], requestID });
  } else if (response.status === 413) {
    // This limit is set in nginx.conf under client_max_body_size
    const text = `File is too large! Uploads cannot exceed ${MAX_FILE_SIZE}`;

    throw new CustomError(`${httpMsg}: ${text}`, { errors: [text] });
  } else if (response.status === 429) {
    const text = 'Too many requests. Please try again later.';
    throw new CustomError(`${httpMsg}: ${text}`, { errors: [text] });
  } else {
    const text = await response.text();
    const requestID = response.headers.get('X-Request-ID');
    throw new CustomError(`${httpMsg}: ${text}`, { errors: [text], requestID });
  }
};

const transformErrors = (e?: unknown): string[] => {
  if (!e) {
    return [];
  }

  if (!isError(e)) {
    return [];
  }

  if (isAbortError(e)) {
    return [];
  }

  if (isCustomError(e)) {
    return e.json?.errors || [];
  }
  return [e.message];
};

const reportError = (e: unknown) => {
  if (!isError(e)) {
    return;
  }

  if (isAbortError(e)) {
    return;
  }

  if (process.env.BUILD_ENV === 'production') {
    Sentry.captureException(e);
  } else {
    console.error(e);
  }
};

/**
 * @deprecated use handleFetchError. This is only for use inside class components.
 */
const catchErrors = function (this: React.Component, e: unknown, flag: string = 'isLoading') {
  if (!isError(e)) {
    return;
  }

  if (isAbortError(e)) {
    return;
  }

  reportError(e);

  let res: ErrorMetadata;

  if (isCustomError(e)) {
    res = { errors: e.json?.errors || [], [flag]: false, requestID: e.json?.requestID };
  } else {
    res = { errors: [e.message], [flag]: false };
  }

  this.setState(res);
};

function AlertNoAssociatedAgent() {
  return (
    <Alert bsStyle="danger">
      <span className="icon icon-warning" /> Your account is not staffable. Please make your account staffable if you
      would like to complete this action. If this issue persists, contact an administrator or {supportMailTo()} to
      resolve the issue.
    </Alert>
  );
}

/**
 * @deprecated use reportError. If you're using react-query, error reporting is handled globally already.
 */
function notify(msg: string, opts?: any) {
  if (process.env.BUILD_ENV === 'production') {
    Sentry.captureMessage(msg, opts);
  } else {
    console.warn(msg, opts);
  }
}

function isError(e: unknown): e is Error {
  return e instanceof Error;
}

function isAbortError(e: unknown) {
  return isError(e) && e.name === 'AbortError';
}

function isCustomError(e: unknown): e is CustomError {
  return isError(e) && e.name === 'CustomError';
}

export {
  AlertNoAssociatedAgent,
  catchErrors,
  handleFetchErrors,
  reportError,
  notify,
  isError,
  isAbortError,
  isCustomError,
  wasUniquenessConstraintViolated,
  ErrorMessageAlert,
  transformErrors,
  CustomError,
};
