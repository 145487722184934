import type { ActionType } from '../actions';
import type { Variables } from '../../models';
import { ChannelConfiguration } from '../../models';

const initialState = {
  variables: {},
  channelConfigurations: {},
  variablesLoaded: false,
  usingTestVariables: false,
} as const;

type VariablesAction = {
  type: ActionType;
  channelConfigurations: {
    [key: string]: ChannelConfiguration;
  };
  variables: {
    [key: string]: Variables;
  };
  usingTestVariables: boolean;
};

export type VariablesState = {
  channelConfigurations: {
    [key: string]: ChannelConfiguration;
  };
  variables: {
    [key: string]: Variables;
  };
  variablesLoaded: boolean;
  usingTestVariables: boolean;
};

const VariablesReducer = (state: VariablesState = initialState, action: VariablesAction) => {
  switch (action.type) {
    case 'UPDATE_VARIABLES':
      return {
        ...state,
        channelConfigurations: { ...state.channelConfigurations, ...action.channelConfigurations },
        variables: { ...state.variables, ...action.variables },
        variablesLoaded: true,
        usingTestVariables: action.usingTestVariables,
      };
    default:
      return state;
  }
};

export { VariablesReducer };
