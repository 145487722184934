import { getColor, ColorHue, ColorValue } from 'assemblage';

import { isLightMode } from '../components/utils/ColorMode';
import type { ColorSet, ColorTheme } from '../models';

export const grayBase = '#0F1014';
const grayDark = '#2C3142';
const grayLight = '#DADCE2';
const grayLighter = '#EAECF0';
const grayWarm500 = '#C5C4C1';
const grayWarm600 = '#DBDBD7';
export const grayWarm700 = '#ECECEA';
const grayWarm800 = '#F8F8F7';

export const overviewDarkTextColor = '#000000';
export const textColor = isLightMode ? grayDark : grayLight;
export const grayTextColor = isLightMode ? '#62677A' : '#93969F';
export const overviewDarkSecondaryTextColor = grayTextColor;
export const overviewLightTextColor = '#FFFFFF';
export const overviewLightSecondaryTextColor = grayTextColor;
export const lightGray = grayLight;

export const formBackgroundColor = isLightMode ? grayWarm700 : '#434857';
export const formFontColor = isLightMode ? grayBase : '#FFF';
export const formPlaceholderFontColor = '#CFD2DA';
export const selectPlaceholderFontColor = 'hsl(0,0%,70%)';
export const inputGroupAddonBackgroundColor = isLightMode ? grayWarm700 : '#4d5364';
export const inputGroupAddonBorderColor = isLightMode ? grayWarm600 : '#4d5364';
export const inputGroupAddonFontColor = isLightMode ? '#434857' : '#FFF';

// for general usage
export const bulkFormCellBackgroundColor = isLightMode ? grayLighter : '#262931';
export const linkFontColor = isLightMode ? '#1892BF' : '#1997c6';
export const darkLinkFontColor = '#13759a';
export const formWrapperBackgroundColor = '#2A2E36';
export const lightGrayBackgroundColor = isLightMode ? grayWarm800 : '#30343e';
export const infoCardDividerColor = isLightMode ? grayWarm600 : '#434857';
export const modalDividerBackgroundColor = '#E1E1E1';
export const modalHeaderBackgroundColor = isLightMode ? grayWarm600 : '#434857';
export const modalMenuOptionHoverColor = isLightMode ? grayWarm800 : '#252830';
export const modalMenuBackgroundColor = isLightMode ? '#FFFFFF' : '#30343e';
export const modalBackgroundColor = isLightMode ? '#FFFFFF' : formWrapperBackgroundColor;
export const modalTitleColor = isLightMode ? overviewDarkTextColor : overviewLightTextColor;
export const modalTextColor = isLightMode ? '#51586a' : formPlaceholderFontColor;
export const menuErrorBorderColor = '#A94442';
export const menuErrorBoxshadowColor = '#CE8483';
export const darkTextColor = '#2D2D2D';
export const tableHoverBackgroundColor = isLightMode ? '#FFFFFF' : '#434857';
export const darkModeBackgroundColor = '#252830';
export const mainBackgroundColor = isLightMode ? '#FFFFFF' : darkModeBackgroundColor;
export const navBackgroundColor = isLightMode ? grayWarm700 : grayBase;
export const skeletonLoaderColor = 'rgba(67,72,87,0.75)';
export const skeletonLoaderColorDarker = 'rgba(67,72,87,1)';
export const skeletonLoaderColorLighter = 'rgba(67,72,87,0.5)';
export const skeletonLoaderColorLightMode = 'hsla(226,12%,90%,0.5)';
export const menuGrayTextColor = 'rgb(81, 88, 106)';
export const blueButtonBackgroundColor = '#1CA8DD';
export const lightGrayBackground = grayLighter;
export const dangerTextColor = '#B80000';
export const todayLineColor = isLightMode ? '#1C2235' : '#FFFFFF';
export const wellBorderColor = isLightMode ? 'rgba(0, 0, 0, 0.4)' : 'rgba(255, 255, 255, 0.5)';
export const cardBackgroundColor = isLightMode ? '#FFFFFF' : '#30343e';
export const staffingTimelineMajorGridColor = isLightMode ? grayWarm600 : '#131417';
export const avocadoColor = '#7CBA72';

export const stickyTableBorderColor = isLightMode ? 'rgba(0, 0, 0, 0.08)' : '#1e2028';
export const stickyTableHoverBackgroundColor = isLightMode ? 'rgba(0, 0, 0, 0.05)' : '#2d323f';

// forecast graph colors
export const colorForecastBase = '#1ca8dd';
export const colorForecastOver = '#1bc98e';
export const colorForecastUnder = '#e64759';

// backlog graph colors
export const colorLapsedBacklog = '#E94E5D';
export const colorNewBacklog = '#FF8C5A';
export const colorReopened = '#86A6F9';
export const backlogGraphCrosshairLineColor = wellBorderColor;

// legacy colors from forecastPanel v1
export const COLOR_ACTUAL = '#8b6cff';
export const COLOR_FORECASTED = '#e64759';
export const COLOR_GREEN_GRAPH = '#1cc98e';
export const COLOR_YELLOW_GRAPH = '#ffb966';
export const COLOR_ORANGE_GRAPH = '#e57850';
export const LINE_GRAPH_COLORS = [
  COLOR_FORECASTED,
  COLOR_ACTUAL,
  COLOR_GREEN_GRAPH,
  COLOR_YELLOW_GRAPH,
  COLOR_ORANGE_GRAPH,
] as const;

// requirement table colors
export const colorAllocationOver = isLightMode ? 'var(--green-60)' : 'var(--green-40)';
export const colorAllocationUnder = isLightMode ? 'var(--orange-60)' : 'var(--orange-40)';

// for timeline
export const timelineSecondaryTickColor = isLightMode ? 'rgba(0, 0, 0, 0.05)' : '#2b2b2b';

export const selectedCardBorderColor = isLightMode ? '#000' : '#FFF';

// for Requirements components
export const requirementsBorderColor = isLightMode ? grayWarm700 : '#434857';

export const warningIconColor = '#FFBC00';

// For Overview V2
export type OverviewTheme = ColorSet & {
  favorable: ColorSet;
  warning: ColorSet;
  danger: ColorSet;
};

export const overviewThemes: Record<ColorTheme, OverviewTheme> = {
  dark: {
    backgroundColor: cardBackgroundColor,
    mainTextColor: overviewLightTextColor,
    secondaryTextColor: overviewLightSecondaryTextColor,
    favorable: {
      backgroundColor: avocadoColor,
      mainTextColor: overviewDarkTextColor,
      secondaryTextColor: overviewDarkTextColor,
    },
    warning: {
      backgroundColor: '#E26B46',
      mainTextColor: overviewDarkTextColor,
      secondaryTextColor: overviewDarkTextColor,
    },
    danger: {
      backgroundColor: '#D34949',
      mainTextColor: overviewDarkTextColor,
      secondaryTextColor: overviewDarkTextColor,
    },
  },
  light: {
    backgroundColor: cardBackgroundColor,
    mainTextColor: overviewDarkTextColor,
    secondaryTextColor: overviewDarkSecondaryTextColor,
    favorable: {
      backgroundColor: '#7CBA72',
      mainTextColor: overviewDarkTextColor,
      secondaryTextColor: overviewDarkTextColor,
    },
    warning: {
      backgroundColor: '#E26B46',
      mainTextColor: overviewDarkTextColor,
      secondaryTextColor: overviewDarkTextColor,
    },
    danger: {
      backgroundColor: '#D34949',
      mainTextColor: overviewDarkTextColor,
      secondaryTextColor: overviewDarkTextColor,
    },
  },
};

export const overviewAgentStateColors = {
  red: '#E54141',
  orange: '#F56B3F',
  yellow: '#EF9746',
  avocado: '#7CBA72',
  watermelon: '#3CA177',
  babyBlue: '#3AA8C0',
  blue: '#4E74D7',
  lavender: '#664FC2',
  purple: '#8C4DCC',
  pink: '#D44B8D',
  gray: '#676B79',
} as const;

export const chartAnnotationColors = {
  outlier: `${overviewAgentStateColors.blue}55`,
  potentialOutlier: `${overviewAgentStateColors.lavender}55`,
  adjustPositive: `${overviewAgentStateColors.avocado}55`,
  adjustNegative: `${overviewAgentStateColors.red}55`,
  neutral: `${overviewAgentStateColors.gray}55`,
} as const;

export const disabledOpacity = 0.3;

const BORDER_BRIGHTNESS_ADJUST = 0.35;
export const getValidHexColor = (hex: string | null): string => {
  if (!hex) return '000000';
  let validHex = String(hex).replace(/[^0-9a-f]/gi, '');
  validHex = validHex.padEnd(3, '0');
  if (validHex.length < 6) {
    validHex = `${validHex[0]}${validHex[0]}${validHex[1]}${validHex[1]}${validHex[2]}${validHex[2]}`;
  }
  return validHex;
};

const computeLuminance = (r: number, g: number, b: number): number => {
  // ITU-R BT.709-6
  // https://www.itu.int/dms_pubrec/itu-r/rec/bt/R-REC-BT.709-6-201506-I!!PDF-E.pdf
  // page no: 6 / luminance calculation
  return 0.2126 * r + 0.7152 * g + 0.0722 * b;
};

export const hexToRGB = (
  hex: string
): {
  r: number;
  g: number;
  b: number;
} => {
  const c = parseInt(hex, 16);
  return {
    r: (c >> 16) & 0xff,
    g: (c >> 8) & 0xff,
    b: (c >> 0) & 0xff,
  };
};

const decimalToHexValue = (decimal: number) => {
  let hex = Math.round(Math.min(Math.max(0, decimal), 255)).toString(16);
  while (hex.length < 2) {
    hex = `0${hex}`;
  }
  return hex;
};

export const getAdjustedBorderColor = (hex: string | null, forceTheme?: ColorTheme): string => {
  let lightMode = isLightMode;
  if (forceTheme) {
    lightMode = forceTheme === 'light';
  }
  const validHexColor = getValidHexColor(hex);
  let { r, g, b } = hexToRGB(validHexColor);
  const luminance = computeLuminance(r, g, b) / 255;

  if (lightMode) {
    if (luminance > 0.8) {
      return skeletonLoaderColorDarker;
    }
  } else {
    if (luminance < 0.2) {
      // since brightness * small brightness value = not bright enough
      // brightness * 0 (for black) is still 0 (black)
      return overviewAgentStateColors.gray;
    }
    return staffingTimelineMajorGridColor;
  }

  const adjustedBrightness = 1 - BORDER_BRIGHTNESS_ADJUST;
  r *= adjustedBrightness;
  g *= adjustedBrightness;
  b *= adjustedBrightness;

  return `#${decimalToHexValue(r)}${decimalToHexValue(g)}${decimalToHexValue(b)}`;
};

const colorHues = [
  ColorHue.Green,
  ColorHue.Red,
  ColorHue.Blue,
  ColorHue.Orange,
  ColorHue.Cyan,
  ColorHue.Gold,
  ColorHue.Magenta,
  ColorHue.Violet,
  ColorHue.Brick,
  ColorHue.Pine,
  ColorHue.CoolGray,
  ColorHue.WarmGray,
];

const colorValues: ColorValue[] = [50, 20, 80];

export function getAssemblageColorByIndex(index: number): string {
  // Note(Jason): If the index is more than the length of the colorValues * colorHues,
  // There's probably other issues with usability that should be addressed 🤔
  if (index >= colorValues.length * colorHues.length) {
    return '';
  }
  const hue = colorHues[index % colorHues.length];
  const value: ColorValue = colorValues[Math.floor(index / colorHues.length)];
  return getColor(hue, value);
}
