import { channelQueueKey } from './Keys';
import type { Channel, Variables, ChannelConfiguration } from '../../models';

export type NumericalVariableKey =
  | 'multiplier'
  | 'productivity'
  | 'unit_of_work_throughput'
  | 'shrinkage'
  | 'aht'
  | 'aht_case_volume'
  | 'aht_units_of_work'
  | 'concurrency'
  | 'concurrency_case_volume'
  | 'concurrency_units_of_work'
  | 'required_agents_override'
  | 'service_level_hours'
  | 'service_level_percent'
  | 'service_level_percent_case_volume'
  | 'service_level_percent_units_of_work'
  | 'service_level_seconds'
  | 'service_level_seconds_case_volume'
  | 'service_level_seconds_units_of_work'
  | 'reopens_multiplier'
  | 'minimum_staffing';

export type VariableKey = NumericalVariableKey | 'should_infer';

// (2020-01-19 ryan): This is different form VariableKey for legacy reasons...
export type VariableName =
  | 'forecast_multiplier'
  | 'agent_productivity'
  | 'shrinkage'
  | 'average_handle_time'
  | 'concurrency'
  | 'required_agents_override'
  | 'service_level_percent'
  | 'service_level_seconds'
  | 'reopens_multiplier'
  | 'minimum_staffing'
  | 'should_infer';

export type VariableDefinition = {
  variable: VariableName;
  defaultValue: string;
  controlLabel: string;
  name: string;
  tooltip: string;
  step?: number;
  type?: string;
  addon?: string;
  inferrable?: boolean;
};

const variableData = {
  multiplier: {
    variable: 'forecast_multiplier',
    defaultValue: '1.0',
    controlLabel: 'Forecast multiplier',
    name: 'forecast multiplier',
    tooltip: 'Factor by which forecasted contact volume is multiplied.',
  },
  productivity: {
    variable: 'agent_productivity',
    defaultValue: '8.0',
    controlLabel: 'Email productivity',
    name: 'productivity',
    tooltip: 'Average number of emails solved per agent per hour.',
    addon: 'solves/hour',
    inferrable: true,
  },
  unit_of_work_throughput: {
    variable: 'agent_productivity',
    defaultValue: '8.0',
    controlLabel: 'Unit of work throughput',
    name: 'unit of work throughput',
    tooltip: 'Average number of solves, transfers, and reassigns per agent per hour.',
    addon: 'units of work handled/hour',
    inferrable: true,
  },
  shrinkage: {
    variable: 'shrinkage',
    defaultValue: '0.0',
    controlLabel: 'Shrinkage',
    name: 'shrinkage',
    tooltip: 'Percentage of time lost during scheduled productive periods.',
    addon: '%',
  },
  aht: {
    variable: 'average_handle_time',
    defaultValue: '10.0',
    controlLabel: 'Average handle time (AHT)',
    name: 'average handle time',
    tooltip: 'Average duration in minutes of a single contact.',
    addon: 'mins',
    inferrable: true,
  },
  aht_case_volume: {
    variable: 'average_handle_time',
    defaultValue: '10.0',
    controlLabel: 'Agent average handle time (AHT) per case',
    name: 'average handle time',
    tooltip: 'Average duration in minutes of a single case.',
    addon: 'mins',
    inferrable: true,
  },
  aht_units_of_work: {
    variable: 'average_handle_time',
    defaultValue: '10.0',
    controlLabel: 'Agent average handle time (AHT) per unit of work',
    name: 'average handle time',
    tooltip: 'Average duration in minutes of a single unit of work.',
    addon: 'mins',
    inferrable: true,
  },
  concurrency: {
    variable: 'concurrency',
    defaultValue: '1.0',
    controlLabel: 'Concurrency',
    name: 'concurrency',
    addon: 'contacts/agent',
    tooltip: 'Number of concurrent contacts an agent can handle.',
  },
  concurrency_case_volume: {
    variable: 'concurrency',
    defaultValue: '1.0',
    controlLabel: 'Concurrency',
    name: 'concurrency',
    addon: 'cases/agent',
    tooltip: 'Number of concurrent cases an agent can handle.',
  },
  concurrency_units_of_work: {
    variable: 'concurrency',
    defaultValue: '1.0',
    controlLabel: 'Concurrency',
    name: 'concurrency',
    addon: 'units of work/agent',
    tooltip: 'Number of concurrent units of work an agent can handle.',
  },
  required_agents_override: {
    variable: 'required_agents_override',
    defaultValue: '0',
    controlLabel: 'Staffing target',
    name: 'staffing target',
    tooltip: 'Target number of agents staffed.',
    addon: 'agents',
  },
  service_level_percent: {
    variable: 'service_level_percent',
    defaultValue: '95',
    controlLabel: 'Service level',
    name: 'service level',
    tooltip: 'Percentage of contacts responded to within the designated threshold.',
    addon: '%',
    step: 1,
  },
  service_level_percent_case_volume: {
    variable: 'service_level_percent',
    defaultValue: '95',
    controlLabel: 'Service level',
    name: 'service level',
    tooltip: 'Percentage of cases responded to within the designated threshold.',
    addon: '%',
    step: 1,
  },
  service_level_percent_units_of_work: {
    variable: 'service_level_percent',
    defaultValue: '95',
    controlLabel: 'Service level',
    name: 'service level',
    tooltip: 'Percentage of units of work responded to within the designated threshold.',
    addon: '%',
    step: 1,
  },
  service_level_hours: {
    // TODO: Backend only supports seconds; the transformation from hours into
    // seconds is handled in an ad-hoc way by frontend.
    variable: 'service_level_seconds',
    defaultValue: '8',
    controlLabel: 'Target response time',
    name: 'target response time',
    tooltip: 'Target response time used to calculate Service Level.',
    addon: 'hours',
    step: 1,
  },
  service_level_seconds: {
    variable: 'service_level_seconds',
    defaultValue: '600',
    controlLabel: 'Target response time',
    name: 'target response time',
    tooltip: 'Target response time used to calculate Service Level.',
    addon: 'seconds',
    type: 'time',
    step: 1,
  },
  service_level_seconds_case_volume: {
    variable: 'service_level_seconds',
    defaultValue: '600',
    controlLabel: 'Response time per case',
    name: 'Response time per case',
    tooltip: 'Target response time used to calculate Service Level.',
    addon: 'seconds',
    type: 'time',
    step: 1,
  },
  service_level_seconds_units_of_work: {
    variable: 'service_level_seconds',
    defaultValue: '600',
    controlLabel: 'Response time per unit of work',
    name: 'Response time per unit of work',
    tooltip: 'Target response time used to calculate Service Level.',
    addon: 'seconds',
    type: 'time',
    step: 1,
  },
  reopens_multiplier: {
    variable: 'reopens_multiplier',
    defaultValue: '1.0',
    controlLabel: 'Reopens multiplier',
    name: 'reopens multiplier',
    tooltip: 'Factor by which reopens should be multiplied when calculating requirements.',
  },
  minimum_staffing: {
    variable: 'minimum_staffing',
    defaultValue: '0',
    controlLabel: 'Minimum staffing',
    name: 'minimum staffing',
    addon: 'FTEs',
    tooltip: 'The minimum number of people required to staff any interval with forecasted volume.',
    step: 1,
  },
  should_infer: {
    variable: 'should_infer',
    defaultValue: 'false',
    controlLabel: '', // We don't display this as a field in the modal, hence empty fields
    name: '',
    tooltip: '',
    type: 'boolean',
  },
} satisfies Record<VariableKey, VariableDefinition>;

function parseVariable(value: string | boolean | number): boolean | number {
  if (value === 'true') {
    return true;
  }
  if (value === 'false') {
    return false;
  }
  if (typeof value === 'boolean') {
    return value;
  }
  if (typeof value === 'number') {
    return value;
  }
  return parseFloat(value);
}

function defaultChannelConfiguration(channel: Channel, useUnitsOfWorkVolume: boolean): ChannelConfiguration {
  const contact_input = useUnitsOfWorkVolume ? 'units_of_work' : 'case_volume';
  return {
    channel,
    contact_input,
    active: true,
    updated_at: new Date().toISOString(),
  };
}
function initialChannelVariables(): Variables {
  return Object.keys(variableData).reduce((res, key) => {
    const d = variableData[key as keyof typeof variableData];
    // @ts-expect-error
    res[d.variable] = parseVariable(d.defaultValue);
    return res;
  }, {} as Variables);
}

function computeChannelConfigurationsList(channelConfigurations: {
  [key: string]: ChannelConfiguration;
}): ChannelConfiguration[] {
  return Object.values(channelConfigurations).filter((key: ChannelConfiguration) => {
    return key.channel !== 'all';
  });
}

function computeVariablesList(variables: { [key: string]: Variables }): Variables[] {
  return Object.keys(variables)
    .filter((key: string) => {
      return key !== 'default';
    })
    .map((channelQueueKey: string) => {
      return Object.values(variableData).reduce((res, varData) => {
        const variableName = varData.variable;
        // @ts-expect-error
        res[variableName] = variableValue(variableName, variables[channelQueueKey]);
        return res;
      }, variables[channelQueueKey]);
    });
}

function didChannelQueueVariablesChange(source1: Variables, source2: Variables): boolean {
  let changed = false;
  Object.keys(variableData).forEach((key) => {
    const varName = variableData[key as keyof typeof variableData].variable;
    if (source1[varName] !== source2[varName]) {
      changed = true;
    }
  });
  return changed;
}

function didVariablesChange(
  vars1: {
    [p: string]: Variables;
  },
  vars2: {
    [p: string]: Variables;
  }
): boolean {
  if (vars1 === vars2) {
    return false;
  }
  if ((!vars1 && vars2) || (vars1 && !vars2)) {
    return true;
  }
  if (!vars1 && !vars2) {
    return false;
  }

  if (Object.keys(vars1).length !== Object.keys(vars2).length) {
    return true;
  }
  const changedChannelQueue = Object.keys(vars1).find((channelQueueKey) => {
    if (!vars2.hasOwnProperty(channelQueueKey)) {
      return true;
    }
    if (didChannelQueueVariablesChange(vars1[channelQueueKey], vars2[channelQueueKey])) {
      return true;
    }
  });
  return !!changedChannelQueue;
}

// TODO(2020-01-19 ryan): The input here isn't Variables because of certain
// discrepancies:
//  - .service_level_hours is *not* in Variables but used as if it is elsewhere
function validateVariables(variables: any) {
  const errors: Array<
    | any
    | {
        message: string;
        param: string;
      }
  > = [];
  if (variables.service_level_percent < 0 || variables.service_level_percent > 100) {
    const message = `${variableData.service_level_percent.controlLabel} is expressed as a percent, so it must be between 0 and 100`;
    errors.push({ param: 'service_level_percent', message });
  }
  if (variables.average_handle_time <= 0) {
    const message = `${variableData.aht.controlLabel} must be greater than 0`;
    errors.push({ param: 'average_handle_time', message });
  }
  if (variables.service_level_hours < 0) {
    const message = `${variableData.service_level_hours.controlLabel} must be non-negative`;
    errors.push({ param: 'service_level_hours', message });
  }
  if (variables.service_level_seconds <= 0) {
    const message = `${variableData.service_level_seconds.controlLabel} must be greater than 0`;
    errors.push({ param: 'service_level_seconds', message });
  }
  if (variables.minimum_staffing < 0) {
    const message = `${variableData.minimum_staffing.controlLabel} must be non-negative`;
    errors.push({ param: 'minimum_staffing', message });
  }
  if (variables.shrinkage && (variables.shrinkage < 0 || variables.shrinkage > 100)) {
    const message = `${variableData.shrinkage.controlLabel} must be between 0 and 100`;
    errors.push({ param: 'shrinkage', message });
  }
  if (variables.concurrency && variables.concurrency <= 0) {
    const message = `${variableData.concurrency.controlLabel} must be greater than 0`;
    errors.push({ param: 'concurrency', message });
  }
  return errors;
}

function variableValue(variableName: VariableName, variables: Variables): any {
  // First look for the variable name in the variables object
  if (variables && Object.keys(variables).length > 0) {
    if (!variables.hasOwnProperty(variableName)) {
      console.warn(`Could not find variable name: ${variableName}`);
      return 0.0;
    }
    return parseVariable(variables[variableName]);
  }

  // Fall back to the default value of the variable
  //
  // TODO(2020-01-19 ryan): generic type of the result is due to how flow
  // handles Object.values. See: https://github.com/facebook/flow/issues/2221
  const varData: any = Object.values(variableData).find((varData) => {
    return varData.variable === variableName;
  });

  if (!varData) {
    console.warn(`Could not find default value for variable: ${variableName}`);
    return 0.0;
  }
  return parseVariable(varData.defaultValue);
}

function getChannelConfiguration(
  channelConfigurations: {
    [key: string]: ChannelConfiguration;
  },
  channel: Channel,
  defaultToUnitsOfWorkVolume: boolean
): ChannelConfiguration {
  if (channel in channelConfigurations) {
    return channelConfigurations[channel];
  }
  return defaultChannelConfiguration(channel, defaultToUnitsOfWorkVolume);
}

function getChannelQueueVariables(
  variables: {
    [key: string]: Variables;
  },
  channel: Channel,
  queue?: string | null
): Variables {
  const key = channelQueueKey(channel, queue);
  let channelQueueVariables;
  if (key in variables) {
    channelQueueVariables = variables[key];
  } else {
    channelQueueVariables = initialChannelVariables();
  }

  channelQueueVariables.channel = channel;
  channelQueueVariables.queue = queue;
  return channelQueueVariables;
}

function setChannelQueueVariables(
  variables: {
    [key: string]: Variables;
  },
  channel: Channel,
  queue: string | null | undefined,
  channelQueueVariables: Variables
) {
  const key = channelQueueKey(channel, queue);
  variables[key] = channelQueueVariables;
}

export {
  computeVariablesList,
  computeChannelConfigurationsList,
  didVariablesChange,
  initialChannelVariables,
  validateVariables,
  variableData,
  variableValue,
  getChannelConfiguration,
  getChannelQueueVariables,
  setChannelQueueVariables,
};
