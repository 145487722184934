import moment from 'moment';

import type { BasicDayState } from '../types/models';

export const SAME_TIMES_INDEX: number = 7;

export const YEAR: number = 1970;
export const MONTH: number = 0;

export const OUTBOUND_DATE_FORMAT: string = 'YYYY-MM-DDTHH:mm:ss[Z]';
export const MONDAY = moment('1970-01-05T00:00:00Z');

// The default start time for the first day of the week.
export const BASE_START_TIME = '1970-01-05T09:00:00Z';

// The default end time for the first day of the week.
export const BASE_END_TIME = '1970-01-05T17:00:00Z';

export const DEFAULT_SHIFTS: BasicDayState[] = [
  {
    value: 'sunday',
    label: 'Sun',
    shortLabel: 'Su',
    selected: false,
  },
  {
    value: 'monday',
    label: 'Mon',
    shortLabel: 'M',
    selected: true,
  },
  {
    value: 'tuesday',
    label: 'Tue',
    shortLabel: 'Tu',
    selected: true,
  },
  {
    value: 'wednesday',
    label: 'Wed',
    shortLabel: 'W',
    selected: true,
  },
  {
    value: 'thursday',
    label: 'Thu',
    shortLabel: 'Th',
    selected: true,
  },
  {
    value: 'friday',
    label: 'Fri',
    shortLabel: 'F',
    selected: true,
  },
  {
    value: 'saturday',
    label: 'Sat',
    shortLabel: 'Sa',
    selected: false,
  },
];

export const VALUE_TO_SHORT_LABEL: { [key: string]: string } = {
  monday: 'Mon',
  tuesday: 'Tue',
  wednesday: 'Wed',
  thursday: 'Thu',
  friday: 'Fri',
  saturday: 'Sat',
  sunday: 'Sun',
};

export const DAY_OPTIONS: { [key: number]: string } = {
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
  7: 'Sunday',
};

export const SHORT_DAY_OPTIONS: { [key: number]: string } = {
  1: 'Mon',
  2: 'Tue',
  3: 'Wed',
  4: 'Thu',
  5: 'Fri',
  6: 'Sat',
  7: 'Sun',
};

export const SUMMARY_DAY_OPTIONS: { [key: string]: string } = {
  Sunday: 'Su',
  Monday: 'M',
  Tuesday: 'Tu',
  Wednesday: 'W',
  Thursday: 'Th',
  Friday: 'F',
  Saturday: 'Sa',
};
