import type { ActionType } from '../actions';
import type { FetchDetails } from '../../components/utils/SendFetch';

export type UpdateFetchDetailsAction = {
  type: ActionType;
  csrfToken: string | null | undefined;
};

type FetchDetailsAction = UpdateFetchDetailsAction;

const initialState: FetchDetails = {
  csrfToken: null,
};

const FetchDetailsReducer = (state: FetchDetails = initialState, action: FetchDetailsAction): FetchDetails => {
  switch (action.type) {
    case 'UPDATE_CSRF':
      return { ...state, csrfToken: action.csrfToken };
    default:
      return state;
  }
};

export { FetchDetailsReducer };
