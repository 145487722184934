//
// FilterOptions represent possible values for each of the filters; e.g.
// FilterOptions.channels represents the possible channels for company N

import type { FilterOptions, FilterValue } from '../../models';

type InitFilterOptionsAction = {
  type: 'INIT_FILTER_OPTIONS';
  filters: any; // TODO: Encode,
  update: any; // TODO: Encode
};

type UpdateFilterOptionsAction = {
  type: 'UPDATE_FILTER_OPTIONS';
  filters: any; // TODO: Encode,
  update: any; // TODO: Encode
};

type CreateFilter = {
  type: 'CREATE_FILTER';
  filterType: string;
  filter: any;
};

type DeleteFilter = {
  type: 'DELETE_FILTER';
  filterType: string;
  filter: FilterValue;
};

type UpdateSpecificFilter = {
  type: 'UPDATE_SPECIFIC_FILTER';
  filterType: string;
  filter: FilterValue;
};
type FilterOptionsAction =
  | InitFilterOptionsAction
  | UpdateSpecificFilter
  | CreateFilter
  | DeleteFilter
  | UpdateFilterOptionsAction;

const initialState: FilterOptions = {
  channels: [],
  sites: [],
  queues: [],
  teams: [],
  skills: [],
  schedules: [],
  templates: [],
  roles: [],
  sites_restricted: false,
  teams_restricted: false,
  queues_restricted: false,
};

const bulkUpdateReducer = (state: FilterOptions, rawUpdate = {}, warn = true): FilterOptions => {
  const update = Object.keys(rawUpdate).reduce<Record<string, any>>((update, paramName) => {
    if (!initialState.hasOwnProperty(paramName)) {
      if (warn) {
        console.warn(`invalid filter option: ${paramName}`);
      }
      return update;
    }
    // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{}'.
    update[paramName] = rawUpdate[paramName];
    return update;
  }, {});

  return { ...state, ...update };
};

const updateSingleFilter = (state: FilterOptions, action: UpdateSpecificFilter): FilterOptions => {
  // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'FilterOptions'. | TS7006 - Parameter 'filter' implicitly has an 'any' type.
  const newList = state[action.filterType].map((filter) => {
    if (filter.id === action.filter.id) {
      return {
        ...filter,
        name: action.filter.name,
        parent_id: action.filter.parent_id,
        parent_value: action.filter.parent_value,
      };
    }
    return filter;
  });

  const res: Record<string, any> = {};
  res[action.filterType] = newList;
  return { ...state, ...res };
};

const createFilter = (state: FilterOptions, action: CreateFilter): FilterOptions => {
  // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'FilterOptions'.
  const newList = state[action.filterType].slice();
  newList.unshift(action.filter);

  const res: Record<string, any> = {};
  res[action.filterType] = newList;
  return { ...state, ...res };
};

const deleteFilter = (state: FilterOptions, action: DeleteFilter): FilterOptions => {
  // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'FilterOptions'.
  const newList = state[action.filterType].slice();
  for (let i = 0; i < newList.length; i++) {
    if (newList[i].id === action.filter.id) {
      newList.splice(i, 1);
      break;
    }
  }

  const res: Record<string, any> = {};
  res[action.filterType] = newList;
  return { ...state, ...res };
};

const FilterOptionsReducer = (state: FilterOptions = initialState, action: FilterOptionsAction): FilterOptions => {
  switch (action.type) {
    case 'INIT_FILTER_OPTIONS':
      return bulkUpdateReducer(state, action.filters);
    case 'UPDATE_FILTER_OPTIONS':
      return bulkUpdateReducer(state, action.update);
    case 'UPDATE_SPECIFIC_FILTER':
      return updateSingleFilter(state, action);
    case 'CREATE_FILTER':
      return createFilter(state, action);
    case 'DELETE_FILTER':
      return deleteFilter(state, action);
    default:
      return state;
  }
};

export { FilterOptionsReducer };
