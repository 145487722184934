import { setChannelQueueVariables } from '../../components/utils/Variables';
import type {
  AgentStateType,
  Channel,
  ChannelConfiguration,
  CompanyConfig,
  CustomEventType,
  FeatureFlags,
  FilterOptions,
  FilterParams,
  FilterType,
  FilterValue,
  GenerationSettings,
  IntegrationMappingPlatform,
  ObjectMapping,
  Person,
  StaffingSettingsUpdate,
  StaffingParameters,
  Variables,
} from '../../models';
import type { UpdateFetchDetailsAction } from '../reducers/FetchDetails';
import type { UpdateCustomEventTypesAction } from '../reducers/EventTypes';
import type { TemplatesReducerState, UpdateTemplatesAction } from '../reducers/Templates';
import type { UndoEventHistoryAction, UndoEventHistoryState } from '../reducers/UndoEventHistory';
import type { UpdateDemoStateAction } from '../reducers/Demo';
import type { FullscreenModeAction } from '../reducers/ViewSettings';
import type { IntegrationMappingsAction } from '../reducers/IntegrationMappings';
import type { PlatformInfo } from '../../components/utils/Platforms';
import type {
  UpdateAgentStateTypesAction,
  UpdateCompanyPlatformsAction,
  UpdateResolvedAgentStateTypesAction,
} from '../reducers/AgentStates';
import { channelQueueKey } from '../../components/utils/Keys';
// we use flow to type-check actions. to add a new action string, add it to
// the Union Type below, which should register it with the checker.
//
// see also: https://flow.org/en/docs/react/redux/
export type ActionType =
  | 'UPDATE_CSRF'
  | 'INIT_FILTER_OPTIONS'
  | 'LOCATION_CHANGE'
  | 'LOGOUT_USER'
  | 'SET_DEFAULT_FILTER_PARAMS'
  | 'UPDATE_CUSTOM_EVENT_TYPES'
  | 'UPDATE_VARIABLES'
  | 'UPDATE_FILTER_OPTIONS'
  | 'CREATE_FILTER'
  | 'DELETE_FILTER'
  | 'UPDATE_SPECIFIC_FILTER'
  | 'UPDATE_FILTER_PARAM'
  | 'UPDATE_FILTER_PARAMS'
  | 'UPDATE_STAFFING_SETTINGS'
  | 'UPDATE_GENERATION_SETTINGS'
  | 'UPDATE_FEATURE_FLAGS'
  | 'UPDATE_TEMPLATES'
  | 'UPDATE_UNDO_EVENT_HISTORY'
  | 'UPDATE_DEMO_STATE'
  | 'TOGGLE_FULLSCREEN_MODE'
  | 'EXIT_FULLSCREEN_MODE'
  | 'UPDATE_COMPANY_CONFIG'
  | 'UPDATE_ALL_ACTIVE_AGENTS'
  | 'TOGGLE_COMMAND_BAR'
  | 'CLOSE_COMMAND_BAR'
  | 'UPDATE_AGENT_STATE_TYPES'
  | 'UPDATE_RESOLVED_AGENT_STATE_TYPES'
  | 'UPDATE_COMPANY_PLATFORMS';

// TODO: Should encode indiviudal action objects as suggested by the docs
export type Action =
  | {
      type: ActionType;
    }
  | {
      type: 'UPDATE_VARIABLES';
      channelConfigurations?: Record<string, any>;
      variables?: Record<string, any>;
      usingTestVariables?: boolean;
    }
  | {
      type: 'INIT_FILTER_OPTIONS';
      filters: FilterOptions;
    }
  | {
      type: 'UPDATE_FEATURE_FLAGS';
      flags: FeatureFlags;
    };

export function updateMultipleVariables(staffingParameters: StaffingParameters, usingTestVariables: boolean): Action {
  const variables = (staffingParameters.variables || []).reduce<Record<string, any>>((res, channelQueueVariables) => {
    setChannelQueueVariables(res, channelQueueVariables.channel, channelQueueVariables.queue, channelQueueVariables);
    return res;
  }, {});

  const channelConfigurations = (staffingParameters.channel_configurations || []).reduce<Record<string, any>>(
    (res, channelConfiguration) => {
      const key = channelQueueKey(channelConfiguration.channel);
      res[key] = channelConfiguration;
      return res;
    },
    {}
  );

  return {
    type: 'UPDATE_VARIABLES',
    channelConfigurations,
    variables,
    usingTestVariables,
  };
}

export function updateVariables(
  channel: Channel,
  queue: string | null | undefined,
  variables: Variables,
  channelConfigurations: ChannelConfiguration
): Action {
  const variablesMap: Record<string, any> = {};
  setChannelQueueVariables(variablesMap, channel, queue, variables);

  const channelConfigurationsMap: Record<string, any> = {};
  channelConfigurationsMap[channelConfigurations.channel] = channelConfigurations;

  return {
    type: 'UPDATE_VARIABLES',
    channelConfigurations: channelConfigurationsMap,
    variables: variablesMap,
  };
}

export function setDefaultFilterParams(update: FilterParams): Action {
  return {
    type: 'SET_DEFAULT_FILTER_PARAMS',
    // @ts-expect-error - TS2322 - Type '{ type: "SET_DEFAULT_FILTER_PARAMS"; update: FilterParams; }' is not assignable to type 'Action'.
    update,
  };
}

export function updateFilterParam(paramName: string, value: unknown): Action {
  return {
    type: 'UPDATE_FILTER_PARAM',
    // @ts-expect-error - TS2322 - Type '{ type: "UPDATE_FILTER_PARAM"; paramName: string; value: unknown; }' is not assignable to type 'Action'.
    paramName,
    value,
  };
}

export function updateFilterParams(update: Partial<FilterParams>): Action {
  return {
    type: 'UPDATE_FILTER_PARAMS',
    // @ts-expect-error - TS2322 - Type '{ type: "UPDATE_FILTER_PARAMS"; update: FilterParams; }' is not assignable to type 'Action'.
    update,
  };
}

export function updateFilterOptions(update: Partial<FilterOptions>): Action {
  return {
    type: 'UPDATE_FILTER_OPTIONS',
    // @ts-expect-error - TS2322 - Type '{ type: "UPDATE_FILTER_OPTIONS"; update: Partial<FilterOptions>; }' is not assignable to type 'Action'.
    update,
  };
}

export function logoutUser(): Action {
  return { type: 'LOGOUT_USER' };
}

export function updateStaffingSettings(update: Partial<StaffingSettingsUpdate>): Action {
  return {
    type: 'UPDATE_STAFFING_SETTINGS',
    // @ts-expect-error - TS2322 - Type '{ type: "UPDATE_STAFFING_SETTINGS"; update: Partial<StaffingSettingsUpdate>; }' is not assignable to type 'Action'.
    update,
  };
}

export function updateGenerationSettings(update: Partial<GenerationSettings>): Action {
  return {
    type: 'UPDATE_GENERATION_SETTINGS',
    // @ts-expect-error - TS2322 - Type '{ type: "UPDATE_GENERATION_SETTINGS"; update: Partial<GenerationSettings>; }' is not assignable to type 'Action'.
    update,
  };
}

export function initFilters(filters: FilterOptions): Action {
  return {
    type: 'INIT_FILTER_OPTIONS',
    filters,
  };
}

export function updateFeatureFlags(flags: FeatureFlags): Action {
  return {
    type: 'UPDATE_FEATURE_FLAGS',
    flags,
  };
}
export function updateCompanyConfig(company_config: CompanyConfig): Action {
  return {
    type: 'UPDATE_COMPANY_CONFIG',
    // @ts-expect-error - TS2322: Type '{ type: "UPDATE_COMPANY_CONFIG"; companyConfig: CompanyConfig; }' is not assignable to type 'Action'.
    companyConfig: company_config,
  };
}

export function updateCustomEventTypes(allCustomEventTypes: Array<CustomEventType>): UpdateCustomEventTypesAction {
  return {
    type: 'UPDATE_CUSTOM_EVENT_TYPES',
    allCustomEventTypes,
  };
}

export function updateSpecificFilter(filterType: FilterType, filter: FilterValue): Action {
  return {
    type: 'UPDATE_SPECIFIC_FILTER',
    // @ts-expect-error - TS2322 - Type '{ type: "UPDATE_SPECIFIC_FILTER"; filterType: FilterType; filter: FilterValue; }' is not assignable to type 'Action'.
    filterType,
    filter,
  };
}

export function createFilter(filterType: FilterType, filter: FilterValue): Action {
  return {
    type: 'CREATE_FILTER',
    // @ts-expect-error - TS2322 - Type '{ type: "CREATE_FILTER"; filterType: FilterType; filter: FilterValue; }' is not assignable to type 'Action'.
    filterType,
    filter,
  };
}

export function deleteFilter(filterType: FilterType, filter: FilterValue): Action {
  return {
    type: 'DELETE_FILTER',
    // @ts-expect-error - TS2322 - Type '{ type: "DELETE_FILTER"; filterType: FilterType; filter: FilterValue; }' is not assignable to type 'Action'.
    filterType,
    filter,
  };
}

export function updateCSRF(csrfToken?: string | null): UpdateFetchDetailsAction {
  return {
    type: 'UPDATE_CSRF',
    csrfToken,
  };
}

export function updateTemplates(update: Partial<TemplatesReducerState>): UpdateTemplatesAction {
  return {
    type: 'UPDATE_TEMPLATES',
    update,
  };
}

export function updateUndoEventHistory(update: Partial<UndoEventHistoryState>): UndoEventHistoryAction {
  return {
    type: 'UPDATE_UNDO_EVENT_HISTORY',
    update,
  };
}

export function addIntegrationMapping(
  platform: IntegrationMappingPlatform,
  om: ObjectMapping
): IntegrationMappingsAction {
  return {
    type: 'ADD_INTEGRATION_MAPPING',
    platform,
    payload: om,
  };
}

export function updateIntegrationMapping(
  platform: IntegrationMappingPlatform,
  om: ObjectMapping
): IntegrationMappingsAction {
  return {
    type: 'UPDATE_INTEGRATION_MAPPING',
    platform,
    payload: om,
  };
}

export function deleteIntegrationMapping(platform: IntegrationMappingPlatform, id: string): IntegrationMappingsAction {
  return {
    type: 'DELETE_INTEGRATION_MAPPING',
    platform,
    id,
  };
}

export function loadIntegrationMappings(oms: Array<ObjectMapping>): IntegrationMappingsAction {
  return {
    type: 'LOAD_INTEGRATION_MAPPINGS',
    payload: oms,
  };
}

export function updateDemoState(demo: boolean): UpdateDemoStateAction {
  return {
    type: 'UPDATE_DEMO_STATE',
    demo,
  };
}

export function toggleFullscreenMode(): FullscreenModeAction {
  return {
    type: 'TOGGLE_FULLSCREEN_MODE',
  };
}

export function exitFullscreenMode(): FullscreenModeAction {
  return {
    type: 'EXIT_FULLSCREEN_MODE',
  };
}

export function toggleCommandBar(): FullscreenModeAction {
  return {
    type: 'TOGGLE_COMMAND_BAR',
  };
}

export function closeCommandBar(): FullscreenModeAction {
  return {
    type: 'CLOSE_COMMAND_BAR',
  };
}

export function updateAllActiveAgents(agents: Person[]): Action {
  return {
    type: 'UPDATE_ALL_ACTIVE_AGENTS',
    // @ts-expect-error -TS2322: Type '{ type: "UPDATE_ALL_ACTIVE_AGENTS"; agents: Agent[]; }' is not assignable to type 'Action'.
    agents,
  };
}

export function updateCompanyPlatforms(platforms: PlatformInfo[]): UpdateCompanyPlatformsAction {
  return {
    type: 'UPDATE_COMPANY_PLATFORMS',
    companyPlatforms: platforms,
  };
}

export function updateAgentStateTypes(agentStateTypes: AgentStateType[]): UpdateAgentStateTypesAction {
  return {
    type: 'UPDATE_AGENT_STATE_TYPES',
    agentStateTypes,
  };
}

export function updateResolvedAgentStateTypes(
  resolvedAgentStateTypes: ObjectMapping[]
): UpdateResolvedAgentStateTypesAction {
  return {
    type: 'UPDATE_RESOLVED_AGENT_STATE_TYPES',
    resolvedAgentStateTypes,
  };
}
