import React from 'react';

import { MenuOption } from '../Menus';
import { AssembledProduct } from '../../models';

import styles from '../navigation/NavigationItem/NavigationItem.module.css';

const appKey = 'assembled-app';

const isCal = (): boolean => {
  const currentApp = localStorage.getItem(appKey);
  return currentApp === AssembledProduct.Cal || process.env.APP_ENV === 'cal';
};

function SetProductView(product: AssembledProduct) {
  localStorage.setItem(appKey, product);
}

function SwitchProductViewMenuOption() {
  const targetView: AssembledProduct = isCal() ? AssembledProduct.Assembled : AssembledProduct.Cal;

  const label = targetView === AssembledProduct.Assembled ? 'Assembled' : 'Assist';

  function changeView() {
    const usingCalOnAppAssembled =
      isCal() &&
      (window.location.hostname === 'app.assembledhq.com' || window.location.hostname === 'app.assembled.com');

    if (process.env.BUILD_ENV === 'production' && !usingCalOnAppAssembled) {
      window.location.href =
        targetView === AssembledProduct.Assembled ? 'https://app.assembledhq.com' : 'https://cal.assembledhq.com';
    } else {
      SetProductView(targetView);
      window.location.href = '/';
    }
  }

  return (
    <div className={styles.userMenu}>
      <MenuOption onClick={() => changeView()}>
        <span className="icon icon-switch" /> {`Switch to ${label}`}
      </MenuOption>
    </div>
  );
}

export { isCal, SwitchProductViewMenuOption, SetProductView };
