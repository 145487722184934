import { FeatureFlags, METRIC_UNIT_FLOAT, MetricType } from '../../../models';
import { formatFloat } from '../../utils/Format';
import { CustomMetricFormatter } from '../../utils/FormatTyped';
import { atLeastOneChannelTicketsV2Enabled } from '../shared/channel_configurations';

// Keep labels, tooltips and calculations in sync with https://support.assembled.com/hc/en-us/articles/9945313474061-List-of-metrics-and-definitions
// Keep in sync with gocode/enums/utils.go

const getAgentAverageHandleTimeMetric = (featureFlags: FeatureFlags): MetricType => {
  const backwardCompatibleEnabled = featureFlags['tickets_v2.backwards_compatibility'];
  const ticketsV2Enabled = atLeastOneChannelTicketsV2Enabled(featureFlags);

  let label = 'Average handle time';
  let tooltip =
    'Average time that it took to resolve a call or chat ticket in the selected time range (typically looking at cases by created date). Varies by platform and excludes email channel.';
  let tooltip_equation: string | undefined = 'Total handle time ÷ Total number of contacts';

  if (ticketsV2Enabled && backwardCompatibleEnabled) {
    label = 'Average handle time per case';
    tooltip =
      'The average time that it took to resolve a Phone or Chat case in the selected time range (typically looking at cases by created date). Varies by platform and excludes the Email channel.';
    tooltip_equation = 'Total handle time ÷ Total number of cases';
  }

  return {
    label,
    id: 'agent_avg_handle_time',
    has_visibility: true,
    useMetricUnits: true,
    visibilityUnit: 'case',
    tooltip,
    tooltip_equation,
  };
};

export const AGENT_AVERAGE_UNIT_OF_WORK_HANDLE_TIME_METRIC: MetricType = {
  label: 'Average handle time per unit of work',
  id: 'agent_avg_unit_of_work_handle_time',
  has_visibility: true,
  useMetricUnits: true,
  visibilityUnit: 'case',
  tooltip:
    ' The average time that it took to handle a unit of work within a case in the selected time range (from the time the unit of work started to when it ended)',
  tooltip_equation: 'Total handle time ÷ Total number of units of work',
};

const getAgentCasesSolvedMetric = (featureFlags: FeatureFlags): MetricType => {
  const partialSolvedMetric = {
    id: 'agent_tickets_solved',
    has_visibility: true,
    useMetricUnits: true,
    visibilityUnit: 'case',
  };

  const ticketsV2Enabled = atLeastOneChannelTicketsV2Enabled(featureFlags);
  const backwardCompatibleEnabled = featureFlags['tickets_v2.backwards_compatibility'];
  const countEverySolve = featureFlags['metrics.count_every_solved'];
  let label = 'Cases solved';
  let tooltip =
    'Number of cases solved by an agent or group of agents in the selected time range (typically looking at cases by created date).';
  let tooltip_equation: string | undefined =
    'How cases are solved can vary depending on your configuration. For example, a case solved multiple times by the same agent can count as multiple solves or a single solve.';

  if (backwardCompatibleEnabled && ticketsV2Enabled) {
    tooltip_equation = undefined;
    if (countEverySolve) {
      label = 'Total cases solved';
      tooltip =
        'Number of cases solved by an agent or group of agents in the selected time range (typically looking at cases by created date). If a case gets solved multiple times (due to reopens, etc.), each solve will count as an additional solve.';
    } else {
      label = 'Unique cases solved';
      tooltip =
        'Number of distinct cases solved by this agent in the selected time range, excluding any cases that were later re-opened and/or solved again.';
    }
  }

  return { ...partialSolvedMetric, label, tooltip, tooltip_equation };
};

export const AGENT_UNITS_OF_WORK_HANDLED: MetricType = {
  label: 'Units of work handled',
  id: 'agent_units_of_work_handled',
  has_visibility: true,
  useMetricUnits: true,
  visibilityUnit: 'case',
  tooltip: 'The number of units of work solved, transferred, reassigned, or paused in the selected time range.',
};

const getAgentSolvedRateMetric = (featureFlags: FeatureFlags): MetricType => {
  const partialSolvedMetric = {
    id: 'agent_solved_rate',
    has_visibility: true,
    useMetricUnits: true,
    visibilityUnit: 'case',
  };

  const countEverySolve = featureFlags['metrics.count_every_solved'];
  let label = 'Unique case solved rate';
  let tooltip =
    'The percentage of terminal units of work that are the last solve of a case. A terminal unit of work is one with a solved, reassigned, or transferred status.';
  if (countEverySolve) {
    label = 'Total case solved rate';
    tooltip =
      'The percentage of terminal units of work that have a solved status. A terminal unit of work is one with a solved, reassigned, or transferred status.';
  }

  return { ...partialSolvedMetric, label, tooltip };
};

export const AGENT_CASES_REASSIGNED_AWAY_METRIC: MetricType = {
  label: 'Cases reassigned away',
  id: 'agent_tickets_reassigned_away',
  has_visibility: true,
  useMetricUnits: true,
  visibilityUnit: 'case',
  tooltip: 'Number of cases reassigned from one agent to another within the same queue in the selected time range.',
};

export const AGENT_CASES_TRANSFERRED_AWAY_METRIC: MetricType = {
  label: 'Cases transferred away',
  id: 'agent_tickets_transferred_away',
  has_visibility: true,
  useMetricUnits: true,
  visibilityUnit: 'case',
  tooltip: 'Number of cases transferred from one queue to another in the selected time range.',
};

export const AGENT_CASES_REASSIGNED_AWAY_RATE_METRIC: MetricType = {
  label: 'Reassigned away rate',
  id: 'agent_tickets_reassigned_away_rate',
  has_visibility: true,
  useMetricUnits: true,
  visibilityUnit: 'case',
  tooltip:
    'The percentage of cases reassigned from one agent to another within the same queue, out of the total number of cases solved, transferred, or reassigned, in the selected time range.',
};

export const AGENT_CASES_FIRST_CONTACT_RESOLUTION_METRIC: MetricType = {
  label: 'Cases solved on first contact',
  id: 'agent_tickets_first_contact_resolution',
  has_visibility: true,
  useMetricUnits: true,
  visibilityUnit: 'case',
  tooltip:
    'Number of email tickets/cases that an agent or group of agents resolves with a single message in the selected time range (typically looking at cases by created date)',
};

export const AGENT_CASES_TRANSFERRED_AWAY_RATE_METRIC: MetricType = {
  label: 'Transferred away rate',
  id: 'agent_tickets_transferred_away_rate',
  has_visibility: true,
  useMetricUnits: true,
  visibilityUnit: 'case',
  tooltip:
    'The percentage of cases transferred from one queue to another, out of all cases that were solved, transferred, or reassigned, in the selected time range.',
};

export const AGENT_CASES_FIRST_CONTACT_RESOLUTION_RATE_METRIC: MetricType = {
  label: 'First contact resolution rate',
  id: 'agent_tickets_first_contact_resolution_rate',
  has_visibility: true,
  useMetricUnits: true,
  visibilityUnit: 'case',
  tooltip:
    'Percent of email tickets/cases resolved with a single response in the selected time range (typically looking at cases by created date).',
  tooltip_equation: 'First Touch Resolution ÷ Total(Email tickets ÷ Cases solved)',
};

export const AGENT_MESSAGES_SENT_METRIC: MetricType = {
  label: 'Messages sent',
  id: 'agent_messages_sent',
  has_visibility: true,
  useMetricUnits: true,
  visibilityUnit: 'message',
  tooltip:
    'Shows how many messages or external comments an agent sent to a customer. This metric does not exist for the phone channel.',
  tooltip_hyperlink: 'https://support.assembled.com/hc/en-us/articles/9945313474061-List-of-metrics-and-definitions',
};

export const AGENT_MESSAGES_SENT_PER_ACTUAL_HOUR_METRIC: MetricType = {
  label: 'Messages sent (per actual hour)',
  id: 'agent_messages_sent_per_actual_hour',
  tooltip: 'The number of messages that an agent sent for a specific time period, by actual productive hour worked.',
  tooltip_equation: 'Messages sent ÷ Total productive hours worked',
};

export const AGENT_MESSAGES_SENT_PER_SCHEDULED_HOUR_METRIC: MetricType = {
  label: 'Messages sent (per scheduled hour)',
  id: 'agent_messages_sent_per_scheduled_hour',
  tooltip: 'The number of messages that an agent sent for a specific time period, by scheduled productive hour worked.',
  tooltip_equation: 'Messages sent ÷ Total scheduled productive hours',
};

export const AGENT_UNITS_OF_WORK_HANDLED_PER_ACTUAL_HOUR_METRIC: MetricType = {
  label: 'Units of work handled (per actual hour)',
  id: 'units_of_work_handled_per_actual_hour',
  tooltip: 'The number of units of work solved, transferred, reassigned, or paused by actual productive hour worked.',
  tooltip_equation: 'Units of work handled ÷ Actual productive hours worked',
};

export const AGENT_UNITS_OF_WORK_HANDLED_PER_SCHEDULED_HOUR_METRIC: MetricType = {
  label: 'Units of work handled (per scheduled hour)',
  id: 'units_of_work_handled_per_scheduled_hour',
  tooltip: 'The number of units of work solved, transferred, reassigned, or paused by scheduled hour.',
  tooltip_equation: 'Units of work handled ÷ Total scheduled productive hours',
};

export const ADHERENCE_METRIC: MetricType = {
  id: 'adherence_percentage',
  label: 'Productive adherence',
  tooltip: 'Percentage of time an agent adhered to their scheduled productive events.',
  tooltip_equation: 'Hours in adherence during scheduled productive events ÷ Scheduled productive hours',
};

export const OUT_OF_ADHERENCE_METRIC: MetricType = {
  id: 'out_of_adherence',
  label: 'Out of adherence duration',
  tooltip: 'How long each agent has been out of adherence for the currently scheduled productive event.',
  positive_direction: 'descending',
  unit: 'seconds',
};

export const STATE_DURATION_METRIC: MetricType = {
  id: 'state_duration',
  label: 'State duration',
  tooltip: 'How long each agent has been in their current state.',
  positive_direction: 'descending',
  unit: 'seconds',
};

// TODO: stop gating this by productive aux feature flag and add to support article, run the playbook to make sure it doesnt impact performance
export const ADHERENCE_METRIC_ALL_CHANNELS: MetricType = {
  id: 'adherence_percentage_all_channels',
  label: 'Productive adherence (all)',
  tooltip: 'Percentage of time an agent adhered to their scheduled productive events.',
  tooltip_equation: 'Hours in adherence during scheduled productive events ÷ Scheduled productive hours',
};

export const SCHEDULE_ADHERENCE_METRIC: MetricType = {
  id: 'schedule_adherence_percentage',
  label: 'Schedule adherence',
  tooltip: 'Percentage of time an agent adhered to all their scheduled events except time off.',
  tooltip_equation: 'Hours in adherence during scheduled events ÷ Scheduled hours',
};

export const CONFORMANCE_METRIC: MetricType = {
  id: 'conformance_percentage',
  label: 'Conformance',
  tooltip:
    'Percent of time worked by an agent compared to what they were scheduled to work, regardless of whether or not they were adhering to the schedule.',
  tooltip_equation: 'Hours in productive state ÷ Scheduled time for that channel',
};

export const CONFORMANCE_STRICT_METRIC: MetricType = {
  id: 'conformance_strict_percentage',
  label: 'Conformance (strict)',
};

export const OCCUPANCY_METRIC: MetricType = {
  id: 'occupancy_percentage',
  label: 'Occupancy',
  tooltip:
    'Percent of logged-in time that an agent spent actively working on customer issues. Varies for each channel.',
};

// TODO: stop gating this by productive aux feature flag and add to support article, run the playbook to make sure it doesnt impact performance
export const OCCUPANCY_ALL_CHANNELS_METRIC: MetricType = {
  id: 'occupancy_percentage_all_channels',
  label: 'Occupancy (all channels)',
};

export const SCHEDULED_HOURS_METRIC: MetricType = {
  id: 'scheduled_seconds',
  label: 'Scheduled hours (productive)',
  tooltip: 'Time the agent was scheduled to work on "productive events" during the specified interval.',
};

export const SCHEDULED_HOURS_TOTAL_METRIC: MetricType = {
  id: 'scheduled_seconds_total',
  label: 'Scheduled hours (total)',
  tooltip: 'Time the agent was scheduled to work on any event during the specified interval.',
};

export const SCHEDULED_UTILIZATION_METRIC: MetricType = {
  id: 'utilization',
  label: 'Scheduled utilization',
  tooltip:
    'Percent of time an agent was scheduled for productive events compared to all scheduled time, regardless of whether or not they were adhering to the schedule.',
  tooltip_equation: 'Scheduled productive hours ÷ Scheduled hours',
};

export const ACTUAL_UTILIZATION_DEPRECATED_METRIC: MetricType = {
  id: 'actual_utilization',
  label: 'Actual utilization',
};

// TODO: add to support article after launch of productive aux
export const ACTUAL_UTILIZATION_METRIC: MetricType = {
  id: 'actual_utilization_new',
  label: 'Actual utilization',
  tooltip:
    'Percent of time an agent worked on productive events compared to actual hours worked, regardless of whether or not they were adhering to the schedule.',
  tooltip_equation: 'Actual productive hours worked ÷ Actual hours worked',
};

// TODO: add to support article after launch of productive aux
export const ACTUAL_UTILIZATION_ALL_CHANNELS_METRIC: MetricType = {
  id: 'actual_utilization_all_channels',
  label: 'Actual utilization (all channels)',
  tooltip: 'This includes all auxiliary productive hours worked',
  tooltip_equation: 'Actual productive and auxiliary productive hours worked ÷ Actual hours worked',
};

export const ACTUAL_HOURS_IN_ADHERENCE_METRIC: MetricType = {
  id: 'actual_seconds_in_adherence',
  label: 'Hours in productive adherence',
  tooltip: 'Time that the agent was following their productive scheduled events during the specified interval.',
};

// TODO: stop gating this by productive aux feature flag and add to support article, run the playbook to make sure it doesnt impact performance
export const ACTUAL_HOURS_IN_ADHERENCE_ALL_CHANNELS_METRIC: MetricType = {
  id: 'actual_seconds_in_adherence_all_channels',
  label: 'Actual hours in adherence (all channels)',
  tooltip: 'Amount of time an agent adhered to their scheduled productive events.',
};

export const ACTUAL_HOURS_IN_SCHEDULE_ADHERENCE_METRIC: MetricType = {
  id: 'actual_seconds_in_schedule_adherence',
  label: 'Hours in schedule adherence',
  tooltip: 'Time an agent adhered to all their scheduled events except time off.',
};

export const ACTUAL_PRODUCTIVE_HOURS_METRIC: MetricType = {
  id: 'actual_seconds_worked',
  label: 'Actual productive hours worked',
  tooltip:
    'Number of productive hours that the agent worked during the specified interval regardless of their schedule.',
};

// TODO: add to support article after launch of productive aux
export const ACTUAL_PRODUCTIVE_AND_AUX_HOURS_ALL_CHANNELS_METRIC: MetricType = {
  id: 'actual_productive_and_aux_seconds_worked_all_channels',
  label: 'Actual productive hours worked (all channels)',
  tooltip: 'This includes all auxiliary productive hours worked',
};

export const ACTUAL_PRODUCTIVE_HOURS_ALL_CHANNELS_METRIC: MetricType = {
  id: 'actual_productive_seconds_worked_all_channels',
  label: '',
};

// TODO: add to support article after launch of productive aux
export const ACTUAL_PRODUCTIVE_AUX_HOURS_ALL_CHANNELS_METRIC: MetricType = {
  id: 'actual_productive_aux_seconds_worked_all_channels',
  label: 'Actual auxiliary productive hours worked (all channels)',
};

export const AGENT_STATE_AGENT_AVG_HANDLE_TIME_METRIC: MetricType = {
  id: 'agent_state_agent_avg_handle_time',
  label: 'Agent average handle time',
  aggregateLabel: 'Avg. handle time',
  has_visibility: true,
  useMetricUnits: true,
  visibilityUnit: 'handle time',
  tooltip: 'The average time spent by an agent per ticket.',
  tooltip_equation: 'Agent ticket time ÷ Agent tickets touched',
};

export const AGENT_STATE_TICKET_HANDLE_TIME_METRIC: MetricType = {
  id: 'agent_state_ticket_handle_time',
  label: 'Agent ticket time',
  aggregateLabel: 'Ticket time',
  has_visibility: true,
  useMetricUnits: true,
  visibilityUnit: 'handle time',
  tooltip: 'The total time an agent spent viewing tickets.',
};

export const AGENT_STATE_TICKETS_TOUCHED_METRIC: MetricType = {
  id: 'agent_state_tickets_touched',
  label: 'Agent tickets touched',
  aggregateLabel: 'Tickets touched',
  has_visibility: true,
  useMetricUnits: true,
  visibilityUnit: 'cases',
  tooltip: 'The number of distinct tickets an agent viewed.',
};

export const AGENT_STATE_SOLVED_AGENT_AVG_HANDLE_TIME_METRIC: MetricType = {
  id: 'agent_state_solved_agent_avg_handle_time',
  label: 'Agent average handle time (solved)',
  aggregateLabel: 'Solved avg. handle time',
  has_visibility: true,
  useMetricUnits: true,
  visibilityUnit: 'handle time',
  tooltip: 'The average time spent by an agent per solved ticket.',
  tooltip_equation: 'Agent ticket time (solved) ÷ Agent tickets touched (solved)',
  unit: 'seconds',
};

export const AGENT_STATE_SOLVED_TICKET_HANDLE_TIME_METRIC: MetricType = {
  id: 'agent_state_solved_ticket_handle_time',
  label: 'Agent ticket time (solved)',
  aggregateLabel: 'Solved ticket time',
  has_visibility: true,
  useMetricUnits: true,
  visibilityUnit: 'handle time',
  tooltip: 'The total time an agent spent viewing solved tickets.',
};

export const AGENT_STATE_SOLVED_TICKETS_TOUCHED_METRIC: MetricType = {
  id: 'agent_state_solved_tickets_touched',
  label: 'Agent tickets touched (solved)',
  aggregateLabel: 'Solved tickets touched',
  has_visibility: true,
  useMetricUnits: true,
  visibilityUnit: 'case',
  tooltip: 'The number of distinct solved tickets an agent viewed.',
};

export const AGENT_CSAT_COUNT_METRIC: MetricType = {
  id: 'agent_csat_count',
  label: 'CSAT responses received',
  tooltip: 'The number of tickets for which a customer has provided a CSAT score for an agent.',
  has_visibility: true,
  useMetricUnits: true,
  visibilityUnit: 'case',
};

export const AGENT_AVERAGE_CSAT_SCORE_METRIC: MetricType = {
  id: 'agent_avg_csat_score',
  label: 'Average CSAT score',
  tooltip:
    'Typically measured on a 1-to-5 scale with 5 being very satisfied and 1 being very dissatisfied. On platforms like Zendesk, CSAT simplifies to "Good" or "Bad" ratings, where the average is calculated as the proportion of "Good" ratings to the total of "Good" and "Bad" ratings received.',
  tooltip_equation: 'Sum of CSAT scores ÷ Number of CSAT responses.',
  has_visibility: true,
  useMetricUnits: true,
  visibilityUnit: 'case',
};

export const ACTUAL_ALL_HOURS_METRIC: MetricType = {
  id: 'actual_all_seconds_worked',
  label: 'Actual hours worked (total)',
  tooltip: 'Number of hours that the agent worked during the specified interval regardless of their schedule.',
};

export const ACTUAL_HOURS_NON_PRODUCTIVE_METRIC_DEPRECATED: MetricType = {
  id: 'actual_non_productive_seconds_worked',
  label: 'Actual hours worked (non-productive)',
  tooltip: 'Number of hours that the agent worked during the specified interval regardless of their schedule',
};

// TODO: add to support article after launch of productive aux
export const ACTUAL_HOURS_NON_PRODUCTIVE_METRIC: MetricType = {
  id: 'actual_non_productive_seconds_worked_new',
  label: 'Actual non-productive hours',
  tooltip: 'Number of hours an agent spent in auxiliary non-productive states',
  tooltip_equation: 'sum of hours spent in non-productive-auxiliary states',
};

// TODO: add to support article after launch of productive aux
export const IN_OFFICE_SHRINKAGE: MetricType = {
  id: 'in_office_shrinkage',
  label: 'In-office shrinkage',
  tooltip:
    'Percentage of time an agent is in a non productive state (e.g. time spent out of adherence for productive events and time spent in events like breaks and lunches)',
};

export const CAL_CSAT_PERCENTAGE: MetricType = {
  id: 'cal_csat_percentage',
  label: 'CSAT with Assist',
  unit: 'percentage',
  aggregateLabel: 'Average CSAT w/ Assist',
  aggregationMethod: 'average',
  graphType: 'line',
  tooltip: 'CSAT score for all tickets that Assist was involved with. This includes all interactions with Assist.',
  tooltip_equation: 'Sum of CSAT Good w/ Assist ÷ (Sum of CSAT Good w/ Assist + Sum of CSAT Bad w/ Assist)',
};

export const CAL_CSAT_GOOD: MetricType = {
  id: 'cal_csat_good',
  label: 'CSAT good',
  unit: 'integer',
  graphColor: '#3B78D9',
  aggregationMethod: 'sum',
  tooltip: 'The number of tickets where the customer rated the interaction as good, and Assist was involved.',
};

export const CAL_CSAT_BAD: MetricType = {
  id: 'cal_csat_bad',
  label: 'CSAT bad',
  unit: 'integer',
  graphColor: '#D47E2F',
  aggregationMethod: 'sum',
  tooltip: 'The number of tickets where the customer rated the interaction as bad, and Assist was involved.',
};

export const NON_CAL_CSAT_GOOD: MetricType = {
  id: 'non_cal_csat_good',
  label: 'CSAT good without Assist',
  unit: 'integer',
  tooltip: 'The number of tickets where the customer rated the interaction as good, and Assist was not involved.',
};

export const NON_CAL_CSAT_BAD: MetricType = {
  id: 'non_cal_csat_bad',
  label: 'CSAT bad without Assist',
  unit: 'integer',
  tooltip: 'The number of tickets where the customer rated the interaction as bad, and Assist was not involved.',
};

export const NON_CAL_CSAT_PERCENTAGE: MetricType = {
  id: 'non_cal_csat_percentage',
  label: 'CSAT without Assist',
  unit: 'percentage',
  aggregateLabel: 'Average CSAT without Assist',
  aggregationMethod: 'average',
  graphType: 'line',
  tooltip: 'CSAT score for all tickets that Assist was not involved with.',
  tooltip_equation: 'Sum of CSAT Good w/o Assist ÷ (Sum of CSAT Good w/o Assist + Sum of CSAT Bad w/o Assist)',
};

export const CAL_MESSAGES_AUTOSENT: MetricType = {
  id: 'cal_messages_autosent',
  label: 'Messages sent by Assist',
  unit: 'integer',
  aggregateLabel: 'Total autosends',
  aggregationMethod: 'sum',
  graphType: 'bar',
  tooltip: 'The number of messages automatically sent by Assist',
};

export const CAL_MESSAGES_SENT: MetricType = {
  id: 'cal_messages_sent',
  label: 'Messages with Assist',
  unit: 'integer',
  aggregateLabel: 'Total interactions',
  aggregationMethod: 'sum',
  graphType: 'bar',
  tooltip:
    'The total number of messages sent to Assist. This encompasses all interactions such as question & answer, summarize ticket, etc.',
};

export const CAL_QUESTION_ANSWER_MESSAGES: MetricType = {
  id: 'cal_question_answer_messages',
  label: 'Question & answer messages',
  unit: 'integer',
  aggregateLabel: 'Total messages sent',
  aggregationMethod: 'sum',
  graphType: 'bar',
  tooltip: 'The number of messages sent to Assist for standard question & answer interactions',
};

export const CAL_DRAFT_A_REPLY_MESSAGES: MetricType = {
  id: 'cal_draft_a_reply_messages',
  label: 'Replies drafted',
  unit: 'integer',
  aggregateLabel: 'Total replies drafted',
  aggregationMethod: 'sum',
  graphType: 'bar',
  tooltip: 'The number of times the agent used Assist to help draft a customer reply',
};

export const CAL_SUMMARIZE_TICKET: MetricType = {
  id: 'cal_summarize_ticket',
  label: 'Summaries created',
  unit: 'integer',
  aggregateLabel: 'Total summaries created',
  aggregationMethod: 'sum',
  graphType: 'bar',
  tooltip: 'The number of times the agent used Assist to summarize a ticket',
};

export const CAL_INITIAL_GREETING: MetricType = {
  id: 'cal_initial_greeting',
  label: 'Initial greetings created',
  unit: 'integer',
  aggregateLabel: 'Total initial greetings',
  aggregationMethod: 'sum',
  graphType: 'bar',
  tooltip: 'The number of times the agent used Assist to create an initial greeting',
};

export const CAL_TICKETS_TOUCHED: MetricType = {
  id: 'cal_tickets_touched',
  label: 'Tickets with Assist',
  unit: 'integer',
  aggregateLabel: 'Total tickets',
  aggregationMethod: 'sum',
  tooltip: 'The unique number of tickets that Assist was used with, across all interactions',
};

export const CAL_TICKETS_DEFLECTED: MetricType = {
  id: 'cal_tickets_deflected',
  label: 'Tickets deflected by Assist',
  unit: 'integer',
  aggregateLabel: 'Total tickets',
  aggregationMethod: 'sum',
  tooltip: `The number of tickets where Assist automatically replied to and resolved the customer's query`,
};

export const CAL_QA_PERCENTAGE: MetricType = {
  id: 'cal_qa_score_percentage',
  label: 'QA score with Assist',
  unit: 'percentage',
  aggregationMethod: 'average',
  tooltip: 'The average quality assurance (QA) score for tickets that Assist was involved with.',
  graphType: 'line',
};

export const NON_CAL_QA_PERCENTAGE: MetricType = {
  id: 'non_cal_qa_score_percentage',
  label: 'QA score without Assist',
  unit: 'percentage',
  aggregationMethod: 'average',
  tooltip: 'The average quality assurance (QA) score for tickets that Assist was not involved with.',
  graphType: 'line',
};

const getCasesSolvedPerScheduledHourMetric = (featureFlags: FeatureFlags): MetricType => {
  const partialSolvedMetric = {
    id: 'solved_per_scheduled_hour',
    unit: METRIC_UNIT_FLOAT,
  };

  const ticketsV2Enabled = atLeastOneChannelTicketsV2Enabled(featureFlags);
  const backwardCompatibleEnabled = featureFlags['tickets_v2.backwards_compatibility'];
  const countEverySolve = featureFlags['metrics.count_every_solved'];
  let label = 'Solved (per scheduled hour)';
  let tooltip =
    'Number of cases solved by an agent or group of agents by scheduled hour. Referred to as "throughput" elsewhere in Assembled';
  let tooltip_equation: string = 'Cases solved ÷ Total scheduled productive hours';

  if (backwardCompatibleEnabled && ticketsV2Enabled) {
    if (countEverySolve) {
      label = 'Total cases solved (per scheduled hour)';
      tooltip =
        'Number of cases solved by an agent or group of agents by scheduled hour. If a case gets solved multiple times (due to reopens, etc.), each solve will count as an additional solve. Referred to as "throughput" elsewhere in Assembled';
    } else {
      label = 'Unique cases solved (per scheduled hour)';
      tooltip =
        'Number of distinct cases solved by an agent or group of agents by scheduled hour. Referred to as "throughput" elsewhere in Assembled';
      tooltip_equation = 'Unique cases solved ÷ Total scheduled productive hours';
    }
  }
  return { ...partialSolvedMetric, label, tooltip, tooltip_equation };
};

const getCasesSolvedPerActualHourMetric = (featureFlags: FeatureFlags): MetricType => {
  const partialSolvedMetric = {
    id: 'solved_per_actual_hour',
    unit: METRIC_UNIT_FLOAT,
  };

  const ticketsV2Enabled = atLeastOneChannelTicketsV2Enabled(featureFlags);
  const backwardCompatibleEnabled = featureFlags['tickets_v2.backwards_compatibility'];
  const countEverySolve = featureFlags['metrics.count_every_solved'];
  let label = 'Solved (per actual hour)';
  let tooltip = 'Number of cases solved by an agent or group of agents by actual productive hour worked.';
  let tooltip_equation: string = 'Cases solved ÷ Actual productive hours worked';

  if (backwardCompatibleEnabled && ticketsV2Enabled) {
    if (countEverySolve) {
      label = 'Total cases solved (per actual hour)';
      tooltip =
        'Number of cases solved by an agent or group of agents by actual productive hour worked. If a case gets solved multiple times (due to reopens, etc.), each solve will count as an additional solve.';
    } else {
      label = 'Unique cases solved (per actual hour)';
      tooltip = 'Number of distinct cases solved by an agent or group of agents by actual productive hour worked.';
      tooltip_equation = 'Unique cases solved ÷ Actual productive hours worked';
    }
  }

  return { ...partialSolvedMetric, label, tooltip, tooltip_equation };
};

export const QA_SCORE_METRIC: MetricType = {
  id: 'qa_score',
  label: 'QA score',
};

export const ACTUAL_ALL_HOURS_WORKED_METRIC_ALL_CHANNELS_METRIC: MetricType = {
  id: 'actual_all_seconds_worked_all_channels',
  label: 'Actual hours worked (all channels)',
};

export const AVAILABLE_AND_OCCUPIED_HOURS_METRIC: MetricType = {
  id: 'available_and_occupied_time_seconds',
  label: 'Available and occupied hours',
};

export const AVAILABLE_AND_OCCUPIED_HOURS_ALL_CHANNELS_METRIC: MetricType = {
  id: 'available_and_occupied_time_seconds_all_channels',
  label: 'Available and occupied hours (all channels)',
};

export const OCCUPIED_HOURS_METRIC: MetricType = {
  id: 'occupied_time_seconds',
  label: 'Occupied hours',
};

export const OCCUPIED_HOURS_ALL_CHANNELS_METRIC: MetricType = {
  id: 'occupied_time_seconds_all_channels',
  label: 'Occupied hours (all channels)',
};

export const SCHEDULED_SECONDS_ALL_CHANNELS_METRIC: MetricType = {
  id: 'scheduled_seconds_all_channels',
  label: 'Scheduled hours (all channels)',
};

export const CAL_FEEDBACK_TYPE_CORRECT: MetricType = {
  id: 'evaluation-correct',
  label: 'Correct',
  unit: 'integer',
  aggregationMethod: 'sum',
  graphColor: '#3B78D9',
  tooltip: 'The knowledge used to generate the answer is correct and the answer itself is correct.',
};

export const CAL_FEEDBACK_TYPE_MISSING_KNOWLEDGE: MetricType = {
  id: 'evaluation-missing-knowledge',
  label: 'Missing knowledge',
  unit: 'integer',
  aggregationMethod: 'sum',
  graphColor: '#F1C331',
  tooltip: 'Assist does not have the knowledge required to answer the question.',
};

export const CAL_FEEDBACK_TYPE_INCORRECT_RESOURCE: MetricType = {
  id: 'evaluation-incorrect-resource',
  label: 'Incorrect resource',
  unit: 'integer',
  aggregationMethod: 'sum',
  graphColor: '#033663',
  tooltip:
    'The knowledge used to generate the answer is incorrect. Meaning, Assist failed to find/use the right resource.',
};

export const CAL_FEEDBACK_TYPE_INCORRECT_RESPONSE: MetricType = {
  id: 'evaluation-incorrect-response',
  label: 'Incorrect response',
  unit: 'integer',
  aggregationMethod: 'sum',
  graphColor: '#D47E2F',
  tooltip:
    'The knowledge used to provide this answer was correct, however, the response contained incorrect information. “Incorrect response” could also indicate that Assist’s response is missing pertinent steps or information.',
};

export const CAL_FEEDBACK_TYPE_INCORRECT_TOTAL: MetricType = {
  id: 'evaluation-incorrect-total',
  label: 'Incorrect',
  unit: 'integer',
  aggregationMethod: 'sum',
  graphColor: '#E36C46',
};

export const CAL_FEEDBACK_TYPE_IMPOSSIBLE_TO_ANSWER: MetricType = {
  id: 'evaluation-impossible-to-answer',
  label: 'Impossible to answer',
  unit: 'integer',
  aggregationMethod: 'sum',
  graphColor: '#DAECFC',
  tooltip: 'The user did not provide enough information for Assist to answer the question',
};

export const CAL_FEEDBACK_TYPE_EXCLUDE: MetricType = {
  id: 'evaluation-exclude',
  label: 'Exclude',
  unit: 'integer',
  aggregationMethod: 'sum',
  graphColor: '#B2A0E5',
  tooltip: 'The interaction is a test or a duplicate.',
};

export const CAL_FEEDBACK_TYPE_ACCURACY: MetricType = {
  id: 'evaluation-accuracy',
  label: 'Correctness',
  unit: 'percentage',
  aggregationMethod: 'average',
  graphType: 'line',
  tooltip: "Cal's overall accuracy in their replies",
  tooltip_equation: 'Correct replies ÷ (Correct replies + Incorrect resource + Incorrect response + Missing knowledge)',
};

export const CAL_FEEDBACK_TYPE_HELPFUL: MetricType = {
  id: 'feedback-helpful',
  label: 'Helpful 👍',
  unit: 'integer',
  aggregationMethod: 'sum',
  graphColor: '#3B78D9',
};

export const CAL_FEEDBACK_TYPE_NOT_HELPFUL: MetricType = {
  id: 'feedback-not-helpful',
  label: 'Not helpful 👎',
  unit: 'integer',
  aggregationMethod: 'sum',
  graphColor: '#E36C46',
};

export const TICKETS_V2_METRICS: Array<MetricType> = [
  AGENT_UNITS_OF_WORK_HANDLED,
  AGENT_AVERAGE_UNIT_OF_WORK_HANDLE_TIME_METRIC,
  AGENT_CASES_REASSIGNED_AWAY_METRIC,
  AGENT_CASES_TRANSFERRED_AWAY_METRIC,
  AGENT_CASES_FIRST_CONTACT_RESOLUTION_METRIC,
  AGENT_CASES_REASSIGNED_AWAY_RATE_METRIC,
  AGENT_CASES_TRANSFERRED_AWAY_RATE_METRIC,
  AGENT_CASES_FIRST_CONTACT_RESOLUTION_RATE_METRIC,
  AGENT_UNITS_OF_WORK_HANDLED_PER_ACTUAL_HOUR_METRIC,
  AGENT_UNITS_OF_WORK_HANDLED_PER_SCHEDULED_HOUR_METRIC,
];

const getMetricType = (metricID: string, featureFlags: FeatureFlags): MetricType => {
  const solvedMetric = getAgentCasesSolvedMetric(featureFlags);
  const solvedPerActualHourMetric = getCasesSolvedPerActualHourMetric(featureFlags);
  const solvedPerScheduledHourMetric = getCasesSolvedPerScheduledHourMetric(featureFlags);
  const solvedRateMetric = getAgentSolvedRateMetric(featureFlags);
  const METRIC_ID_TO_METRIC_TYPE: { [key: string]: MetricType } = {
    [getAgentAverageHandleTimeMetric(featureFlags).id]: getAgentAverageHandleTimeMetric(featureFlags),
    [AGENT_AVERAGE_UNIT_OF_WORK_HANDLE_TIME_METRIC.id]: AGENT_AVERAGE_UNIT_OF_WORK_HANDLE_TIME_METRIC,
    [solvedMetric.id]: solvedMetric,
    [AGENT_UNITS_OF_WORK_HANDLED.id]: AGENT_UNITS_OF_WORK_HANDLED,
    [solvedRateMetric.id]: solvedRateMetric,
    [AGENT_CASES_REASSIGNED_AWAY_METRIC.id]: AGENT_CASES_REASSIGNED_AWAY_METRIC,
    [AGENT_CASES_TRANSFERRED_AWAY_METRIC.id]: AGENT_CASES_TRANSFERRED_AWAY_METRIC,
    [AGENT_CASES_REASSIGNED_AWAY_RATE_METRIC.id]: AGENT_CASES_REASSIGNED_AWAY_RATE_METRIC,
    [AGENT_CASES_FIRST_CONTACT_RESOLUTION_METRIC.id]: AGENT_CASES_FIRST_CONTACT_RESOLUTION_METRIC,
    [AGENT_CASES_TRANSFERRED_AWAY_RATE_METRIC.id]: AGENT_CASES_TRANSFERRED_AWAY_RATE_METRIC,
    [AGENT_CASES_FIRST_CONTACT_RESOLUTION_RATE_METRIC.id]: AGENT_CASES_FIRST_CONTACT_RESOLUTION_RATE_METRIC,
    [AGENT_MESSAGES_SENT_METRIC.id]: AGENT_MESSAGES_SENT_METRIC,
    [AGENT_MESSAGES_SENT_PER_ACTUAL_HOUR_METRIC.id]: AGENT_MESSAGES_SENT_PER_ACTUAL_HOUR_METRIC,
    [AGENT_MESSAGES_SENT_PER_SCHEDULED_HOUR_METRIC.id]: AGENT_MESSAGES_SENT_PER_SCHEDULED_HOUR_METRIC,
    [AGENT_UNITS_OF_WORK_HANDLED_PER_ACTUAL_HOUR_METRIC.id]: AGENT_UNITS_OF_WORK_HANDLED_PER_ACTUAL_HOUR_METRIC,
    [AGENT_UNITS_OF_WORK_HANDLED_PER_SCHEDULED_HOUR_METRIC.id]: AGENT_UNITS_OF_WORK_HANDLED_PER_SCHEDULED_HOUR_METRIC,
    [ADHERENCE_METRIC.id]: ADHERENCE_METRIC,
    [ADHERENCE_METRIC_ALL_CHANNELS.id]: ADHERENCE_METRIC_ALL_CHANNELS,
    [SCHEDULE_ADHERENCE_METRIC.id]: SCHEDULE_ADHERENCE_METRIC,
    [CONFORMANCE_METRIC.id]: CONFORMANCE_METRIC,
    [CONFORMANCE_STRICT_METRIC.id]: CONFORMANCE_STRICT_METRIC,
    [OCCUPANCY_METRIC.id]: OCCUPANCY_METRIC,
    [OCCUPANCY_ALL_CHANNELS_METRIC.id]: OCCUPANCY_ALL_CHANNELS_METRIC,
    [SCHEDULED_HOURS_METRIC.id]: SCHEDULED_HOURS_METRIC,
    [SCHEDULED_HOURS_TOTAL_METRIC.id]: SCHEDULED_HOURS_TOTAL_METRIC,
    [SCHEDULED_UTILIZATION_METRIC.id]: SCHEDULED_UTILIZATION_METRIC,
    [ACTUAL_UTILIZATION_DEPRECATED_METRIC.id]: ACTUAL_UTILIZATION_DEPRECATED_METRIC,
    [ACTUAL_UTILIZATION_METRIC.id]: ACTUAL_UTILIZATION_METRIC,
    [ACTUAL_UTILIZATION_ALL_CHANNELS_METRIC.id]: ACTUAL_UTILIZATION_ALL_CHANNELS_METRIC,
    [ACTUAL_HOURS_IN_ADHERENCE_METRIC.id]: ACTUAL_HOURS_IN_ADHERENCE_METRIC,
    [ACTUAL_HOURS_IN_ADHERENCE_ALL_CHANNELS_METRIC.id]: ACTUAL_HOURS_IN_ADHERENCE_ALL_CHANNELS_METRIC,
    [ACTUAL_HOURS_IN_SCHEDULE_ADHERENCE_METRIC.id]: ACTUAL_HOURS_IN_SCHEDULE_ADHERENCE_METRIC,
    [ACTUAL_PRODUCTIVE_HOURS_METRIC.id]: ACTUAL_PRODUCTIVE_HOURS_METRIC,
    [ACTUAL_PRODUCTIVE_AND_AUX_HOURS_ALL_CHANNELS_METRIC.id]: ACTUAL_PRODUCTIVE_AND_AUX_HOURS_ALL_CHANNELS_METRIC,
    [ACTUAL_PRODUCTIVE_AUX_HOURS_ALL_CHANNELS_METRIC.id]: ACTUAL_PRODUCTIVE_AUX_HOURS_ALL_CHANNELS_METRIC,
    [ACTUAL_ALL_HOURS_METRIC.id]: ACTUAL_ALL_HOURS_METRIC,
    [ACTUAL_HOURS_NON_PRODUCTIVE_METRIC_DEPRECATED.id]: ACTUAL_HOURS_NON_PRODUCTIVE_METRIC_DEPRECATED,
    [ACTUAL_HOURS_NON_PRODUCTIVE_METRIC.id]: ACTUAL_HOURS_NON_PRODUCTIVE_METRIC,
    [IN_OFFICE_SHRINKAGE.id]: IN_OFFICE_SHRINKAGE,
    [solvedPerScheduledHourMetric.id]: solvedPerScheduledHourMetric,
    [solvedPerActualHourMetric.id]: solvedPerActualHourMetric,
    [QA_SCORE_METRIC.id]: QA_SCORE_METRIC,
    [ACTUAL_ALL_HOURS_WORKED_METRIC_ALL_CHANNELS_METRIC.id]: ACTUAL_ALL_HOURS_WORKED_METRIC_ALL_CHANNELS_METRIC,
    [AVAILABLE_AND_OCCUPIED_HOURS_METRIC.id]: AVAILABLE_AND_OCCUPIED_HOURS_METRIC,
    [AVAILABLE_AND_OCCUPIED_HOURS_ALL_CHANNELS_METRIC.id]: AVAILABLE_AND_OCCUPIED_HOURS_ALL_CHANNELS_METRIC,
    [OCCUPIED_HOURS_METRIC.id]: OCCUPIED_HOURS_METRIC,
    [OCCUPIED_HOURS_ALL_CHANNELS_METRIC.id]: OCCUPIED_HOURS_ALL_CHANNELS_METRIC,
    [SCHEDULED_SECONDS_ALL_CHANNELS_METRIC.id]: SCHEDULED_SECONDS_ALL_CHANNELS_METRIC,
    [AGENT_STATE_AGENT_AVG_HANDLE_TIME_METRIC.id]: AGENT_STATE_AGENT_AVG_HANDLE_TIME_METRIC,
    [AGENT_STATE_TICKET_HANDLE_TIME_METRIC.id]: AGENT_STATE_TICKET_HANDLE_TIME_METRIC,
    [AGENT_STATE_TICKETS_TOUCHED_METRIC.id]: AGENT_STATE_TICKETS_TOUCHED_METRIC,
    [AGENT_STATE_SOLVED_AGENT_AVG_HANDLE_TIME_METRIC.id]: AGENT_STATE_SOLVED_AGENT_AVG_HANDLE_TIME_METRIC,
    [AGENT_STATE_SOLVED_TICKET_HANDLE_TIME_METRIC.id]: AGENT_STATE_SOLVED_TICKET_HANDLE_TIME_METRIC,
    [AGENT_STATE_SOLVED_TICKETS_TOUCHED_METRIC.id]: AGENT_STATE_SOLVED_TICKETS_TOUCHED_METRIC,
    [CAL_CSAT_PERCENTAGE.id]: CAL_CSAT_PERCENTAGE,
    [CAL_CSAT_GOOD.id]: CAL_CSAT_GOOD,
    [CAL_CSAT_BAD.id]: CAL_CSAT_BAD,
    [NON_CAL_CSAT_PERCENTAGE.id]: NON_CAL_CSAT_PERCENTAGE,
    [NON_CAL_CSAT_GOOD.id]: NON_CAL_CSAT_GOOD,
    [NON_CAL_CSAT_BAD.id]: NON_CAL_CSAT_BAD,
    [CAL_MESSAGES_SENT.id]: CAL_MESSAGES_SENT,
    [CAL_MESSAGES_AUTOSENT.id]: CAL_MESSAGES_AUTOSENT,
    [CAL_TICKETS_TOUCHED.id]: CAL_TICKETS_TOUCHED,
    [CAL_TICKETS_DEFLECTED.id]: CAL_TICKETS_DEFLECTED,
    [CAL_QUESTION_ANSWER_MESSAGES.id]: CAL_QUESTION_ANSWER_MESSAGES,
    [CAL_DRAFT_A_REPLY_MESSAGES.id]: CAL_DRAFT_A_REPLY_MESSAGES,
    [AGENT_CSAT_COUNT_METRIC.id]: AGENT_CSAT_COUNT_METRIC,
    [AGENT_AVERAGE_CSAT_SCORE_METRIC.id]: AGENT_AVERAGE_CSAT_SCORE_METRIC,
    [CAL_FEEDBACK_TYPE_CORRECT.id]: CAL_FEEDBACK_TYPE_CORRECT,
    [CAL_FEEDBACK_TYPE_MISSING_KNOWLEDGE.id]: CAL_FEEDBACK_TYPE_MISSING_KNOWLEDGE,
    [CAL_FEEDBACK_TYPE_INCORRECT_RESOURCE.id]: CAL_FEEDBACK_TYPE_INCORRECT_RESOURCE,
    [CAL_FEEDBACK_TYPE_INCORRECT_RESPONSE.id]: CAL_FEEDBACK_TYPE_INCORRECT_RESPONSE,
    [CAL_FEEDBACK_TYPE_IMPOSSIBLE_TO_ANSWER.id]: CAL_FEEDBACK_TYPE_IMPOSSIBLE_TO_ANSWER,
    [CAL_FEEDBACK_TYPE_EXCLUDE.id]: CAL_FEEDBACK_TYPE_EXCLUDE,
    [CAL_FEEDBACK_TYPE_ACCURACY.id]: CAL_FEEDBACK_TYPE_ACCURACY,
    [CAL_FEEDBACK_TYPE_INCORRECT_TOTAL.id]: CAL_FEEDBACK_TYPE_INCORRECT_TOTAL,
    [CAL_FEEDBACK_TYPE_HELPFUL.id]: CAL_FEEDBACK_TYPE_HELPFUL,
    [CAL_FEEDBACK_TYPE_NOT_HELPFUL.id]: CAL_FEEDBACK_TYPE_NOT_HELPFUL,
    [CAL_SUMMARIZE_TICKET.id]: CAL_SUMMARIZE_TICKET,
    [CAL_INITIAL_GREETING.id]: CAL_INITIAL_GREETING,
    [CAL_QA_PERCENTAGE.id]: CAL_QA_PERCENTAGE,
    [NON_CAL_QA_PERCENTAGE.id]: NON_CAL_QA_PERCENTAGE,
  };

  return METRIC_ID_TO_METRIC_TYPE[metricID];
};

export const CUSTOM_METRIC_FORMATTER: CustomMetricFormatter = {
  [SCHEDULED_HOURS_METRIC.id]: (valueInSeconds: number) => {
    return formatFloat(valueInSeconds / 60 / 60, 1);
  },
  [SCHEDULED_HOURS_TOTAL_METRIC.id]: (valueInSeconds: number) => {
    return formatFloat(valueInSeconds / 60 / 60, 1);
  },
  [ACTUAL_PRODUCTIVE_HOURS_METRIC.id]: (valueInSeconds: number) => {
    return formatFloat(valueInSeconds / 60 / 60, 1);
  },
  [ACTUAL_HOURS_NON_PRODUCTIVE_METRIC.id]: (valueInSeconds: number) => {
    return formatFloat(valueInSeconds / 60 / 60, 1);
  },
  [ACTUAL_PRODUCTIVE_AUX_HOURS_ALL_CHANNELS_METRIC.id]: (valueInSeconds: number) => {
    return formatFloat(valueInSeconds / 60 / 60, 1);
  },
  [ACTUAL_PRODUCTIVE_AND_AUX_HOURS_ALL_CHANNELS_METRIC.id]: (valueInSeconds: number) => {
    return formatFloat(valueInSeconds / 60 / 60, 1);
  },
  [ACTUAL_HOURS_IN_ADHERENCE_METRIC.id]: (valueInSeconds: number) => {
    return formatFloat(valueInSeconds / 60 / 60, 1);
  },
  [ACTUAL_HOURS_IN_ADHERENCE_ALL_CHANNELS_METRIC.id]: (valueInSeconds: number) => {
    return formatFloat(valueInSeconds / 60 / 60, 1);
  },
  [ACTUAL_HOURS_IN_SCHEDULE_ADHERENCE_METRIC.id]: (valueInSeconds: number) => {
    return formatFloat(valueInSeconds / 60 / 60, 1);
  },
  [ACTUAL_ALL_HOURS_METRIC.id]: (valueInSeconds: number) => {
    return formatFloat(valueInSeconds / 60 / 60, 1);
  },
  [ACTUAL_ALL_HOURS_WORKED_METRIC_ALL_CHANNELS_METRIC.id]: (valueInSeconds: number) => {
    return formatFloat(valueInSeconds / 60 / 60, 1);
  },
  [AVAILABLE_AND_OCCUPIED_HOURS_METRIC.id]: (valueInSeconds: number) => {
    return formatFloat(valueInSeconds / 60 / 60, 1);
  },
  [AVAILABLE_AND_OCCUPIED_HOURS_ALL_CHANNELS_METRIC.id]: (valueInSeconds: number) => {
    return formatFloat(valueInSeconds / 60 / 60, 1);
  },
  [OCCUPIED_HOURS_METRIC.id]: (valueInSeconds: number) => {
    return formatFloat(valueInSeconds / 60 / 60, 1);
  },
  [OCCUPIED_HOURS_ALL_CHANNELS_METRIC.id]: (valueInSeconds: number) => {
    return formatFloat(valueInSeconds / 60 / 60, 1);
  },
  [SCHEDULED_SECONDS_ALL_CHANNELS_METRIC.id]: (valueInSeconds: number) => {
    return formatFloat(valueInSeconds / 60 / 60, 1);
  },
};

export const METRIC_TYPE_TO_CUSTOM_CSV_LABEL: { [key: string]: string } = {
  [AGENT_STATE_AGENT_AVG_HANDLE_TIME_METRIC.id]: `${AGENT_STATE_AGENT_AVG_HANDLE_TIME_METRIC.label} minutes`,
  [AGENT_STATE_TICKET_HANDLE_TIME_METRIC.id]: `${AGENT_STATE_TICKET_HANDLE_TIME_METRIC.label} minutes`,
  [AGENT_STATE_SOLVED_AGENT_AVG_HANDLE_TIME_METRIC.id]: 'Agent average handle time minutes (solved)',
  [AGENT_STATE_SOLVED_TICKET_HANDLE_TIME_METRIC.id]: 'Agent ticket time minutes (solved)',
};

export const CSV_CUSTOM_METRIC_FORMATTER: CustomMetricFormatter = {
  [METRIC_TYPE_TO_CUSTOM_CSV_LABEL[AGENT_STATE_AGENT_AVG_HANDLE_TIME_METRIC.id]]: (valueInSeconds: number) => {
    return formatFloat(valueInSeconds / 60, 1);
  },
  [METRIC_TYPE_TO_CUSTOM_CSV_LABEL[AGENT_STATE_TICKET_HANDLE_TIME_METRIC.id]]: (valueInSeconds: number) => {
    return formatFloat(valueInSeconds / 60, 1);
  },
  [METRIC_TYPE_TO_CUSTOM_CSV_LABEL[AGENT_STATE_SOLVED_AGENT_AVG_HANDLE_TIME_METRIC.id]]: (valueInSeconds: number) => {
    return formatFloat(valueInSeconds / 60, 1);
  },
  [METRIC_TYPE_TO_CUSTOM_CSV_LABEL[AGENT_STATE_SOLVED_TICKET_HANDLE_TIME_METRIC.id]]: (valueInSeconds: number) => {
    return formatFloat(valueInSeconds / 60, 1);
  },
};

export type CustomMetricLabelFormatter = {
  [key: string]: string;
};

export const CSV_CUSTOM_METRIC_HEADER_LABEL: CustomMetricLabelFormatter = {
  [AGENT_STATE_AGENT_AVG_HANDLE_TIME_METRIC.id]:
    METRIC_TYPE_TO_CUSTOM_CSV_LABEL[AGENT_STATE_AGENT_AVG_HANDLE_TIME_METRIC.id],
  [AGENT_STATE_TICKET_HANDLE_TIME_METRIC.id]: METRIC_TYPE_TO_CUSTOM_CSV_LABEL[AGENT_STATE_TICKET_HANDLE_TIME_METRIC.id],
  [AGENT_STATE_SOLVED_AGENT_AVG_HANDLE_TIME_METRIC.id]:
    METRIC_TYPE_TO_CUSTOM_CSV_LABEL[AGENT_STATE_SOLVED_AGENT_AVG_HANDLE_TIME_METRIC.id],
  [AGENT_STATE_SOLVED_TICKET_HANDLE_TIME_METRIC.id]:
    METRIC_TYPE_TO_CUSTOM_CSV_LABEL[AGENT_STATE_SOLVED_TICKET_HANDLE_TIME_METRIC.id],
  [AGENT_STATE_AGENT_AVG_HANDLE_TIME_METRIC.label]:
    METRIC_TYPE_TO_CUSTOM_CSV_LABEL[AGENT_STATE_AGENT_AVG_HANDLE_TIME_METRIC.id],
  [AGENT_STATE_TICKET_HANDLE_TIME_METRIC.label]:
    METRIC_TYPE_TO_CUSTOM_CSV_LABEL[AGENT_STATE_TICKET_HANDLE_TIME_METRIC.id],
  [AGENT_STATE_SOLVED_AGENT_AVG_HANDLE_TIME_METRIC.label]:
    METRIC_TYPE_TO_CUSTOM_CSV_LABEL[AGENT_STATE_SOLVED_AGENT_AVG_HANDLE_TIME_METRIC.id],
  [AGENT_STATE_SOLVED_TICKET_HANDLE_TIME_METRIC.label]:
    METRIC_TYPE_TO_CUSTOM_CSV_LABEL[AGENT_STATE_SOLVED_TICKET_HANDLE_TIME_METRIC.id],
};

export const solvedAHTMetrics: string[] = [
  AGENT_STATE_AGENT_AVG_HANDLE_TIME_METRIC.id,
  AGENT_STATE_TICKET_HANDLE_TIME_METRIC.id,
  AGENT_STATE_TICKETS_TOUCHED_METRIC.id,
  AGENT_STATE_SOLVED_AGENT_AVG_HANDLE_TIME_METRIC.id,
  AGENT_STATE_SOLVED_TICKET_HANDLE_TIME_METRIC.id,
  AGENT_STATE_SOLVED_TICKETS_TOUCHED_METRIC.id,
];

// Adding a metric will render the NewBadge across Team performance and
// Agent scorecard metric selectors
export const newMetricTypes: string[] = [...solvedAHTMetrics];

export {
  getMetricType,
  getAgentCasesSolvedMetric,
  getCasesSolvedPerActualHourMetric,
  getCasesSolvedPerScheduledHourMetric,
  getAgentSolvedRateMetric,
  getAgentAverageHandleTimeMetric,
};
