import moment from 'moment';
import ReactGA from 'react-ga';
import * as Sentry from '@sentry/react';
import { get } from 'lodash-es';
import LogRocket from 'logrocket';
import queryString from 'query-string';

import { handleFetchErrors } from './Errors';
import {
  initChatWindow,
  initLogRocket,
  LOGROCKET_DISABLED_COMPANIES,
  PENDO_DISABLED_COMPANIES,
  stopChatWindow,
} from './ThirdPartyPlugins';
import { convertToTimezone } from './Timezone';
import { Ranges } from '../../constants/RangeConstants';
import { initStore, Store } from '../../redux';
import { logoutUser, setDefaultFilterParams, updateFilterParams } from '../../redux/actions';
import { isInZendeskAppContext } from '../zendesk/Utils';
import { isUUID } from './Lib';
import { getColorMode } from './ColorMode';
import type { User, UserRole } from '../../models';
import { isCal } from './Cal';
import { AssembledProduct } from '../../models';

export const DEFAULT_LOCALE = 'en';
const DEFAULT_WEEK_START = 0;

export const USER_ROLE_ORDER: Array<UserRole> = ['basic', 'standard', 'lead', 'manager', 'admin'];

const ZENDESK_SCRIPT_URL = `https://static.zdassets.com/ekr/snippet.js?key=${process.env.REACT_APP_ZENDESK_SCRIPT_KEY}`;
const ZENDESK_SCRIPT_ID = 'ze-snippet';

const BENTO_APP_ID = process.env.REACT_APP_BENTO_APP_ID;

type onChangeFn = (user: User | null, loaded: boolean) => void;

class UserManager {
  user: User | null;

  history: History | null;

  store: Store | null;

  initialized: boolean;

  loaded: boolean;

  seenTrialBanner: boolean;

  onChangeFns: Array<onChangeFn>;

  constructor() {
    this.user = null;
    this.history = null;
    this.store = null;
    this.initialized = false;
    this.loaded = false;
    this.seenTrialBanner = false;
    this.onChangeFns = [];
  }

  setHistory(history: any) {
    this.history = history;
  }

  setStore(store: Store) {
    this.store = store;
  }

  handleMagicLink() {
    const { location } = window;
    const query = queryString.parse(location.search);
    const magicToken: string = query.magic;

    if (!isCal() || !magicToken) {
      return Promise.resolve();
    }
    delete query.magic;
    const params: URLSearchParams = new URLSearchParams(query);
    return fetch('/api/sessions/magic', {
      method: 'POST',
      body: JSON.stringify({
        token: magicToken,
      }),
      credentials: 'same-origin',
    })
      .then(handleFetchErrors)
      .then((user) => {
        this.loaded = true;
        this.setUser(user);
      })
      .catch(() => {
        this.loaded = true;
        this._alertChange();
      })
      .finally(() => {
        if (this.history) {
          // @ts-ignore
          this.history.push(`${location.pathname}?${params.toString()}`);
        }
      });
  }

  checkSession() {
    fetch('/api/sessions', {
      method: 'GET',
      credentials: 'same-origin',
    })
      .then(handleFetchErrors)
      .then((user) => {
        this.loaded = true;
        this.setUser(user);
      })
      .catch(() => {
        this.loaded = true;
        this._alertChange();
      });
  }

  logout() {
    const { user } = this;
    this.unsetUser();
    fetch('/api/sessions/end', {
      method: 'POST',
      credentials: 'same-origin',
    })
      .then(handleFetchErrors)
      .catch((error) => {
        this.setUser(user);
        console.warn(error);
      });
  }

  bindOnChange(onChangeFn: onChangeFn) {
    const idx = this.onChangeFns.length;
    this.onChangeFns.push(onChangeFn);
    return idx;
  }

  unbindOnChangeFn(index: number) {
    if (index > this.onChangeFns.length - 1) return false;
    return this.onChangeFns.splice(index, 1).length > 0;
  }

  baseInterval() {
    // TODO: The company's forecast interval should eventually be set on the
    // db, but for now, everyone gets forecasted at an hourly interval
    if (this.user && this.user.company) {
      return this.user.company.base_interval;
    }
    return 60 * 60;
  }

  defaultDayLengthHours() {
    if (this.user && this.user.company) {
      const defaultDayLengthSeconds = this.user.company.timeoff_day_length_seconds;
      return parseFloat((defaultDayLengthSeconds / (60 * 60)).toFixed(2));
    }
    return 8;
  }

  dragPrecision() {
    if (this.user && this.user.company && this.user.company.drag_precision) {
      return this.user.company.drag_precision;
    }
    return Math.min(60 * 15, this.baseInterval());
  }

  _alertChange() {
    this.onChangeFns.map((fn) => {
      fn(this.user, this.loaded);
    });
  }

  _shouldEnableLogRocket(user: User) {
    if (window.location.href.indexOf('/forgot/finish') !== -1) {
      return false;
    }

    if (isInZendeskAppContext()) {
      return false;
    }
    if (!this.hasPermissionsAtLeast('lead')) {
      return false;
    }

    const companyID = get(user, 'company.id', null);
    if (!companyID) {
      return false;
    }

    if (this.isAssembledAccount()) {
      return false;
    }

    return !LOGROCKET_DISABLED_COMPANIES.includes(companyID);
  }

  _initPendo(user: User) {
    const companyID = get(user, 'company.id', null);
    if (!companyID || PENDO_DISABLED_COMPANIES.includes(companyID)) {
      return;
    }

    const colorMode = getColorMode();

    const companyName = user.company && user.company.name;

    const { agent } = user;
    const agentFilterData: pendo.IdentityMetadata = agent
      ? {
          channels: agent.channels,
          site: agent.site?.name || null,
          site_id: agent.site?.value || null,
          teams: agent.teams?.map((team) => team.name) || [],
          team_ids: agent.teams?.map((team) => team.value) || [],
          queues: agent.queues?.map((queue) => queue.name) || [],
          queue_ids: agent.queues?.map((queue) => queue.value) || [],
          skills: agent.skills?.map((skill) => skill.name) || [],
          skill_ids: agent.skills?.map((skill) => skill.value) || [],
        }
      : {};
    const pendoData: pendo.InitOptions = {
      visitor: {
        id: user.email,
        user_id: user.id,
        name: `${user.first_name} ${user.last_name}`,
        admin: user.admin,
        role: user.role,
        roles: user?.roles?.map((role) => role.name) || [],
        impersonated: user.impersonated?.company_id || null,
        timezone: user.timezone,
        has_associated_agent: user.agent_id != null,
        color_mode: colorMode,
        ...agentFilterData,
      },
      account: {
        id: companyID,
        company_id: `${companyName} (${companyID})`,
        company_name: companyName || null,
      },
    };
    // If Pendo has already been initialized for a different user
    // use the identify method to switch which user the events are recorded for.
    try {
      if (isUUID(pendo.getVisitorId())) {
        pendo.identify(pendoData);
      } else {
        pendo.initialize(pendoData);
      }
    } catch (e: any) {
      console.error(e);
    }
  }

  _trackUser(user: any) {
    if (user && process.env.BUILD_ENV === 'production') {
      Sentry.setUser({
        username: user.email,
        email: user.email,
        id: user.id,
        company_id: user.company.id,
      });
    } else {
      Sentry.setUser(null);
    }

    if (this._shouldEnableLogRocket(user)) {
      initLogRocket();
    }

    this._initPendo(user);

    if (user) {
      LogRocket.identify(user.id, {
        name: user.name,
        email: user.email,
        company_name: user.company && user.company.name,
        company_id: user.company && user.company.id,
        admin: user.admin, // for legacy
        role: user.role,
        impersonated: user.impersonated,
        timezone: user.timezone,
      });
      ReactGA.set({
        userId: user.id,
        dimension1: user.company.id,
        dimension2: `${user.company.name} (${user.company.id})`,
      });
    } else {
      // @ts-expect-error - TS2769 - No overload matches this call.
      LogRocket.identify(null);
      ReactGA.set({
        userId: null,
        dimension1: null,
        dimension2: null,
      });
    }
  }

  // Zendesk documentation
  // https://developer.zendesk.com/api-reference/widget/settings/#authenticate
  loadZendeskWidget(callback: () => void) {
    const existingScript = document.getElementById(ZENDESK_SCRIPT_ID);
    if (!existingScript) {
      window.zESettings = {
        webWidget: {
          authenticate: {
            chat: {
              jwtFn(cb) {
                fetch('/api/zendesk-chat-widget/authorize').then((res) => {
                  res.text().then((jwtRaw) => {
                    const jwt = jwtRaw.replace(/"/g, '').trim();
                    cb(jwt);
                  });
                });
              },
            },
          },
        },
      };

      const script = document.createElement('script');
      script.src = ZENDESK_SCRIPT_URL;
      script.id = ZENDESK_SCRIPT_ID;
      document.body && document.body.appendChild(script);
      script.onload = () => {
        if (callback) callback();
      };
    }
    if (existingScript && callback) callback();
  }

  _initZendeskChatWidget() {
    if (isCal()) {
      return;
    }
    this.loadZendeskWidget(() => {
      if (window.zE) {
        window.zE('webWidget', 'reset');
        window.zE('webWidget', 'chat:reauthenticate');
        window.zE('webWidget', 'hide');
        // https://support.zendesk.com/hc/en-us/articles/4408831194906-Why-can-my-customers-still-chat-after-all-agents-have-gone-offline-
        window.zE('webWidget:on', 'chat:status', (status: string) => {
          if (status === 'offline') {
            window.zE?.('webWidget', 'chat:end');
          }
        });
        window.zE('webWidget:on', 'close', () => {
          window.zE?.('webWidget', 'hide');
        });
      }
    });
  }

  _unInitZendeskChatWidget() {
    if (window.zE) {
      window.zE.logout?.();
      window.zE.hide?.();
    }
  }

  // Bento Installation Guide - https://www.notion.so/Bento-installation-d860652453b34de89420a475df379a8e
  _initBento(user: any) {
    // Only initialize Bento if we are not running the Zendesk app
    if (isInZendeskAppContext()) {
      return;
    }

    if (!user) {
      return;
    }

    window.bentoSettings = {
      appId: BENTO_APP_ID,
      // Accounts represent your customers. For example, a company called "AcmeCo"
      account: {
        id: user.company.id, // REQUIRED; pass in the "Account" ID. You probably call this "Account" or "Company" or "Org" in your codebase
        name: user.company.name, // REQUIRED; This allows admin/CSM users to identify the companies/accounts they're working with.
        createdAt: user.company.created_at, // OPTIONAL (leave blank if not using); This timestamp is used to automatically launch guides based on when the account (customer/org) was created in your database. Please ensure it's formatted to ISO8601 Date String
        // [additionalAttribute: string]: boolean | Date | number | text, -- additional tenant attributes you define can be passed in this object
      },
      // accountUsers represent the people at your customer. For example, someone named John Doe who works at AcmeCo
      accountUser: {
        id: user.id, // set this to the user ID
        fullName: `${user.first_name} ${user.last_name}`, // Allows you to identify end users in the Bento UI
        role: user.role, // i.e. "engineer", "admin", etc. This will allow you to target guides based on user persona
        createdAt: user.created_at, // ISO8601 Date String - Allows you to auto-launch user onboarding guides to newly created users
        email: user.email, // Allows your account user to receive notifications around comment replies (if commenting is enabled)
        avatarUrl: '', // Allow you to see an avatar for your end user in comments (if commenting is enabled)
        // [additionalAttribute: string]: boolean | Date | number | text -- additional user attributes you define can be passed in this object
      },
    };

    window.Bento && window.Bento.initialize();
  }

  _unInitBento() {
    if (isInZendeskAppContext()) {
      return;
    }

    if (window.bentoSettings) {
      delete window.bentoSettings;
      window.Bento && window.Bento.reset();
    }
  }

  timezone() {
    if (this.user && this.user.timezone) {
      return this.user.timezone;
    }
    return moment.tz.guess();
  }

  locale() {
    if (this.user && this.user.locale) {
      return this.user.locale;
    }
    return DEFAULT_LOCALE;
  }

  _resetMomentSettings() {
    const locale = (this.user && this.user.locale) || DEFAULT_LOCALE;
    moment.updateLocale(locale, {
      week: { dow: DEFAULT_WEEK_START },
    });
    moment.tz.setDefault();
  }

  _updateMomentSettings(user: any) {
    if (!user) {
      return;
    }
    if (!user.company.start_of_week && !user.timezone && !user.locale) {
      return;
    }

    // @ts-expect-error - TS2339 - Property 'store' does not exist on type 'UserManager'.
    const { filterParams } = this.store.getState();
    let date = filterParams.date.clone();
    let start = filterParams.start.clone();
    let end = filterParams.end.clone();

    if (user.company.start_of_week || user.locale) {
      const locale = user.locale || DEFAULT_LOCALE;
      const opts: Record<string, any> = {};
      if (user.company.start_of_week) {
        // Update the moment locale to have a different start of the week (these are
        // integers where 0: Sunday, 1: Monday, etc.)
        opts.week = { dow: user.company.start_of_week };
      } else {
        opts.week = { dow: DEFAULT_WEEK_START };
      }
      moment.updateLocale(locale, opts);

      date = moment.unix(date.unix());
      start = moment.unix(start.unix());
      end = moment.unix(end.unix());
    }

    if (user.timezone) {
      // Update the timezone for the moment objects in the filter params redux obj
      if (filterParams.setByLocationChange || this.initialized) {
        // If the date has already been initialized or set by the location change,
        // then we shouldn't call startOf again and all we need to do is take the
        // current date and change the timezone.
        date = convertToTimezone(date, user.timezone);
        start = convertToTimezone(start, user.timezone);
        end = convertToTimezone(end, user.timezone);
      } else {
        date = moment.tz(user.timezone).startOf('day');
        start = moment.tz(user.timezone).startOf('day');
        end = moment.tz(user.timezone).startOf('day').add(1, 'day');
      }
      // Update the default moment timezone
      moment.tz.setDefault(user.timezone);
    }

    // @ts-expect-error - TS2339 - Property 'store' does not exist on type 'UserManager'.
    this.store.dispatch(
      updateFilterParams({
        date,
        start,
        end,
      })
    );
  }

  isAssembledAccount(): boolean {
    if (!this.user) {
      return false;
    }
    return this.user.team_account || this.user.admin;
  }

  isAssembledCompanyID(): boolean {
    const assembledCompanyExternalID = '67626e2f-2620-4f76-986a-90c68c356e3e';
    return this.companyId() === assembledCompanyExternalID;
  }

  _updateDefaultFilterParams(user: any) {
    const update = {
      channel: 'all',
      range: Ranges.day,
    };
    if (user) {
      if (user.default_channel) {
        update.channel = user.default_channel;
      } else if (user.company && (user.company.default_channel || user.company.channels.length === 1)) {
        update.channel = user.company.default_channel || user.company.channels[0];
      } else {
        update.channel = 'all';
      }
      if (user.default_range) {
        update.range = user.default_range;
      }
      if (user.default_site) {
        // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type '"site"' can't be used to index type '{ channel: string; range: "day"; }'.
        update.site = user.default_site;
      }
      if (user.default_queue) {
        // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type '"queue"' can't be used to index type '{ channel: string; range: "day"; }'.
        update.queue = user.default_queue;
      }
      if (user.default_team) {
        // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type '"team"' can't be used to index type '{ channel: string; range: "day"; }'.
        update.team = user.default_team;
      }
      if (user.default_limit) {
        // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type '"limit"' can't be used to index type '{ channel: string; range: "day"; }'.
        update.limit = user.default_limit;
      }
    }
    // @ts-expect-error - TS2339 - Property 'store' does not exist on type 'UserManager'. | TS2345 - Argument of type '{ channel: string; range: "day"; }' is not assignable to parameter of type 'FilterParams'.
    this.store.dispatch(setDefaultFilterParams(update));
  }

  _initStore(user: any) {
    if (user && this.store) {
      initStore(this.store);
    }
  }

  isLoggedIn() {
    return !!this.user;
  }

  getFirstName(): string {
    if (!this.user) {
      return '';
    }
    return this.user.first_name;
  }

  isLoading() {
    return !this.loaded;
  }

  setUser(user: any) {
    this.user = user;
    if (!inIframe()) {
      initChatWindow(user);
    }
    this._trackUser(user);
    this._updateDefaultFilterParams(user);
    this._initStore(user);
    this._updateMomentSettings(user);
    this._alertChange();
    this._initZendeskChatWidget();
    this._initBento(user);
    this.initialized = true;
  }

  unsetUser() {
    this.user = null;
    this.initialized = false;
    if (!inIframe()) {
      stopChatWindow();
    }
    this._trackUser(null);
    this._alertChange();
    this._resetMomentSettings();
    this._unInitZendeskChatWidget();
    this._unInitBento();
    this.store?.dispatch(logoutUser());
  }

  /**
   * Helpers for introspecting user settings
   */
  channels() {
    if (this.user && this.user.company) {
      return this.user.company.channels || [];
    }
    return [];
  }

  companyId() {
    if (this.user && this.user.company) {
      return this.user.company.id;
    }
    return '';
  }

  companyName() {
    if (this.user && this.user.company) {
      return this.user.company.name;
    }
    return '';
  }

  sites() {
    if (this.user && this.user.company) {
      return this.user.company.sites || [];
    }
    return [];
  }

  hasAssociatedAgent() {
    return this.user && this.user.agent && this.user.agent.active;
  }

  hasFeatureFlag(name: any) {
    if (!this.user || !this.user.company || !this.user.company.feature_flags) {
      return false;
    }
    const flag = this.user.company.feature_flags.find((f) => f === name);
    return !!flag;
  }

  hasEnabledProduct(name: AssembledProduct): boolean {
    if (!this.user || !this.user.company || !this.user.company.enabled_products) {
      return false;
    }
    const product = this.user.company.enabled_products.find((p) => p === name);
    return !!product;
  }

  getStartOfWeek(): number {
    if (!this.user || !this.user.company || typeof this.user.company.start_of_week !== 'number') {
      return 0;
    }
    return this.user.company.start_of_week;
  }

  getID(): string {
    if (!this.user || !this.user.id) {
      return '';
    }
    return this.user.id;
  }

  hasRole(role: any) {
    if (!this.user || !this.user.role) {
      return false;
    }
    return this.user.role === role;
  }

  hasPermissionsAtLeast(role: UserRole) {
    if (!this.user || !this.user.role) {
      return false;
    }

    return USER_ROLE_ORDER.indexOf(this.user.role) >= USER_ROLE_ORDER.indexOf(role);
  }

  hasRoleStandard() {
    return this.hasRole('standard');
  }

  // Taylor (2021-03-08): Generally prefer to use hasPermissionsAtLeast
  hasAnyRole(roles: any) {
    if (!this.user || !this.user.role) {
      return false;
    }
    return this.userHasRoles(this.user, roles);
  }

  userHasRoles(user: User, roles: Array<string>) {
    return !!roles.find((role) => user.role === role);
  }

  hasRestrictedSites() {
    if (this.user && this.user.restricted_sites) {
      return this.user.restricted_sites.length > 0;
    }
    return false;
  }

  isImpersonated() {
    return this.user && this.user.impersonated;
  }

  getImpersonatedCompany() {
    if (!this.user || !this.user.impersonated) {
      return '';
    }
    return this.user.impersonated.company_id;
  }

  zendeskSubdomain() {
    if (this.user && this.user.company) {
      return this.user.company.zendesk_subdomain;
    }
  }

  isSuperAdmin(): boolean {
    return Boolean(this.user && (this.user.admin || this.user.impersonated));
  }
}

// Per https://developer.mozilla.org/en-US/docs/Web/API/Window/parent, if a
// window does not have a parent, its parent property is a reference to itself.
// When a window is loaded in an iframe, its parent is the window with the
// element embedding the window.
function inIframe(): boolean {
  return window.location !== window.parent.location;
}

export default new UserManager();

export { inIframe };
