import * as React from 'react';
import { Alert } from '@assembled/react-bootstrap';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';

import { useWebSocket } from './utils/WebSockets';
import UserManager from './utils/UserManager';
import type { FeatureFlags, Notification } from '../models';
import type { ReduxState } from '../redux/models';

import '../css/notifications.css';

const alertStyle = {
  border: '0',
  color: '#25272F',
  marginBottom: '0',
  paddingLeft: '15px',
  paddingRight: '40px',
} as const;

type NotificationContainerProps = {
  featureFlags: FeatureFlags;
};

function NotificationContainerRaw(props: NotificationContainerProps) {
  const [notifications, setNotifications] = React.useState<Array<Notification>>([]);
  const [seenNotifications, setSeenNotifications] = React.useState<Array<string>>([]);

  const { setWsOpts } = useWebSocket();

  const handleWsResponse = (resp: string) => {
    const notifications = JSON.parse(resp);
    if (notifications) {
      setNotifications(notifications);
    }
  };

  const websocketFlag = props.featureFlags['notifications.websockets'];

  React.useEffect(() => {
    if (websocketFlag && UserManager.isLoggedIn()) {
      setWsOpts({
        relativePath: '/api/ws/notifications',
        onOpen: () => {},
        onMessage: handleWsResponse,
        reconnect: true,
      });
    } else {
      // This ensures that we don't try to use the old websocket options to establish a connection
      // on logout
      setWsOpts(null);
    }
  }, [websocketFlag, setWsOpts, UserManager.isLoggedIn()]);

  // @ts-expect-error - TS7006 - Parameter 'props' implicitly has an 'any' type.
  function NotificationWrapper(props) {
    if (!seenNotifications.includes(props.notification.id)) {
      return (
        <NotificationBanner
          className={props.className}
          style={props.style}
          icon={props.icon}
          closeToast={props.closeToast}
        >
          {props.notification.message}
        </NotificationBanner>
      );
    }
    return null;
  }

  notifications.map((notification: Notification) => {
    toast(
      ({ closeToast }: { closeToast: () => void }) => (
        <NotificationWrapper
          style="success"
          icon={<span className="icon icon-light-up" />}
          closeToast={closeToast}
          notification={notification}
          className="notification"
        />
      ),
      {
        toastId: notification.id,
        autoClose: false,
        onOpen: () => {
          setSeenNotifications((prevSeen) => {
            return prevSeen.concat(notification.id);
          });
        },
        onClick: () => {
          return fetch('/api/notifications/ack', {
            method: 'POST',
            credentials: 'same-origin',
            body: JSON.stringify({ id: notification.id }),
          });
        },
      }
    );
  });

  return null;
}

function mapStateToProps(state: ReduxState): NotificationContainerProps {
  return {
    featureFlags: state.featureFlags,
  };
}

const NotificationContainer = connect(mapStateToProps)(NotificationContainerRaw);

type NotificationBannerProps = {
  closeToast: () => void;
  className: string;
  style: string;
  icon: React.ReactNode;
  children: React.ReactNode;
};

function NotificationBanner(props: NotificationBannerProps) {
  return (
    <Alert onDismiss={props.closeToast} className={props.className} bsStyle={props.style} style={alertStyle}>
      <div className="d-flex">
        <div className="m-r-sm">{props.icon}</div>
        <div className="flex-grow" style={{ overflowWrap: 'break-word' }}>
          {props.children}
        </div>
      </div>
    </Alert>
  );
}

export default NotificationContainer;
