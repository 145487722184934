import * as React from 'react';

import styles from './HoverableText.module.css';

export default React.forwardRef<HTMLSpanElement, { children?: React.ReactNode }>(function HoverableText(
  { children },
  ref
) {
  return (
    <span ref={ref} className={styles.span}>
      {children}
    </span>
  );
});
