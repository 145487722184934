export type UpdateTemplatesAction = {
  type: 'UPDATE_TEMPLATES';
  update: Partial<TemplatesReducerState>;
};

export type TemplatesReducerState = {};

const initialState: TemplatesReducerState = {};

const TemplatesReducer = (
  state: TemplatesReducerState = initialState,
  action: UpdateTemplatesAction
): TemplatesReducerState => {
  switch (action.type) {
    case 'UPDATE_TEMPLATES':
      return { ...state, ...action.update };
    default:
      return state;
  }
};

export { TemplatesReducer };
