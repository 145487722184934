import * as React from 'react';
import { sample } from 'lodash-es';
import classNames from 'classnames';

import { inIframe } from '../utils/UserManager';
import { getColorMode, isLightMode } from '../utils/ColorMode';
import type { Theme } from '../../models';

import styles from './Loading.module.css';

const assembledLogoLoading = (
  <div id="assembled-loading">
    <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.1504 2H5.44336V7.8537H16.1504V21.5493H22.0041V2H22.0029H16.1504Z"
        fill="var(--green-50)"
      />
      <path
        d="M8.36592 9.26953C4.85012 9.26953 2 12.1197 2 15.6355C2 19.1513 4.85012 22.0014 8.36592 22.0014C11.8817 22.0014 14.7318 19.1513 14.7318 15.6356H8.36607L8.36592 9.26953Z"
        fill="var(--green-50)"
      />
    </svg>
  </div>
);

const loadingMessages = [
  'Just a moment, we’re assembling data!',
  'Thanks for your patience while we assemble some data',
  'We’re working on assembling your calculations 🤓',
];

const getLoadingMessage = () => {
  return <div id="assembled-loading-message">{sample(loadingMessages)}</div>;
};

type FullPageLoadingProps = {
  hide?: boolean;
  fullWidth?: boolean;
};

function FullPageLoading(props: FullPageLoadingProps) {
  const { hide, fullWidth } = props;
  const theme: Theme = inIframe() ? 'light' : getColorMode();
  const [loadingMessage, setLoadingMessage] = React.useState<JSX.Element>();
  React.useEffect(() => {
    setLoadingMessage(getLoadingMessage());
  }, []);

  const isLightMode = theme === 'light';
  return (
    <div className={classNames(styles.dimmer, { [styles.light]: isLightMode, [styles.active]: !hide })}>
      <div
        className={classNames(styles.fullPageLoading, {
          [styles.light]: isLightMode,
          [styles.fullWidth]: fullWidth,
        })}
      >
        {assembledLogoLoading}
        {loadingMessage}
      </div>
    </div>
  );
}

function SectionLoading() {
  const theme: Theme = inIframe() ? 'light' : getColorMode();
  const [loadingMessage, setLoadingMessage] = React.useState('');
  React.useEffect(() => {
    // @ts-expect-error - TS2345 - Argument of type 'Element' is not assignable to parameter of type 'SetStateAction<string>'.
    setLoadingMessage(getLoadingMessage());
  }, []);

  let lightModeClassAddition = '';
  if (theme === 'light') {
    lightModeClassAddition = styles[theme];
  }

  return (
    <div className={classNames(styles.dimmer, styles.active, styles.fullPageLoading, lightModeClassAddition)}>
      {assembledLogoLoading}
      {loadingMessage}
    </div>
  );
}

type LoadingProps = {
  hide?: boolean | null | undefined;
  className?: string | null | undefined;
  style?: React.CSSProperties;
  inline?: boolean;
};

class Loading extends React.Component<LoadingProps> {
  render() {
    if (this.props.hide) {
      return null;
    }

    return (
      <div
        className={classNames(styles.loadingFadingCircle, this.props.className, {
          [styles.inlineLoading]: this.props.inline,
          [styles.dimmed]: isLightMode,
        })}
        style={this.props.style}
      >
        <div className={classNames(styles.loadingCircle1, styles.loadingCircle)} />
        <div className={classNames(styles.loadingCircle2, styles.loadingCircle)} />
        <div className={classNames(styles.loadingCircle3, styles.loadingCircle)} />
        <div className={classNames(styles.loadingCircle4, styles.loadingCircle)} />
        <div className={classNames(styles.loadingCircle5, styles.loadingCircle)} />
        <div className={classNames(styles.loadingCircle6, styles.loadingCircle)} />
        <div className={classNames(styles.loadingCircle7, styles.loadingCircle)} />
        <div className={classNames(styles.loadingCircle8, styles.loadingCircle)} />
        <div className={classNames(styles.loadingCircle9, styles.loadingCircle)} />
        <div className={classNames(styles.loadingCircle10, styles.loadingCircle)} />
        <div className={classNames(styles.loadingCircle11, styles.loadingCircle)} />
        <div className={classNames(styles.loadingCircle12, styles.loadingCircle)} />
      </div>
    );
  }
}

type LoadingBlockProps = {
  hide?: boolean;
  children?: React.ReactNode;
};

function LoadingBlock(props: LoadingBlockProps) {
  if (props.hide) {
    return <>{props.children}</>;
  }

  return (
    <div>
      <strong>Loading…</strong>
      <Loading inline />
    </div>
  );
}

export { FullPageLoading, Loading, LoadingBlock, SectionLoading };
