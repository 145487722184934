import queryString from 'query-string';

import { handleFetchErrors } from './components/utils/Errors';

// Similar to magic links, we need to handle this _before_ any auth, which is why it needs to be so
// high on index.ts. In short, Shopify requires OAuth to happen _before_ app-required auth, so we
// need to have a special interceptor here to fall back into this OAuth flow before attempting
// to hijack the current session to require Assembled-auth
async function handleShopifyAuth() {
  const { location } = window;
  const query = queryString.parse(location.search);
  const authShopify: string = query.authorize_shopify;

  if (!authShopify || authShopify !== 'true') {
    return Promise.resolve();
  }

  const params: URLSearchParams = new URLSearchParams(query);
  return fetch(`/api/shopify/authorize/init?${params.toString()}`, {
    method: 'GET',
    credentials: 'same-origin',
  })
    .then(handleFetchErrors)
    .then((res: any) => {
      window.location.href = res.url;
    });
}

export { handleShopifyAuth };
