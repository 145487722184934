import type { Channel } from '../../models';

function channelQueueKey(channel: Channel, queue?: string | null) {
  if (queue) {
    return `${channel}:${queue}`;
  }
  return `${channel}`;
}

export { channelQueueKey };
